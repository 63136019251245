import React, { useEffect, useState } from 'react'
import { withRouter } from '../../../providers/withRouter';
import { firestore, storage } from "../../../providers/firebase";
import { PDFViewer, Document, View, Page, Text, Image, StyleSheet } from "@react-pdf/renderer"
import CircularProgress from '@material-ui/core/CircularProgress';
import LogoNF from "../../../images/Logo.png"
import salida from "../../../images/salida.png"
import salidaEmergencia from "../../../images/salidaEmergencia.png"
import riesgosElectricos from "../../../images/riesgosElectricos.png"
import pica from 'pica';

const EvaluacionRiesgosPDF = (props) => {
    
    const [hojaVisita, setHojasVisita] = useState([])
    const [centroTrabajo, setCentroTrabajo] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        firestore.collection("hojasVisita").doc(props.params.nifFinca).get().then(doc => {
            const hojaVisita = doc.data()
            let centroTrabajo = undefined
            hojaVisita.centrosTrabajo.forEach(ct => {
                if(ct.nif === props.params.nifCentroTrabajo){
                    centroTrabajo = ct
                }
            })
            console.log(centroTrabajo)
            storage.ref(centroTrabajo?.img).getDownloadURL().then(img => {
                hojaVisita['imgURL'] = img
                let fechaVisita = new Date(centroTrabajo?.fechaVisita.seconds * 1000)
                let fechaVisitaRenovacion = centroTrabajo?.fechaVisitaRenovacion === undefined ? new Date (0) : new Date(centroTrabajo?.fechaVisitaRenovacion?.seconds * 1000)
                let fechaRealizacion = new Date(centroTrabajo?.fechaVisita.seconds * 1000)
                hojaVisita['diffFechas'] = centroTrabajo?.fechaVisitaRenovacion?.seconds < centroTrabajo?.fechaVisita?.seconds
                hojaVisita['fechaVisita'] = ("0" + fechaVisita.getDate()).slice(-2) + "-" + ("0" + (fechaVisita.getMonth() + 1)).slice(-2) + "-" + fechaVisita.getFullYear()
                hojaVisita['fechaRealizacion'] = ("0" + fechaRealizacion.getDate()).slice(-2) + "-" + ("0" + (fechaRealizacion.getMonth() + 1)).slice(-2) + "-" + fechaRealizacion.getFullYear()
                hojaVisita['fechaVisitaRenovacion'] = ("0" + fechaVisitaRenovacion.getDate()).slice(-2) + "-" + ("0" + (fechaVisitaRenovacion.getMonth() + 1)).slice(-2) + "-" + fechaVisitaRenovacion.getFullYear()
                if(fechaVisitaRenovacion === undefined || hojaVisita?.diffFechas === true || fechaVisitaRenovacion.getFullYear()=== 1970){
                    hojaVisita['fechaMostrar'] = ("0" + fechaVisita.getDate()).slice(-2) + "-" + ("0" + (fechaVisita.getMonth() + 1)).slice(-2) + "-" + fechaVisita.getFullYear()
                    // console.log(fechaVisita.toLocaleDateString())
                }else{
                    hojaVisita['fechaMostrar'] = ("0" + fechaVisitaRenovacion.getDate()).slice(-2) + "-" + ("0" + (fechaVisitaRenovacion.getMonth() + 1)).slice(-2) + "-" + fechaVisitaRenovacion.getFullYear()
                    // console.log(fechaVisitaRenovacion.toLocaleDateString())
                }
                setHojasVisita( hojaVisita )
                setCentroTrabajo( centroTrabajo )
                setIsLoading(false)
                // comprimirImagen(hojaVisita?.imgURL)
                // fetchImage(hojaVisita?.img);
                console.log(hojaVisita?.imgURL)
            })
        }).catch(err => console.log(err))
    },[])

    const styles = StyleSheet.create({
        imgPrincipal:{
            height:'400px', 
            width:'100%', 
            marginTop:30
        },
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: '2cm',
        },
        table: { 
            display: "table", 
            width: "auto", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderRightWidth: 0, 
            borderBottomWidth: 0 
        }, 
        tableRow: { 
            margin: "auto", 
            flexDirection: "row" 
        }, 
        tableColHeader: { 
            width: "20%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#375491',
            color: '#ffffff',
        }, 
        tableColHeader1: { 
            width: "40%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#375491',
            color: '#ffffff',
        }, 
        /* tableColHeaderProv: { 
            width: "33%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#ffffff',
            color: '#000000',
        }, 
        tableColHeaderProv1: { 
            width: "34%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#ffffff',
            color: '#000000',
        }, 
        tableColHeaderSOS: { 
            width: "50%",
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#ffffff',
            color: '#000000',
        }, 
        tableColHeaderNM: { 
            width: "100%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#ffffff',
            color: '#000000',
        },  */
        tableCol: { 
            width: "20%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        }, 
        tableCol1: { 
            width: "40%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
        }, /* 
        tableColSOS: { 
            width: "50%",
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            color:'red',
        }, 
        tableColNM: { 
            width: "100%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
        },  */
        tableCell: { 
            margin: "auto", 
            marginTop: 5, 
            marginBottom: 5, 
            marginLeft:2,
            marginRight: 2,
            fontSize: 8 
        },
        pdfHeader: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'fixed',
            top: 0,
            backgroundColor: '#ffffff',
            /* margin:'2cm', */
        },
        pdfHeaderImg:{
            width:'60px', 
            height:'60px',
            marginBottom:'30px',
        },
        tablePage: {
            width: '100%',
        },
        headerSpace: {
            height: '90px',
        },
        div: {
            fontSize: '10px'
        },
        h1: {
            /* marginLeft:'2cm',
            marginRight:'2cm', 
            marginTop: '50px',*/
            marginBottom: '30px',
            fontSize: '14px',
            fontWeight: '600',
        },
         h2: {
            fontSize: '11px',
            fontWeight: '600',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
            marginTop: '10px',
            marginBottom: '10px',
        },
        h3: {
            fontSize: '9px',
            width: '100%',
            padding: '5px 10px',
            backgroundColor: '#f8f8f8',
            textTransform: 'uppercase',
            border: '1px solid #e6e6e6',
            marginTop: '25px',
            marginBottom: '15px',
            fontWeight:'bold',
            /* marginLeft:'2cm',
            marginRight:'20cm', */
        },
        h4: {
            color: '#ab2a3e',
            marginBottom: '10px',
            marginTop: '20px',
            fontSize: '9px',
            fontWeight:'bold',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
        },
        h5: {
            fontSize: '10px',
            marginTop: '15px',
            marginBottom: '5px',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
        },
        /* textBox: {
            border: '2px solid #e6e6e6',
            padding: '15px',
            marginTop: '15px',
        }, */
        tableRiesgos: {
            borderCollapse: 'collapse',
            width: '100%',
            tableLayout: 'fixed',
            marginBottom: '24px',
            flexDirection: 'row',
            borderBottomColor: '#bff0fd',
            backgroundColor: '#bff0fd',
            borderBottomWidth: 1,
            alignItems: 'center',
            textAlign: 'center',
            fontStyle: 'bold',
            flexGrow: 1,
        },
        /* simpleTableTh: {
            border: '1px solid #000000',
            padding: '5px',
            lineHeight: 1.1
        }, */
        /* simpleTableTd: {
            border: '1px solid #000000',
            padding: '5px',
            lineHeight: 1.1
        },
        tableRiesgosTd: {
            border: '1px solid #000000',
            padding: '5px',
            lineHeight: 1.1
        },
        tableRiesgosTh: {
            backgroundColor: '#375491',
            color: '#ffffff',
            fontSize: '12px',
            fontWeight: 'bold',
            border: '1px solid #000000',
            padding: '5px',
            lineHeight: 1.1
        }, */
        simpleTable: {
            borderCollapse: 'collapse',
            width: '80%',
            margin: '0 10% 24px 10%',
        },
        simpleTableTh: {
            textTransform: 'uppercase',
        },
        /* subul: {
            lineHeight: '1.8',
            fontSize:'8px',
            flexDirection: "column", 
            width: 400,
            marginLeft: '8px',
        }, */
        ul: {
            // lineHeight: '1.8',
            // fontSize:'7px',
            flexDirection: "column", 
            width: 150,
        },
        ol: {
            lineHeight: '1.8',
            flexDirection: "row", 
            marginBottom: 4,
            fontSize: '7px',
        },
        p: {
            lineHeight: '1.8',
            textAlign: 'justify',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
            fontSize: '7px',
        },
        subp: {
            lineHeight: '1.8',
            textAlign: 'justify',
            marginLeft: '8px',
            fontSize: '8px',
        },
        br: {
            display: 'block',
            margin: '10px 0 2px 0',
            lineHeight: '5px',
            content: " ",
        },
        /* pageTitle: {
            textAlign: 'center',
            textDecoration: 'underline',
            fontWeight: 'bold',
            fontSize: '12px',
            marginBottom: '20px'
        }, */
        page: {
            size: 'A4 portrait',
            margin: '2cm',
        },
        pagebreak: {
            clear: 'both',
            pageBreakAfter: 'always',
          }
      });
    return ( 
        <>
            {isLoading ? <CircularProgress style={{color:'#848474', position: 'absolute', top: '50%', left: '50%'}} /> :
                <PDFViewer style={{ width:"100%", height:"100vh"}}>
                    <Document size="A4" margin="2cm">
                        <Page style={styles.body}>
                            <View style={styles.pdfHeader} fixed>
                                <Image style={styles.pdfHeaderImg} src={LogoNF} alt="Logo NF" />
                            </View>
                            <View>
                                <View>
                                    <Text style={styles.h1}>IDENTIFICACIÓN DE RIESGOS</Text>
                                    <View ><Text style={styles.h2}>Comunidad de propietarios: {hojaVisita?.comunidad.razonSocial}</Text> </View>
                                    <View><Text style={styles.h2}>Centro de Trabajo: {centroTrabajo?.nombre}</Text> </View>
                                    <View><Text style={styles.h2}>Fecha: {hojaVisita?.fechaMostrar }</Text> </View>
                                    {/* <View><Image source={{uri:hojaVisita?.imgURL, headers: {'Content-type': '/image\/(png|jpg|jpeg)/i', AccessControlAllowOrigin: "*", 'Access-Control-Allow-Headers':'Content-Type'}}} style={{height:'20px', width:'20px'}} alt="img" /></View> */}
                                    <Image style={styles.imgPrincipal} src={hojaVisita?.imgURL} />
                                </View>
                            </View>
                            <View break>
                                <View>
                                    <Text style={styles.h3}>1. Introducción</Text>
                                    <Text style={styles.p}>
                                        A petición de {hojaVisita?.comunidad.razonSocial} se realiza la visita con motivo de llevar a cabo un análisis de las condiciones de trabajo y realizar la Identificación de los peligros existentes en el centro de trabajo.<br />
                                        Este documento es fiel reflejo del estado de situación del centro de trabajo y los riesgos que se identifican corresponden al análisis realizado en la fecha anteriormente indicada. Cualquier modificación o alteración de las instalaciones actuales conllevaría la necesidad de actualización del presente informe.< br />
                                    </Text>
                                    <Text style={styles.h3}>2. Metodología</Text>
                                    <Text style={styles.p}>
                                        La identificación de riesgos se realizará mediante la observación del conjunto de las instalaciones y teniendo en cuenta a los diferentes proveedores de mantenimiento que puedan tener acceso a las mismas.<br />
                                        Los técnicos que han llevado a cabo este informe valoran y ponderan los diferentes riesgos en función de su peligrosidad y el riesgo que implican para los trabajadores de aquellas empresas que presten servicios de mantenimiento en el centro de trabajo.<br />
                                        Todos los riesgos detectados y valorados anteriormente tendrán sus correspondientes medidas correctoras, con el fin de eliminar o disminuir los riesgos. Las acciones correctoras tendrán un orden de preferencia según su peligrosidad.<br />
                                    </Text>
                                    <Text style={styles.h3}>3. Datos de la comunidad</Text>
                                    <Text style={styles.p}>Comunidad de propietarios: {hojaVisita?.comunidad.razonSocial}</Text>
                                    <Text style={styles.p}>Dirección: {centroTrabajo.nombre}</Text>
                                    <Text style={styles.p}>Localidad: {hojaVisita?.comunidad.localidad}</Text>
                                    <Text style={styles.p}>C.I.F.: {hojaVisita?.comunidad.nif}</Text>
                                    <Text style={styles.p}>Sector: COMUNIDAD DE PROPIETARIOS</Text>
                                    <Text style={styles.p}>Centro de Trabajo Analizado: {centroTrabajo.nombre}, {hojaVisita?.comunidad.codigoPostal}, {hojaVisita?.comunidad.localidad}</Text>
                                    <Text style={styles.h3}>4. Datos de la identificación de riesgos</Text>
                                    <Text style={styles.p}>Fecha de realización: {hojaVisita?.fechaRealizacion}</Text>
                                </View>
                            </View>
                            <View break>
                                <Text style={styles.h3}>5. Identificación de riesgos y acciones correctoras</Text>
                                <Text style={styles.p}>
                                    En este apartado se indican las diferentes zonas en las que se ha clasificado u organizado la comunidad de propietarios para la identificación de los factores de riesgo existentes en el centro.<br />
                                    Para cada factor de riesgo encontrado, se identifican y estiman los riesgos existentes para los trabajadores tanto propias como externos, aplicando, para cada uno de ellos, las medidas de prevención necesarias para evitar o controlar la situación de riesgo. A continuación, se determinan las acciones preventivas a desarrollar en función de la visita realizada en la comunidad de propietarios:<br />
                                </Text>
                                {centroTrabajo?.p1_2 !== 's' && centroTrabajo?.p1_1 !== 's' && centroTrabajo?.p3_4 !== 'n' && centroTrabajo?.p1_4 !== 's' && centroTrabajo?.p1_5 !== 's' && centroTrabajo?.p1_6 !== 's' 
                                && centroTrabajo?.p1_7 !== 's' && centroTrabajo?.p1_8 !== 'n' && centroTrabajo?.p2_1 !== 'n' && centroTrabajo?.p2_2 !== 'n' && centroTrabajo?.p2_3 !== 'n' && centroTrabajo?.p2_4 !== 'n' 
                                && centroTrabajo?.p2_5 !== 'n' && centroTrabajo?.p2_6 !== 'n' && centroTrabajo?.p2_7 !== 'n' && centroTrabajo?.p2_8 !== 'n' && centroTrabajo?.p2_9 !== 'n' && centroTrabajo?.p2_10 !== 'n' 
                                && centroTrabajo?.p3_1 !== 'n' && centroTrabajo?.p3_2 !== 'n' && centroTrabajo?.p3_3 !== 'n' && centroTrabajo?.p3_4 !== 'n' && centroTrabajo?.p4_1 !== 'n' && centroTrabajo?.p4_2 !== 'n' 
                                && centroTrabajo?.p4_3 !== 'n' && centroTrabajo?.p4_4 !== 'n' && centroTrabajo?.p4_6 !== 'n' && centroTrabajo?.p4_7 !== 'n' && centroTrabajo?.p4_8 !== 'n' && centroTrabajo?.p4_9 !== 'n' 
                                && centroTrabajo?.p4_10 !== 'n' &&centroTrabajo?.p4_11 !== 'n' && centroTrabajo?.p4_12 !== 'n' && centroTrabajo?.p4_13 !== 'n' && centroTrabajo?.p4_14 !== 'n' && centroTrabajo?.p4_15 !== 'n' 
                                && centroTrabajo?.p4_16 !== 'n' && centroTrabajo?.p4_17 !== 'n' && centroTrabajo?.p5_1 !== 'n' && centroTrabajo?.p5_2 !== 'n' && centroTrabajo?.p6_1 !== 's' && centroTrabajo?.p6_2 !== 'n' 
                                && centroTrabajo?.p6_3 !== 'n' && centroTrabajo?.p7_1 !== 'n' && centroTrabajo?.p7_2 !== 'n' && centroTrabajo?.p7_3 !== 'n' && centroTrabajo?.p8_1 !== 'n' && centroTrabajo?.p8_2 !== 'n' 
                                && centroTrabajo?.p8_3 !== 'n' && centroTrabajo?.p8_4 !== 'n' && centroTrabajo?.p8_5 !== 'n' && centroTrabajo?.p9_1 !== 'n' && centroTrabajo?.p9_2 !== 'n' && centroTrabajo?.p9_3 !== 'n'
                                && centroTrabajo?.p10_1 !== 'n' && centroTrabajo?.p10_2 !== 'n' && centroTrabajo?.p10_3 !== 'n' && centroTrabajo?.p10_4 !== 'n' && centroTrabajo?.p10_5 !== 'n' && centroTrabajo?.p10_6 !== 'n'
                                && centroTrabajo?.p11_1 !== 'n' && centroTrabajo?.p11_2 !== 'n' && centroTrabajo?.p12_1 !== 'n' && centroTrabajo?.p12_2 !== 'n' && centroTrabajo?.p13_0 !== 's' ? 
                                    <>
                                        <Text style={styles.br}></Text>
                                        <Text style={styles.p}>El día de la visita no se contemplan riesgos en el centro de trabajo. Se recomienda proseguir con los trabajos de mantenimiento habituales.</Text>
                                    </>
                                :
                                    <>
                                        <View style={styles.table}> 
                                            <View style={styles.tableRow}> 
                                                <View style={styles.tableColHeader}> 
                                                    <Text style={styles.tableCell}>Descripción del riesgo</Text> 
                                                </View> 
                                                <View style={styles.tableColHeader}> 
                                                    <Text style={styles.tableCell}>Origen</Text> 
                                                </View> 
                                                <View style={styles.tableColHeader}> 
                                                    <Text style={styles.tableCell}>Valoración del riesgo</Text> 
                                                </View> 
                                                <View style={styles.tableColHeader1}> 
                                                    <Text style={styles.tableCell}>Acción correctora</Text> 
                                                </View> 
                                            </View>
                                            {hojaVisita?.p1_2 === 's' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Golpes y cortes por objetos y herramientas</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>General</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Sustituir los cristales afectados, por otros en correcto estado.</Text> 
                                                    </View> 
                                                </View> 
                                            : null}
                                            {hojaVisita?.p1_1 === 's' ?
                                            <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Caídas de personas a diferente nivel</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>General</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Importante</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Reparar los desperfectos encontrados en el suelo.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p3_4 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Caídas de personas a diferente nivel</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>General</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Importante</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Cubrir las aberturas en el suelo o colocar barandillas, barras intermedias y plintos en todo el perímetro de los huecos.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p1_4 === 's' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Contactos eléctricos</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>General</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Importante</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Canalizar todo el cableado de la instalación eléctrica mediante canaletas o pasacables. En especial las que crucen zonas de paso</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p1_5 === 's' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Contactos eléctricos</Text>
                                                        <Text style={styles.br} ></Text>
                                                        <Text style={styles.tableCell}>Cajas de registro abiertas.</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>General</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Realizar revisiones periódicas de la instalación eléctrica y de los equipos eléctricos por personal autorizado (también revisar el cableado, conectores, enchufes, etc.). Documentar las revisiones. Cuadro eléctrico</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p1_6 === 's' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Caídas de personas a distinto nivel</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>General</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Adecuar las rampas con tiras antideslizantes</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p1_7 === 's' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Contactos eléctricos</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>General</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p1_8 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Contactos eléctricos</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>General</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Importante</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Señalizar el riesgo de contacto eléctrico en los cuadros eléctricos de las instalaciones de la empresa mediante pictograma homologado (triángulo negro sobre fondo amarillo).</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p2_1 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Ascensores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Revisar periódicamente los ascensores mediante una empresa de mantenimiento y por personal autorizado</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p2_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Ascensores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Dotar de iluminación interior 24h. Revisarla periódicamente</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p2_3 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Ascensores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Dotar de iluminación exterior 24h. Revisarla periódicamente</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p2_4 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Ascensores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Dotar de alarma con conexión 24h. Revisarla periódicamente</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p2_6 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Ascensores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Comprobar y proteger el motor</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p2_5 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Ascensores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Cerrar el acceso a la sala de máquinas para personal no autorizado.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p2_7 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Contacto eléctrico</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Ascensores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Señalizar el riesgo eléctrico</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p2_8 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Ascensores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Disponer de un extintor de CO² en el interior de la sala de máquinas, a una altura máxima sobre su punto de anclaje de 1,20 m del suelo</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p2_9 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Ascensores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Dispone de un extintor, preferentemente, de CO² en el exterior de la sala de maquinas</Text> 
                                                    </View>
                                                </View> 
                                            : null}      
                                            {hojaVisita?.p2_10 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Atrapamientos</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Ascensores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Instalar un cartel con el texto: “Cuarto de maquinaria del ascensor</Text> 
                                                    </View>
                                                </View> 
                                            : null}     
                                            {hojaVisita?.p2_10 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Atrapamientos</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Ascensores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Colocar un cartel con el texto “Se prohíbe la entrada a toda persona no autorizada</Text> 
                                                    </View>
                                                </View> 
                                            : null}        
                                            {hojaVisita?.p3_1 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Azotea</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Delimitar y señalizar la zona de la azotea para evitar el acceso de cualquier persona ajena a la actividad.</Text> 
                                                    </View>
                                                </View> 
                                            : null} 
                                            {hojaVisita?.p3_1 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Azotea</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Disponer de un acceso seguro a la zona de la azotea. Prohibir el acceso mediante escaleras de mano</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p3_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Azotea</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Instalar escaleras de servicio para el acceso seguro a la zona de azoteas</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p3_3 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Azotea</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Proteger el perímetro de la zona de la azotea mediante barandillas. Estas deben disponer de pasamanos, barra intermedia y rodapié</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p3_3 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Azotea</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Importante</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Estudiar la posibilidad de instalar una línea de vida</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p3_4 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Azotea</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Importante</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Suspender los trabajos en azoteas los días de viento fuerte o cuando las condiciones climáticas así lo aconsejen (lluvia, nieve, granizo, viento).</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_1 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Disponer e instalar extintores suficientes en las instalaciones. El número mínimo de extintores deberá ser el suficiente para que el recorrido real en cada planta desde cualquier origen de evacuación hasta un extintor no supere los 15 metros.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Revisar cada tres meses, de forma ocular, los extintores de la empresa</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                                    
                                            {hojaVisita?.p4_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Comprobación de la accesibilidad, señalización, buen estado aparente de conservación</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Inspección ocular de seguros, precintos, inscripciones, etc. </Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Comprobación del peso y presión en su caso.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Inspección ocular del estado externo de las partes metálicas (boquilla, válvula, manguera, etc.).</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Realizar revisiones periódicas reglamentarias de los extintores, según el Real Decreto 513/2017</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_3 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Señalizar los medios de extinción de incendios mediante pictograma fotoluminiscente (fondo rojo y letras blanca) instalando correctamente la señal tipo cartel indicativa del lugar de ubicación del extintor, de manera que indique claramente su ubicación.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_4 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Colgar los extintores a una altura máxima sobre su punto de anclaje de 1,20 m del suelo.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_6 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Señalizar los recorridos y las salidas de evacuación con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_7 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Instalar luces de emergencia sobre las puertas de salida, para dotar de suficiente visibilidad el recorrido de evacuación y las salidas de emergencia ante una falta de iluminación</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_8 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Señalizar las direcciones de circulación en el interior del parking</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_9 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Señalizar la dirección y sentido de acceso en las entradas al parking.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_9 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Instalar luz de advertencia giratoria en las entradas al parking, de manera que advierta de la salida de vehículos</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_10 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Importante</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Dotar de sistemas automáticos de extinción de incendios (rociadores)</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_11 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Importante</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Instalar sistemas de extracción de humos en las instalaciones</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_12 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Importante</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Revisar periódicamente la BIE por una empresa homologada y especializada.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_13 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Revisar periódicamente los detectores de humo de las instalaciones o una empresa homologada y especializada</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_14 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Revisar periódicamente el correcto estado de funcionamiento de la central de alarmas, en especial los pulsadores mediante una empresa homologada y especializada</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_15 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Revisar periódicamente el correcto estado de funcionamiento de la central de alarmas, mediante una empresa homologada y especializada</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_16 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p4_17 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Contactos eléctricos</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Parking</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Señalizar el cuadro eléctrico con "señal riesgo eléctrico". Pictograma triangular amarillo con letras negras</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p5_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Exposición a contaminantes químicos</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Jardín</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Importante</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Disponer de un armario cerrado bajo llave </Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p5_3 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Exposición a contaminantes químicos</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Jardín</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Se solicitarán las Fichas de datos de seguridad química, a los proveedores, de todos los productos fitosanitarios que se utilizan y se pondrán a disposición de las personas que los manipulen.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p5_3 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Exposición a contaminantes químicos</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Jardín</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Proporcionar una copia de las fichas de datos de seguridad de los productos químicos al Servicio de prevención. Se actualizará la información en caso de utilización de nuevos productos.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p5_3 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Exposición a contaminantes químicos</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Jardín</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Poner a disposición de los trabajadores la información contenida en las fichas de datos de seguridad de los productos químicos utilizados.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p6_1 === 's' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Exposición a contaminantes químicos</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Piscina</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Almacenar y manipular los productos fitosanitarios según las indicaciones de las Fichas de datos de seguridad de productos químicos.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p6_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Caídas de personal a diferente nivel</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Piscina</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Durante el trabajo en las inmediaciones de la piscina deberá respetarse la señalización y el vallado de la misma.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p6_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Caídas de personal a diferente nivel</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Piscina</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Extremar la precaución en los desplazamientos por el lugar de trabajo (borde de la piscina, plataformas flotantes, suelos mojados y con desnivel, etc.).</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p6_3 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Otros</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Piscina</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Disponer de un aro salvavidas en las inmediaciones de la piscina.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {/* {hojaVisita?.p7_1 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Otros</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Instalación de Gas</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Importante</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Mantener cerrada la sala de gas</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p7_1 === 'n' || hojaVisita?.p7_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Otros</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Instalación de Gas</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Señalizar la sala de gas mediante cartel indicativo del riesgo</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p7_3 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Explosiones</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Instalación de Gas</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Disponer de un extintor de polvo ABC en las inmediaciones de la sala de gas</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p8_1 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Otros</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Instalación de Gasoil</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Importante</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Cerrar bajo llave la sala de gasoil</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p8_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Otros</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Instalación de Gasoil</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Señalizar la sala de gasoil</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p8_3 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Otros</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Instalación de Gasoil</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Señalizar el depósito de gasoil con el pictograma de inflamable.</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p8_4 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Instalación de Gasoil</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Dotar de cubeta de retención de fugas y vertidos accidentales</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p8_5 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Instalación de Gasoil</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Instalar un extintor de polvo ABC en las inmediaciones de la sala de gasoil</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p9_1 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Instalación gas ciudad</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Disponer de un extintor de polvo ABC en las inmediaciones de la sala de gas</Text> 
                                                    </View>
                                                </View> 
                                            : null}
                                            {hojaVisita?.p9_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Instalación gas ciudad</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Disponer de cartel en las inmediaciones del cuarto de gas con el texto: "Cuarto de Gas. Peligro de explosión".</Text> 
                                                    </View>
                                                </View> 
                                            : null} */}
                                            {hojaVisita?.p10_1 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Extintores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Importante</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>En todo edificio se dispondrán extintores en número suficiente para que el recorrido real en cada planta desde cualquier origen de evacuación hasta un extintor no supere los 15 m.</Text> 
                                                    </View>
                                                </View>
                                            : null}
                                            {/* {hojaVisita?.p10_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Extintores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Los extintores deben ser adecuados al tipo de fuego previsible. Serán suficientes en número para que el recorrido real en cada planta desde cualquier origen de la evacuación hasta el extintor no supere los 15 m. Estarán bien ubicados, se revisarán periódicamente y se formará a los trabajadores en su correcto uso.</Text> 
                                                    </View>
                                                </View>
                                            : null} */}
                                            {hojaVisita?.p10_3 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Extintores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Revisar cada tres meses el estado aparente de conservación, de carga y del botellín de gas impulsor. Verificar su carga con una periodicidad anual y cada 5 años retimbrar el extintor de acuerdo con el Reglamento de Aparatos a Presión sobre Extintores de Incendios.</Text> 
                                                    </View>
                                                </View>
                                            : null}
                                            {hojaVisita?.p10_4 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Extintores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Señalizar todos los equipos de protección contra incendios, con su respectivo pictograma.</Text> 
                                                    </View>
                                                </View>
                                            : null}
                                            {hojaVisita?.p10_5 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Extintores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Situar de forma tal que el extremo superior del extintor se encuentre a una altura sobre el suelo menor que 1,20m</Text> 
                                                    </View>
                                                </View>
                                            : null}
                                            {/* {hojaVisita?.p10_6 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Extintores</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Moderado</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Mantener en buen estado de conservación los extintores según el Reglamento de aparatos a presión sobre extintores de incendios.</Text> 
                                                    </View>
                                                </View>
                                            : null} */}
                                            {hojaVisita?.p11_1 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Iluminación</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Equipar las vías y salidas de evacuación con iluminación de seguridad de suficiente intensidad según Reglamento actual.</Text> 
                                                    </View>
                                                </View>
                                            : null}
                                            {hojaVisita?.p11_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Iluminación</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Equipar las salidas de la finca con iluminación de suficiente intensidad.</Text> 
                                                    </View>
                                                </View>
                                            : null}
                                            {hojaVisita?.p12_1 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Señalización</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Señalizar las salidas de la finca con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida.</Text> 
                                                        <Text style={styles.br}></Text>
                                                        <Image style={{ width:'50px' }} src={salida} alt="Salida" />
                                                    </View>
                                                </View>
                                            : null}
                                            {hojaVisita?.p12_2 === 'n' ?
                                                <View style={styles.tableRow}> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Incendios</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}>Señalización</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Tolerable</Text> 
                                                    </View>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}>Señalizar los recorridos y las salidas de evacuación con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida.</Text> 
                                                        <Text style={styles.br}></Text>
                                                        <Image style={{ width:'50px' }} src={salidaEmergencia} alt="Salida de emergencia" />
                                                    </View>
                                                </View>
                                            : null}
                                        </View>

                                        {hojaVisita?.p13_0 === 's' ?
                                            <>
                                                <Text style={styles.h3}>9. Identificación de riesgos, acciones correctoras y planificación preventiva en comunidades con trabajadores</Text>
                                                <View style={styles.table}>
                                                    <View style={styles.tableRow}> 
                                                        <View style={styles.tableColHeader}> 
                                                            <Text style={styles.tableCell}>Descripción del riesgo</Text> 
                                                        </View> 
                                                        <View style={styles.tableColHeader}> 
                                                            <Text style={styles.tableCell}>Origen</Text> 
                                                        </View> 
                                                        <View style={styles.tableColHeader}> 
                                                            <Text style={styles.tableCell}>Valoración del riesgo</Text> 
                                                        </View> 
                                                        <View style={styles.tableColHeader1}> 
                                                            <Text style={styles.tableCell}>Acción correctora</Text> 
                                                        </View> 
                                                    </View>
                                                    {hojaVisita?.p13_1 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Posturas forzadas y/o estáticas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>El personal de limpieza debe disponer de los equipos de trabajo y material necesario para poder realizar su trabajo de la forma más segura y fácil posible de tal manera que minimice la realización de posturas forzadas al realizar las tareas de limpieza.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_2 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Posturas forzadas y/o estáticas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Palos alargaderas para limpiar cristales, techos, zonas de difícil acceso y todas aquellas que impliquen trabajos con las manos por encima de la cabeza.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_3 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Posturas forzadas y/o estáticas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Escaleras pequeñas para facilitar el acceso a zonas elevadas (armarios, estanterías, etc.)</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_3 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Posturas forzadas y/o estáticas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Estructuras de mopas adecuadas a las tareas y zonas a limpiar.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_3 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Posturas forzadas y/o estáticas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Tolerable</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Recogedores, mopas, escobas con mangos largos.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_3 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Posturas forzadas y/o estáticas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Carros para transportar el material.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_3 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Importante</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Se dotará a los trabajadores de arneses de seguridad, cuerda y puntos de amarre seguros para la realización de trabajaos a alturas superiores a 2 metros. Registrar su entrega.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_3 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Tolerable</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Establecer que las escaleras de mano se utilizarán sólo en aquellas circunstancias en las que el riesgo sea de bajo nivel o que por las características de los emplazamientos no se justifique el uso de otros equipos de trabajo más seguros (andamios, elevadores, etc.).</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_4 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Importante</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Informar a los trabajadores sobre el correcto uso de productos de limpieza</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_4 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Tolerable</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Se recomienda señalizar las zonas de trabajo, zonas de paso y zonas destinadas a almacén, de esta manera se asegura un correcto estado de orden y limpieza en las instalaciones.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_4 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Exposición a contaminantes químicos</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Tolerable</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Proporcionar una copia de las fichas de datos de seguridad de los productos químicos al Servicio de prevención. Se actualizará la información en caso de utilización de nuevos productos.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_4 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Exposición a contaminantes químicos</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Importante</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Poner a disposición de los trabajadores la información contenida en las fichas de datos de seguridad de los productos químicos utilizados.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_5 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Caídas de personas a diferente nivel</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Tolerable</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Proporcionar al trabajador señal que indique “peligro suelo resbaladizo”</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_6 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Contactos eléctricos</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Realizar las tareas de mantenimiento (cambio de bombillas, fluorescentes, etc.) con la corriente eléctrica desconectada.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_7 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Caídas de personas a diferente nivel</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Importante</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Se dotará a los trabajadores de arneses de seguridad, cuerda y puntos de amarre seguros para la realización de trabajaos a alturas superiores a 2 metros. Registrar su entrega.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_8 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Golpes y cortes por objetos y herramientas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Proporcionar guantes de resistencia mecánica y de abrasión para la manipulación de bolsas de basura, cristales rotos, etc. Registrar su entrega.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_8 === 's' ?
                                                            <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Golpes y cortes por objetos y herramientas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Tolerable</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Establecer como obligatorio el uso de guantes de seguridad para la manipulación de basuras.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_9 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}></Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Tolerable</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Proporcionar a los trabajadores mascarillas de protección contra contaminantes biológicos. Registrar su entrega.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_10 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}></Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Importante</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Formar e informar al trabajador sobre los riesgos específicos de su puesto de trabajo.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_11 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}></Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Importante</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Se debe ofrecer la Vigilancia de la salud a todos los trabajadores, teniendo en cuanta la posible existencia de puestos de trabajo con reconocimiento obligatorio.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_11 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}></Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>???</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>En el caso de renuncia por parte de los trabajadores a realizarse el reconocimiento médico, esta deberá documentarse por escrito.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_12 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Durante el trabajo en las inmediaciones de la piscina deberá respetarse la señalización y el vallado de la misma.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_12 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Extremar la precaución en los desplazamientos por el lugar de trabajo (borde de la piscina, plataformas flotantes, suelos mojados y con desnivel, etc.).</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_13 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Exposición a contaminantes químicos</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Importante</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Suministrar a los trabajadores los equipos de protección indicados en las etiquetas y fichas de los productos de limpieza utilizados.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_14 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Contacto con productos químicos</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Se establecerá y se verificará la utilización de guantes de protección en el uso de productos fitosanitarios del tipo "guantes contra productos químicos".</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_15 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Contacto con productos químicos</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>El nivel de prestación y mantenimiento se adaptará a las indicaciones de las fichas de seguridad química y al folleto informativo del fabricante del EPI.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_15 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Contacto con productos químicos</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>En la aplicación de productos fitosanitarios se establecerá y vigilará el uso obligatorio de ropa de trabajo adecuada, pantalón largo y manga larga.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_15 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Contacto con productos químicos</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Tolerable</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Se solicitarán las Fichas de datos de seguridad química, a los proveedores, de todos los productos fitosanitarios que se utilizan y se pondrán a disposición de los trabajadores.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_16 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Golpes y cortes por objetos y herramientas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Facilitar al trabajador escaleras de mano adecuadas para realizar la poda y el mantenimiento de los árboles. Esta debe estar provista de zapatas regulables o hincas; abrazaderas, ganchos o sistema de apoyo y sujeción en su parte superior (tipo apoyo en postes).</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_16 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Golpes y cortes por objetos y herramientas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Importante</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Proporcionar a los trabajadores arnés de seguridad, cuerdas de seguridad y punto de amarre seguro para atarse durante la realización de las tareas de poda desde los árboles. Registrar su entrega.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_16 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Golpes y cortes por objetos y herramientas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Importante</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Proporcionar al trabajador guantes de seguridad para su uso durante la manipulación de herramientas de poda manuales. Realizar el control de entrega del equipo.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_16 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Golpes y cortes por objetos y herramientas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Importante</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Informar a los trabajadores sobre los riesgos y las medidas preventivas a seguir durante el uso de herramientas de poda manuales.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_16 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Golpes y cortes por objetos y herramientas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Importante</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Proporcionar pantalla de protección facial/ocular con resistencia mecánica frente a impactos en operaciones de susceptibles de proyección de partículas como segado del césped o trabajos de poda. Se debe de registrar su entrega.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_17 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Golpes y cortes por objetos y herramientas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Velar por el uso, por parte del trabajador, del calzado y guantes de seguridad durante el uso de herramientas de mano y la manipulación de piezas pesadas.</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}></Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_17 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Golpes y cortes por objetos y herramientas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Tolerable</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Habilitar espacios y elementos donde ubicar las herramientas de mano, de tal manera que se consiga una ubicación ordenada de las herramientas (cajas de herramientas con ruedas, armarios, estanterías, etc.)</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_17 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Golpes y cortes por objetos y herramientas</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Importante</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Proporcionar al trabajador guantes de seguridad para la manipulación de herramientas de mano, manipulación de plantas o flores que presenten espinas, recogida de materiales cortantes, etc. Realizar el control de entrega del equipo.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_18 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}></Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Informar a los trabajadores sobre los riesgos y medidas preventivas a seguir en el uso de escaleras de mano.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_18 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Facilitar al trabajador escaleras de mano adecuadas para realizar la poda y el mantenimiento de los árboles. Esta debe estar provista de zapatas regulables o hincas; abrazaderas, ganchos o sistema de apoyo y sujeción en su parte superior (tipo apoyo en postes).</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_18 === 's' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Caída de personal a diferente nivel</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Revisar periódicamente las escaleras de mano, asegurándose que:</Text> 
                                                                <Text style={styles.br}></Text>
                                                                <View style={styles.ul}>
                                                                    <View style={styles.ol}>
                                                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                                        <Text>Disponen de zapatas antideslizantes</Text>
                                                                    </View>
                                                                    <View style={styles.ol}>
                                                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                                        <Text>Se usan los dispositivos de agarre en el cabezal de la escalera cuando se trabaja con ella.</Text>
                                                                    </View>
                                                                    <View style={styles.ol}>
                                                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                                        <Text>Los peldaños no están flojos, rotos, agrietados, etc.</Text>
                                                                    </View>
                                                                    <View style={styles.ol}>
                                                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                                        <Text>Dispone de sistema anti apertura.</Text>
                                                                    </View>
                                                                </View>
                                                                
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_19 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}></Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Informar de la mutua de accidentes al trabajador.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_20 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}></Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Moderado</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Informar del centro asistencial más cercano.</Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}
                                                    {hojaVisita?.p13_21 === 'n' ?
                                                        <View style={styles.tableRow}> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}></Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Trabajador</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol}> 
                                                                <Text style={styles.tableCell}>Tolerable</Text> 
                                                            </View> 
                                                            <View style={styles.tableCol1}>
                                                                <Text style={styles.tableCell}>Establecer un protocolo de comunicación entre el trabajador y la comunidad/administrador de la finca </Text> 
                                                            </View> 
                                                        </View> 
                                                    : null}    
                                                </View>
                                            </>
                                        : null}
                                    </>
                                }
                                <Text style={styles.h3}>6. Conclusión</Text>
                                <Text style={styles.p}>
                                    La identificación de riesgos se ha realizado conforme a la situación actual del centro de trabajo. El informe ha sido realizado por <Text style={{textTransform:"capitalize"}}>{hojaVisita?.tecnico.nombre}</Text> , técnico en prevención de riesgos laborales. <br />
                                </Text>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            }
            <style jsx>{`
                .imgPrincipal{
                    background-image: url(${hojaVisita?.imgURL});
                    width:100%;
                    height:40px;
                }
        `}</style>
        </>
     );
}
 
export default withRouter(EvaluacionRiesgosPDF);