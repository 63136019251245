
import React from 'react'
/* import * as FileSaver from "file-saver"; */
import * as XLSX from "xlsx";
import Excel from "../icons/excel.svg"
export const ExportToExcel = ({ fincasPendientes, fincasRenovadasPendientes, fincasVisitadas, fincasRenovadasVisitadas }) => {
  /* const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"; */
  const fileExtension = ".xlsx";

  // Agregar elementos de `centrosTrabajo` al array inicial
  // apiData.forEach((item, index) => {
  //   if (Array.isArray(item.centrosTrabajo)) {
  //     item.centrosTrabajo.forEach((centro) => {
  //       const newItem = { ...item, ...centro };
  //       delete newItem.centrosTrabajo;
  //       apiData.splice(index + 1, 0, newItem);
  //       console.log(newItem)
  //     });
  //   }
  // });

  // console.log(apiData)

  const exportar = (fincasPendientes, fincasRenovadasPendientes, fincasVisitadas, fincasRenovadasVisitadas) => {
    const fP = fincasPendientes.flatMap(item => {
      if (Array.isArray(item.centrosTrabajo)) {
        return item.centrosTrabajo.map(centro => {
          
          if (centro.fechaNoVisita) {
            const fechaNoVisitaLegible = new Date(
              centro.fechaNoVisita.seconds * 1000
            );
            centro.fechaNoVisita = fechaNoVisitaLegible.toLocaleDateString();
          }
          delete centro.nif;
          delete centro.noVisitada;
          delete centro.documentos;
          delete item.centrosTrabajo;
          console.log(item, centro)
          return { ...item, ...centro };
        });
      } else {
        delete item.centrosTrabajo;
        delete item.noVisitada;
        delete item.documentos;
        return item;
      }
    });
    const fRP = fincasRenovadasPendientes.flatMap(item => {
      if (Array.isArray(item.centrosTrabajo)) {
        return item.centrosTrabajo.map(centro => {
          
          if (centro.fechaNoVisita) {
            const fechaNoVisitaLegible = new Date(
              centro.fechaNoVisita.seconds * 1000
            );
            centro.fechaNoVisita = fechaNoVisitaLegible.toLocaleDateString();
          }
          delete centro.nif;
          delete centro.noVisitada;
          delete centro.documentos;
          delete item.centrosTrabajo;
          console.log(item, centro)
          return { ...item, ...centro };
        });
      } else {
        delete item.centrosTrabajo;
        delete item.noVisitada;
        delete item.documentos;
        return item;
      }
    });
    const fV = fincasVisitadas.flatMap(item => {
      if (Array.isArray(item.centrosTrabajo)) {
        return item.centrosTrabajo.map(centro => {
          
          if (centro.fechaNoVisita) {
            const fechaNoVisitaLegible = new Date(
              centro.fechaNoVisita.seconds * 1000
            );
            centro.fechaNoVisita = fechaNoVisitaLegible.toLocaleDateString();
          }
          delete centro.nif;
          delete centro.noVisitada;
          delete centro.documentos;
          delete item.centrosTrabajo;
          console.log(item, centro)
          return { ...item, ...centro };
        });
      } else {
        delete item.centrosTrabajo;
        delete item.noVisitada;
        delete item.documentos;
        return item;
      }
    });
    const fRV = fincasRenovadasVisitadas.flatMap(item => {
      if (Array.isArray(item.centrosTrabajo)) {
        return item.centrosTrabajo.map(centro => {
          
          if (centro.fechaNoVisita) {
            const fechaNoVisitaLegible = new Date(
              centro.fechaNoVisita.seconds * 1000
            );
            centro.fechaNoVisita = fechaNoVisitaLegible.toLocaleDateString();
          }
          delete centro.nif;
          delete centro.noVisitada;
          delete centro.documentos;
          delete item.centrosTrabajo;
          console.log(item, centro)
          return { ...item, ...centro };
        });
      } else {
        delete item.centrosTrabajo;
        delete item.noVisitada;
        delete item.documentos;
        return item;
      }
    });
    

  // console.log(newData)
  // Ajustar el ancho de las columnas al contenido
  
  
    // const workSheet = XLSX.utils.json_to_sheet(newData)
    const sheet1 = XLSX.utils.json_to_sheet(fP);
    const sheet2 = XLSX.utils.json_to_sheet(fRP);
    const sheet3 = XLSX.utils.json_to_sheet(fV);
    const sheet4 = XLSX.utils.json_to_sheet(fRV);

    //se adapta las columnas al texto más largo
    /* const cols = Object.keys(newData[0]);
    const columnWidths = cols.map(columna => {
      const maxTextLength = Math.max(
        ...newData.map(row => row[columna]?.toString().length)
      );
      const columnWidth = Math.min(30, Math.max(10, maxTextLength));
      return { wch: columnWidth };
    }); */
    // workSheet['!cols'] = columnWidths;


    const workBook = XLSX.utils.book_new()
    // XLSX.utils.book_append_sheet(workBook, workSheet, "Listado")
    XLSX.utils.book_append_sheet(workBook, sheet1, 'Fincas Pendientes');
    XLSX.utils.book_append_sheet(workBook, sheet2, 'Fincas Renovadas Pendientes');
    XLSX.utils.book_append_sheet(workBook, sheet3, 'Fincas Visitadas');
    XLSX.utils.book_append_sheet(workBook, sheet4, 'Fincas Renovadas Visitadas');
    let buf = XLSX.write(workBook, {bookType:"xlsx", type:"buffer"})
    XLSX.write(workBook, {bookType:"xlsx", type:"binary"})
    XLSX.writeFile(workBook, "Gestión fincas" + fileExtension)
  };

  return (
    <button className='bigBtn' onClick={(e) => exportar(fincasPendientes, fincasRenovadasPendientes, fincasVisitadas, fincasRenovadasVisitadas)}><img src={Excel} alt="exportar listado" width="30px"/> Exportar listado</button>
  );
};