import React from "react";
import { firestore } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";/* 
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch'; */
import HeaderBackground from "../../../images/banner_proveedores.jpg"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tick from "../../../icons/tick.svg"
import Cross from "../../../icons/cross.svg"
import Minus from "../../../icons/minus.svg"
import { Link } from "react-router-dom";
import { abrirArchivo, subirDocumentacionProveedor, subirDeclaracionResponsable } from "../../../providers/documentos";
import orderArray from "../../../providers/orderArray";

import { serviciosProveedores } from "../../../providers/serviciosProveedores";

/* const CustomSwitch = withStyles({
  switchBase: {
    color: "#FF5252",
    '&$checked': {
      color: "#7bc977",
    },
    '&$checked + $track': {
      backgroundColor: "#7bc977",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#FF5252"
  },
})(Switch);

const CustomPendienteSwitch = withStyles({
  switchBase: {
    color: "#FFD700"
  },
  checked: {},
  track: {
    backgroundColor: "#FFD700"
  },
})(Switch);
 */
class Proveedor extends React.Component {
  constructor() {
    super()
    this.state = {
      aaff: {},
      proveedor: undefined,
      dialog: {
        opened: false,
        title: "",
        docName: "",
      },
      verDocumentacion: {
        opened: false,
        nombreDoc: undefined
      },
      subirDocumentacion: {
        opened: false,
        nombreDoc: undefined,
        fecha: undefined
      }
    }
  }
  
  componentDidMount() {
    firestore.collection("aaff").doc(this.props.params.nifAAFF).get().then(doc => this.setState({ aaff: doc.data() })).catch(err => window.location.replace("/aaff"))
    this.loadPageContent()
  }
  loadPageContent = () => {
    firestore.collection("fincas").where("nifProveedores", "array-contains", this.props.params.nifProveedor).orderBy("razonSocial").get().then(snapshot => {
      let aaffs = []
      let nifAAFF = []
      snapshot.docs.forEach(doc => {
        let finca = doc.data()
        nifAAFF.push(finca.nifAAFF)
      })
      nifAAFF = [...new Set(nifAAFF)]
      let promises = []
      nifAAFF.forEach(aaff => promises.push(firestore.collection("aaff").doc(aaff).get().then(docAAFF => aaffs.push(docAAFF.data()))))
      Promise.all(promises).then(() => {
        this.setState({ aaffs: orderArray(aaffs) })
        //console.log(aaffs)
      })
    })
    firestore.collection("proveedores").doc(this.props.params.nifProveedor).get().then(snapshot => {
      let prov = snapshot.data();
      let regexp = new RegExp("(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)")
      let declResp = {}
      for (let nifAAFF in prov.documentos?.declaracionResponsable) {
        if (regexp.test(nifAAFF)) {
          declResp[nifAAFF] = { ...prov.documentos?.declaracionResponsable?.[nifAAFF] }
        }
      }
      this.setState({ prov: prov, declResp: declResp })

    })
    firestore.collection("proveedores").doc(this.props.params.nifProveedor).get().then(snapshot => {
      let proveedor = snapshot.data();
      let fincas = [];
      /* let promises =[]; */
      firestore.collection("fincas").where("nifProveedores", "array-contains", this.props.params.nifProveedor).where("nifAAFF", "==", this.props.params.nifAAFF).get().then(res => {
        res.docs.forEach(doc => {
          let finca = doc.data()
          finca.servicios = ""
          finca.proveedores.forEach(provserv => {
            if (provserv.nif === this.props.params.nifProveedor) {
              if (finca.servicios === "") {
                finca.servicios += serviciosProveedores[provserv.servicio]
              } else {
                finca.servicios += ", " + serviciosProveedores[provserv.servicio]
              }
            }
          });

          fincas.push(finca)
        })

        
        this.setState({ fincas: fincas, proveedor: proveedor }, () => console.log(this.state.fincas))
        
      })
    }).catch(error => {
      //window.location.replace("/")
    })
  }

  validarDocumentacion = () => {
    firestore.doc(`proveedores/${this.state.proveedor.nif}`).set({
      documentos: this.state.dialog.docName === "declaracionResponsable" ? {
        declaracionResponsable: {
          [this.props.params.nifAAFF]: {
            validado: true,
            pendiente: false
          }
        }
      } : {
        [this.state.dialog.docName]: {
          validado: true,
          pendiente: false
        }
      }
    }, { merge: true })
    this.setState({ dialog: { opened: false, title: "", docName: "", proveedor: undefined } })
    this.loadPageContent()
  }
  noValidarDocumentacion = () => {
    firestore.doc(`proveedores/${this.state.proveedor.nif}`).set({
      documentos: this.state.dialog.docName === "declaracionResponsable" ? {
        declaracionResponsable: {
          [this.props.params.nifAAFF]: {
            ruta: "",
            validado: false,
            pendiente: false
          }
        }
      } : {
        [this.state.dialog.docName]: {
          ruta: "",
          validado: false,
          pendiente: false
        }
      }
    }, { merge: true })
    this.setState({ dialog: { opened: false, title: "", docName: "", proveedor: undefined } })
    this.loadPageContent()
  }

  render() {
    if (!this.state.proveedor || this.state.fincas === []) return null //Loading data...
    return <>
      <div className="pageHeader"  >Administrador / {this.state.aaff.razonSocial} / Proveedores / <b>{this.state.proveedor.razonSocial}</b></div>
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            INFORMACIÓN GENERAL
            <Link to={`/aaff/${this.state.aaff.nif}/proveedores`}><div className="gridHeadRight">{"<< volver al listado de Proveedores"}</div></Link>
          </div>
          <div className="gridSubhead">DATOS EMPRESA</div>
          <div className="datosGrid">
            <p><b>Nombre proveedor:</b> {this.state.proveedor.razonSocial}</p>
            <p><b>Dirección:</b> {this.state.proveedor.direccion}</p>
            <p><b>NIF:</b> {this.state.proveedor.nif}</p>
            <p><b>Municipio:</b> {this.state.proveedor.municipio}</p>
            <p><b>Persona de contacto:</b> {this.state.proveedor.contacto.nombre}</p>
            <p><b>CP:</b> {this.state.proveedor.codigoPostal}</p>
            <p><b>Email:</b> {this.state.proveedor.contacto.email}</p>
            <p><b>Provincia:</b> {this.state.proveedor.provincia}</p>
            <p><b>Telefono:</b> {this.state.proveedor.contacto.telefono}</p>
            <p><b>Fecha de alta:</b> {new Date(this.state.proveedor.fechaAlta.seconds * 1000).toLocaleDateString()}</p>
          </div>
          <div className="gridSubhead">DOCUMENTOS EMPRESA</div>
          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Certificado LOPD</th>
                  <th style={{ textAlign: "center" }}>Seguro Resp. Civil</th>
                  <th style={{ textAlign: "center" }}>Justificante pago SPA</th>
                  <th style={{ textAlign: "center" }}>Planificación preventiva</th>
                  <th style={{ textAlign: "center" }}>Identificación riesgos</th>
                  <th style={{ textAlign: "center" }}>Certificado SS</th>
                  <th style={{ textAlign: "center" }}>Vigilancia salud</th>
                  <th style={{ textAlign: "center" }}>Declaración responsable</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.lopd?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                    this.state.proveedor.documentos.lopd?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                      abrirArchivo(this.state.proveedor.documentos.lopd?.ruta)
                    }}/> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                    <small style={{display:'block'}}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.seguro?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                    this.state.proveedor.documentos.seguro?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                      abrirArchivo(this.state.proveedor.documentos.seguro?.ruta)
                    }}/> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                    <small style={{display:'block'}}>{this.state.proveedor.documentos.seguro?.fechaCaducidad !== undefined ? new Date(this.state.proveedor.documentos.seguro?.fechaCaducidad?.seconds* 1000).toLocaleDateString() : "-"}</small>
                  </td>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.justificante?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                    this.state.proveedor.documentos.justificante?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                      abrirArchivo(this.state.proveedor.documentos.justificante?.ruta)
                    }}/> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                    <small style={{display:'block'}}>{this.state.proveedor.documentos.justificante?.fechaCaducidad !== undefined ? new Date(this.state.proveedor.documentos.justificante?.fechaCaducidad?.seconds* 1000).toLocaleDateString() : "-"}</small>
                  </td>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.planificacionPreventiva?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                    this.state.proveedor.documentos.planificacionPreventiva?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                      abrirArchivo(this.state.proveedor.documentos.planificacionPreventiva?.ruta)
                    }}/> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                    <small style={{display:'block'}}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.evaluacionRiesgos?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                    this.state.proveedor.documentos.evaluacionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                      abrirArchivo(this.state.proveedor.documentos.evaluacionRiesgos?.ruta)
                    }}/> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                    <small style={{display:'block'}}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.certificadoSS?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                    this.state.proveedor.documentos.certificadoSS?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                      abrirArchivo(this.state.proveedor.documentos.certificadoSS?.ruta)
                    }}/> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                    <small style={{display:'block'}}>{this.state.proveedor.documentos.certificadoSS?.fechaCaducidad !== undefined ? new Date(this.state.proveedor.documentos.certificadoSS?.fechaCaducidad?.seconds* 1000).toLocaleDateString() : "-"}</small>
                  </td>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.vigilancia?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                    this.state.proveedor.documentos.vigilancia?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                      abrirArchivo(this.state.proveedor.documentos.vigilancia?.ruta)
                    }}/> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                    <small style={{display:'block'}}>{this.state.proveedor.documentos.vigilancia?.fechaCaducidad !== undefined ? new Date(this.state.proveedor.documentos.vigilancia?.fechaCaducidad?.seconds* 1000).toLocaleDateString() : "-"}</small>
                  </td>
                  <td style={{ textAlign: "center" }}>{this.state.proveedor.documentos.declaracionResponsable?.pendiente ? <img src={Minus} style={{cursor: 'unset'}} alt="Pendiente" className="tableIcon" /> :
                    this.state.proveedor.documentos.declaracionResponsable?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                      abrirArchivo(this.state.proveedor.documentos.declaracionResponsable?.ruta)
                    }}/> : <img src={Cross} style={{cursor: 'unset'}} alt="No validado" className="tableIcon" />}
                    <small style={{display:'block'}}>-</small>
                  </td>
                </tr>
              </tbody>
            </table>
            <input type="file" id="fileElem" hidden></input>
          </div>
          <div className="detPageGrid">
            <div className="gridCard">
              <div className="gridSubhead">COMUNIDADES</div>
              <table style={{ margin: 15 }}>
                <thead>
                  <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.fincas.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td style={{padding: "10px 0"}}>{e.razonSocial} <br/>
                         <small>{e.servicios}</small></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={this.state.dialog.opened}
        onClose={() => this.setState({ dialog: { opened: false, title: "", docName: "" } })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.state.dialog.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            El archivo se está descargando...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.noValidarDocumentacion()} color="inherit" style={{ color: "#FF5252" }}>
            No Validar
          </Button>
          <Button onClick={() => this.validarDocumentacion()} color="inherit" style={{ color: "#7bc977" }} autoFocus>
            <b>Validar</b>
          </Button>
        </DialogActions>
      </Dialog>
      {/* VER DOCUMENTACIÓN */}
      <Dialog
        open={this.state.verDocumentacion.opened}
        onClose={() => this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined } })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Qué quieres hacer con el documento?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Subir un nuevo documento implica sobreescribir el anterior
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            firestore.doc(`proveedores/${this.state.proveedor.nif}`).set({
              documentos: this.state.verDocumentacion.nombreDoc === "declaracionResponsable" ? {
                declaracionResponsable: {
                  [this.props.params.nifAAFF]: {
                    ruta: "",
                    validado: false,
                    pendiente: false
                  }
                }
              } : {
                [this.state.verDocumentacion.nombreDoc]: {
                  ruta: "",
                  validado: false,
                  pendiente: false
                }
              }
            }, { merge: true }).then(() => {
              toast.success("Documento borrado con éxito")
              this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined } })
              this.loadPageContent()
            })
          }} color="inherit" style={{ color: "#848474" }}>
            Borrar documento
          </Button>
          <Button onClick={() => {
            const nombreDoc = this.state.verDocumentacion.nombreDoc
            if (["seguro", "justificante", "vigilancia", "certificadoSS"].includes(nombreDoc)) {
              this.setState({ subirDocumentacion: { opened: true, nombreDoc: nombreDoc, proveedor: this.state.proveedor } })
            } else {
              if (nombreDoc === "declaracionResponsable") {
                subirDeclaracionResponsable(this.props.params.nifAAFF, this.state.proveedor, this.loadPageContent)
              } else {
                subirDocumentacionProveedor(this.state.verDocumentacion.nombreDoc, this.state.proveedor, this.loadPageContent)
              }
            }
            this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined } })
          }} color="inherit" style={{ color: "#848474" }}>
            Subir nuevo
          </Button>
          <Button onClick={() => {
            abrirArchivo(this.state.verDocumentacion.nombreDoc === "declaracionResponsable" ? this.state.proveedor.documentos.declaracionResponsable[this.props.params.nifAAFF].ruta : this.state.proveedor.documentos[this.state.verDocumentacion.nombreDoc].ruta)
            this.setState({ verDocumentacion: { opened: false, nombreDoc: undefined } })
          }} color="inherit" style={{ color: "#848474" }} autoFocus>
            <b>VER</b>
          </Button>
        </DialogActions>
      </Dialog>
      {/* SUBIR DOCUMENTACIÓN */}
      <Dialog
        open={this.state.subirDocumentacion.opened}
        onClose={() => this.setState({ subirDocumentacion: { opened: false, nombreDoc: undefined, fecha: undefined } })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Subir documentación</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Indica la fecha de caducidad del documento
            <div>
              <input type="date" required value={this.state.subirDocumentacion.fecha} onChange={event => this.setState({ subirDocumentacion: { ...this.state.subirDocumentacion, fecha: event.target.value } })} />
            </div>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            this.setState({ subirDocumentacion: { opened: false, nombreDoc: undefined, fecha: undefined } })
          }} color="inherit" style={{ color: "#848474" }} autoFocus>
            Cancelar
          </Button>
          <Button onClick={() => {
            if (this.state.subirDocumentacion.fecha !== undefined) {
              subirDocumentacionProveedor(this.state.subirDocumentacion.nombreDoc, this.state.proveedor, this.loadPageContent, this.state.subirDocumentacion.fecha)
              this.setState({ subirDocumentacion: { opened: false, nombreDoc: undefined, fecha: undefined } })
            } else {
              toast.error("Introduce la fecha de caducidad")
            }
          }} color="inherit" style={{ color: "#848474" }} autoFocus>
            <b>Guardar y elegir archivo</b>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  }
}

export default withRouter(Proveedor);
