import React, { useState, useEffect } from "react";
import { firestore, storage } from "../../../providers/firebase";
import { PDFViewer, Document, View, Page, Text, Image, StyleSheet } from "@react-pdf/renderer"
import LogoNF from "../../../images/Logo-B.png"
import Cabecera from "../../../images/cabecera.png"
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "../../../providers/withRouter";
import { incidencias } from "../../../providers/incidencias";

const styles = StyleSheet.create({
    body: {
        // paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: '0cm',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0 
    }, 
    tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableColHeader: { 
        width: "20%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#eaecee',
        color: '#000000',
        fontWeight:'bold',
        textAlign:'left'
    }, 
    tableCol: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableCell: { 
        //margin: "auto", 
        marginLeft:5,
        marginTop: 3, 
        marginBottom: 3, 
        fontSize: 7,
        fontWeight:'bold',
        textAlign:'left'
    },
    tableColHeader1: { 
        width: "100%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#eaecee',
        color: '#000000',
        fontWeight:'bold',
        textAlign:'left'
    }, 
    tableCol1: { 
        width: "100%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableCell1: { 
        marginLeft:5,
        marginTop: 3, 
        marginBottom: 3, 
        minHeight: 60,
        fontSize: 8,
        textAlign:'left'
    },
    tableCell2: { 
        marginLeft:5,
        marginTop: 3, 
        marginBottom: 3, 
        minHeight: 120,
        fontSize: 8,
        textAlign:'left'
    },
    pdfHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        backgroundColor: '#ffffff',
        /* margin:'2cm', */
    },
    pdfHeaderImg:{
        width: '100%',
        height: 100,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundImage: `url(${Cabecera})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: "fixed",
        top: 0,
        left: 0
    },
    /* tablePage: {
        width: '100%',
    }, */
    /* headerSpace: {
        height: '90px',
    }, */
    div: {
        fontSize: '10px'
    },
    h1: {
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: '600',
    },
     h2: {
        fontSize: '11px',
        fontWeight: '600',
        marginTop: '10px',
        marginBottom: '10px',
    },
    h3: {
        fontSize: '9px',
        width: '100%',
        padding: '5px 10px',
        backgroundColor: '#f8f8f8',
        textTransform: 'uppercase',
        border: '1px solid #e6e6e6',
        marginTop: '25px',
        marginBottom: '15px',
        fontWeight:'bold',
    },
    h4: {
        color: '#ab2a3e',
        marginBottom: '10px',
        marginTop: '20px',
        fontSize: '9px',
        fontWeight:'bold',
    },
    h5: {
        fontSize: '10px',
        marginTop: '15px',
        marginBottom: '5px',
    },
    /* textBox: {
        border: '2px solid #e6e6e6',
        padding: '15px',
        marginTop: '15px',
    }, */
    tableRiesgos: {
        borderCollapse: 'collapse',
        width: '100%',
        tableLayout: 'fixed',
        marginBottom: '24px',
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    simpleTableTh: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    },
    /* simpleTableTd: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    }, */
    /* tableRiesgosTd: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    }, */
    /* tableRiesgosTh: {
        backgroundColor: '#375491',
        color: '#ffffff',
        fontSize: '12px',
        fontWeight: 'bold',
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    }, */
    simpleTable: {
        borderCollapse: 'collapse',
        width: '80%',
        margin: '0 10% 24px 10%',
    },
    simpleTableTh: {
        textTransform: 'uppercase',
    },
    subul: {
        lineHeight: '1.8',
        fontSize:'8px',
        flexDirection: "column", 
        width: 400,
        marginLeft: '8px',
    },
    ul: {
        lineHeight: '1.8',
        fontSize:'7px',
        flexDirection: "column", 
        // width: 200,
    },
    ol: {
        lineHeight: '1.8',
        flexDirection: "row", 
        marginBottom: 4,
        fontSize: '7px',
    },
    p: {
        lineHeight: '1.8',
        textAlign: 'justify',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
        fontSize: '7px',
    },
    subp: {
        lineHeight: '1.8',
        textAlign: 'justify',
        marginLeft: '8px',
        fontSize: '8px',
    },
    br: {
        display: 'block',
        margin: '10px 0 2px 0',
        lineHeight: '5px',
        content: " ",
    },
    pageTitle: {
        textAlign: 'center',
        textDecoration: 'underline',
        fontWeight: 'bold',
        fontSize: '12px',
        marginBottom: '20px'
    },
    page: {
        size: 'A4 portrait',
        margin: '2cm',
    },
    pagebreak: {
        clear: 'both',
        pageBreakAfter: 'always',
    },
    bold: { fontWeight: 'bold' }
  });

const HojaVisitaRender = (props) => {
    //proveedores: [{servicio: 1, nombre:"b", telefono:"123456789"}, {servicio: 2, nombre:"c", telefono:"444555666"}],
    const imagenes = [
        {id: "1", fecha: '10 Marzo 2023', img: 'https://3.bp.blogspot.com/-kRvaP5I4GXA/XJ9U4U7WCOI/AAAAAAAADXY/6ntBe3dxGlYLLZq9OxDGf4urEoejuBnvQCLcBGAs/w400/199.jpeg', titulo: 'El lugar perfecto 1', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "2", fecha: '11 Marzo 2023', img: 'https://images.pexels.com/photos/7319307/pexels-photo-7319307.jpeg', titulo: 'El lugar perfecto 2', categoria: "Categoria 2", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "3", fecha: '13 Marzo 2023', img: 'https://1.bp.blogspot.com/-nMSa4Nn7Ptc/XYnmptL7xaI/AAAAAAAAD6Y/4qZ7nfvQIbIx7UFSPYEP0l95WHEVjM6fwCNcBGAsYHQ/w400/168.jpg', titulo: 'El lugar perfecto 3', categoria: "Categoria 3", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "4", fecha: '14 Marzo 2023', img: 'https://4.bp.blogspot.com/-R-W4st0RZSI/WO-0p3MJOjI/AAAAAAAAAZw/78ifeoiqCWgk3g5rn06FzJMtDwTON6pcQCLcB/s1600/img-1-380x475.jpg', titulo: 'El lugar perfecto 4', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "5", fecha: '15 Marzo 2023', img: 'https://4.bp.blogspot.com/-_VUr4RH5TeA/WO-0zvhepeI/AAAAAAAAAaE/022bDzNgjxw7qRlhU23KCi2Mmte-moKwQCLcB/s1600/14-380x253.jpg', titulo: 'El lugar perfecto 5', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "6", fecha: '16 Marzo 2023', img: 'https://1.bp.blogspot.com/-9XnOoVlReAw/WO-1PTTNOzI/AAAAAAAAAak/QiZUHUYUE58mTl9B1tQMGVaigVr1FLF5gCLcB/s1600/6-380x380.jpg', titulo: 'El lugar perfecto 6', categoria: "Categoria 4", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "7", fecha: '17 Marzo 2023', img: 'https://3.bp.blogspot.com/-UZ6aTxCipsA/WO-0zNSI2eI/AAAAAAAAAZ4/kJyIJIwYjbACRAkCk8gMSBPBOwOjLmbDACPcB/s1600/11-380x253.jpg', titulo: 'El lugar perfecto 7', categoria: "Categoria 2", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "8", fecha: '18 Marzo 2023', img: 'https://2.bp.blogspot.com/-lO-_sTpNVXk/WO-004bHfdI/AAAAAAAAAaU/SK4XAHnHny0hH79ClImZNgZGprjRxfulACPcB/s1600/8-380x475.jpg', titulo: 'El lugar perfecto 8', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "9", fecha: '18 Marzo 2023', img: 'https://1.bp.blogspot.com/-vIKm5p_3OeA/WO-0zmwqV-I/AAAAAAAAAaA/f_IDerUTiL4mu2RVv68TwfhlGvT8bbgyACPcB/s1600/13-380x253.jpg', titulo: 'El lugar perfecto 9', categoria: "Categoria 3", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "10", fecha: '19 Marzo 2023', img:'https://1.bp.blogspot.com/-nxE5vIdt4Us/XTakuNcqhII/AAAAAAAADyY/EgJ7hpOLs10_h2k9yRNsFET1hKzG1nhZwCLcBGAs/w400/img-334.jpg', titulo: 'El lugar perfecto 10', categoria: "Categoria 3", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "11", fecha: '20 Marzo 2023', img:'https://2.bp.blogspot.com/-2lfoUvrFDk0/XJ9TgiebqyI/AAAAAAAADWw/7onsCw6RC0A2X1DwtoCiQAkNk26Q776aQCLcBGAs/w400/206.jpeg', titulo: 'El lugar perfecto 11', categoria: "Categoria 4", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "12", fecha: '21 Marzo 2023', img:'https://1.bp.blogspot.com/-xb8TF4SzdE8/XdUqK3UQENI/AAAAAAAAEE4/dbB6pmxuC08nzF67hizdg2eposOFteNlwCNcBGAsYHQ/w400/6.jpg', titulo: 'El lugar perfecto 12', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "13", fecha: '22 Marzo 2023', img:'https://1.bp.blogspot.com/-rCCw3TmOfbo/XR8gyfmdlkI/AAAAAAAADvE/rhnPdUQC8wwxAj4HmZnnE6HmCQ86hJbFwCLcBGAs/w400/img-325.jpg', titulo: 'El lugar perfecto 13', categoria: "Categoria 2", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "14", fecha: '23 Marzo 2023', img:'https://1.bp.blogspot.com/-IdIUpQDP35I/XJ9VJFXG7MI/AAAAAAAADXg/8rboDT-FDxAyg2CygF120kh5_-lxVuh6gCLcBGAs/w400/202.jpeg', titulo: 'El lugar perfecto 14', categoria: "Categoria 3", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "15", fecha: '23 Marzo 2023', img:'https://1.bp.blogspot.com/-OlCKjMigKQ0/XYdfUZqv-KI/AAAAAAAAD6A/anwZ57Ppsac_qUi1M7d0DB4uVxTgRCgPgCNcBGAsYHQ/w400/150.jpg', titulo: 'El lugar perfecto 15', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
        {id: "16", fecha: '24 Marzo 2023', img:'https://1.bp.blogspot.com/-rCCw3TmOfbo/XR8gyfmdlkI/AAAAAAAADvE/rhnPdUQC8wwxAj4HmZnnE6HmCQ86hJbFwCLcBGAs/w400/img-325.jpg', titulo: 'El lugar perfecto 16', categoria: "Categoria 1", titular: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel vero dolores nobis nesciunt, ab minus pariatur asperiores nulla dicta ut expedita exercitationem quam dolorum, ipsam repellendus placeat! Placeat, soluta voluptatem.", texto: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos consequuntur ex cumque voluptatibus dignissimos temporibus illum. Maiores consectetur possimus magni aspernatur animi aperiam sint, laboriosam amet omnis at, odio quod!'},
    ]
    const [isLoading, setIsLoading] = useState(true)
    const [hojasVisita, setHojasVisita] = useState([])
    const [centrosTrabajo, setCentrosTrabajo] = useState({})
    const [finca, setFincas] = useState({})
    const [imagenes1, setImagenes] = useState('')
    const [inc, setIncidencias] = useState([])

    useEffect(() => {
        firestore.collection("hojasVisita").doc(props.params.nifFinca).get().then(doc => {
            const hojaVisita = doc.data()
            let imgs = []
            let centroTrabajo = undefined
            doc.data().centrosTrabajo.forEach(ct => {
                if(ct.nif === props.params.nifCentroTrabajo){
                    centroTrabajo = ct
                    if(centroTrabajo.imagenes !== undefined){
                        centroTrabajo.imagenes.forEach(i => {
                            storage.ref(i).getDownloadURL().then(img => {
                                imgs.push(img)
                            })
                        })
                        setTimeout(()=>{
                            console.log(imgs)
                            setImagenes(imgs)
                        }, 2000)
                    }
                }
            })
            let inc = []
            if (centroTrabajo?.p1_1 === "s") inc.push(incidencias.p1_1)
            if (centroTrabajo?.p1_2 === "s") inc.push(incidencias.p1_2)
            if (centroTrabajo?.p1_3 === "s") inc.push(incidencias.p1_3)
            if (centroTrabajo?.p1_4 === "s") inc.push(incidencias.p1_4)
            if (centroTrabajo?.p1_5 === "s") inc.push(incidencias.p1_5)
            if (centroTrabajo?.p1_6 === "s") inc.push(incidencias.p1_6)
            if (centroTrabajo?.p1_7 === "s") inc.push(incidencias.p1_7)
            if (centroTrabajo?.p1_8 === "n") inc.push(incidencias.p1_8)
            if (centroTrabajo?.p2_0 === "s") {
                if (centroTrabajo?.p2_1 === "n") inc.push(incidencias.p2_1)
                if (centroTrabajo?.p2_2 === "n") inc.push(incidencias.p2_2)
                if (centroTrabajo?.p2_3 === "n") inc.push(incidencias.p2_3)
                if (centroTrabajo?.p2_4 === "n") inc.push(incidencias.p2_4)
                if (centroTrabajo?.p2_5 === "n") inc.push(incidencias.p2_5)
                if (centroTrabajo?.p2_5 === "n") {
                    if (centroTrabajo?.p2_6 === "n") inc.push(incidencias.p2_6)
                    if (centroTrabajo?.p2_7 === "n") inc.push(incidencias.p2_7)
                    if (centroTrabajo?.p2_8 === "n" && hojaVisita?.p2_9 === "n") inc.push(incidencias.p2_8)
                }
                if (centroTrabajo?.p2_9 === "n" && centroTrabajo?.p2_8 === "n") inc.push(incidencias.p2_9)
                if (centroTrabajo?.p2_10 === "n") inc.push(incidencias.p2_10)
            }
            if (centroTrabajo?.p3_0 === "s") {
                if (centroTrabajo?.p3_1 === "n") inc.push(incidencias.p3_1)
                if (centroTrabajo?.p3_2 === "n") inc.push(incidencias.p3_2)
                if (centroTrabajo?.p3_3 === "n") inc.push(incidencias.p3_3)
                if (centroTrabajo?.p3_4 === "n") inc.push(incidencias.p3_4)
            }
            if (centroTrabajo?.p4_0 === "s") {
                if (centroTrabajo?.p4_1 === "n") inc.push(incidencias.p4_1)
                if (centroTrabajo?.p4_2 === "n") inc.push(incidencias.p4_2)
                if (centroTrabajo?.p4_3 === "n") inc.push(incidencias.p4_3)
                if (centroTrabajo?.p4_4 === "n") inc.push(incidencias.p4_4)
                if (centroTrabajo?.p4_5 === "n") inc.push(incidencias.p4_5)
                if (centroTrabajo?.p4_6 === "n") inc.push(incidencias.p4_6)
                if (centroTrabajo?.p4_7 === "n") inc.push(incidencias.p4_7)
                if (centroTrabajo?.p4_8 === "n") inc.push(incidencias.p4_8)
                if (centroTrabajo?.p4_9 === "n") inc.push(incidencias.p4_9)
                if (centroTrabajo?.p4_10 === "n") inc.push(incidencias.p4_10)
                if (centroTrabajo?.p4_11 === "n") inc.push(incidencias.p4_11)
                if (centroTrabajo?.p4_12 === "n") inc.push(incidencias.p4_12)
                if (centroTrabajo?.p4_13 === "n") inc.push(incidencias.p4_13)
                if (centroTrabajo?.p4_14 === "n") inc.push(incidencias.p4_14)
                if (centroTrabajo?.p4_15 === "n") inc.push(incidencias.p4_15)
            }
            if (centroTrabajo?.p5_0 === "s") {
                if (centroTrabajo?.p5_2 === "n") inc.push(incidencias.p5_2)
                if (centroTrabajo?.p5_3 === "n") inc.push(incidencias.p5_3)
            }
            if (centroTrabajo?.p6_0 === "s") {
                if (centroTrabajo?.p6_2 === "n") inc.push(incidencias.p6_2)
                if (centroTrabajo?.p6_3 === "n") inc.push(incidencias.p6_3)
            }
            if (centroTrabajo?.p10_0 === "s") {
                if (centroTrabajo?.p10_1 === "n") inc.push(incidencias.p10_1)
                // if (centroTrabajo?.p10_2 === "n") inc.push(incidencias.p10_2)
                if (centroTrabajo?.p10_3 === "n") inc.push(incidencias.p10_3)
                if (centroTrabajo?.p10_4 === "n") inc.push(incidencias.p10_4)
                if (centroTrabajo?.p10_5 === "n") inc.push(incidencias.p10_5)
                if (centroTrabajo?.p10_6 === "n") inc.push(incidencias.p10_6)
            }
            if (centroTrabajo?.p10_0 === "n") inc.push(incidencias.p10_0)
            if (centroTrabajo?.p11_0 === "s") {
                if (centroTrabajo?.p11_1 === "n") inc.push(incidencias.p11_1)
                if (centroTrabajo?.p11_2 === "n") inc.push(incidencias.p11_2)
            }
            if (centroTrabajo?.p11_0 === "n") inc.push(incidencias.p11_0)
            if (centroTrabajo?.p12_0 === "s") {
                if (centroTrabajo?.p12_1 === "n") inc.push(incidencias.p12_1)
                if (centroTrabajo?.p12_2 === "n") inc.push(incidencias.p12_2)
            }
            if (centroTrabajo?.p12_0 === "n") inc.push(incidencias.p12_0)
            
            let fechaVisita = new Date(centroTrabajo?.fechaVisita?.seconds * 1000)
            let fechaVisitaRenovacion = centroTrabajo?.fechaVisitaRenovacion === undefined ? new Date (0) : new Date(centroTrabajo?.fechaVisitaRenovacion?.seconds * 1000)
            hojaVisita['diffFechas'] = hojaVisita?.fechaVisitaRenovacion?.seconds < hojaVisita?.fechaVisita?.seconds
            hojaVisita['fechaVisita'] = ("0" + fechaVisita.getDate()).slice(-2) + "-" + ("0" + (fechaVisita.getMonth() + 1)).slice(-2) + "-" + fechaVisita.getFullYear()
            hojaVisita['fechaVisitaRenovacion'] = ("0" + fechaVisitaRenovacion.getDate()).slice(-2) + "-" + ("0" + (fechaVisitaRenovacion.getMonth() + 1)).slice(-2) + "-" + fechaVisitaRenovacion.getFullYear()
            setTimeout(()=>{
                setHojasVisita(hojaVisita)
                setCentrosTrabajo(centroTrabajo)
                setIncidencias(inc)
                console.log(hojaVisita)
                console.log(inc)
                console.log(imgs)
                if(hojasVisita?.fechaVisitaRenovacion === undefined || hojasVisita?.diffFechas === true || fechaVisitaRenovacion.getFullYear()=== 1970){
                    hojaVisita['fechaMostrar'] = ("0" + fechaVisita.getDate()).slice(-2) + "-" + ("0" + (fechaVisita.getMonth() + 1)).slice(-2) + "-" + fechaVisita.getFullYear()
                    // console.log(fechaVisita.toLocaleDateString())
                }else{
                    hojaVisita['fechaMostrar'] = ("0" + fechaVisitaRenovacion.getDate()).slice(-2) + "-" + ("0" + (fechaVisitaRenovacion.getMonth() + 1)).slice(-2) + "-" + fechaVisitaRenovacion.getFullYear()
                    // console.log(fechaVisitaRenovacion.toLocaleDateString())
                }
                setIsLoading(false)
            },2000)
        })/* .catch(() => window.location.replace('/')) */
    },[])

    return (
        <>
            {/* {console.log(imagenes1)}
            <div style={{ display:'flex', justifyContent:'center', float:'left'  }}>
                {imagenes1.map( i => {
                    return (
                        <img style={{ width:100, flexDirection: "row", float:'left' }} src={i} alt="a"  /> 
                    )
                })}
            </div> */}
            {console.log(imagenes1)}
            {isLoading ? <CircularProgress style={{color:'#848474', position: 'absolute', top: '50%', left: '50%'}} /> :
                <PDFViewer style={{ width:"100%", height:"100vh"}}>
                    <Document size="A4" margin="2cm">
                        <Page style={styles.body}>
                            <View style={{marginTop: 0, paddingBottom: 10, marginHorizontal: '0cm'}} fixed>
                                <View style={{ display: 'flex', color: '#ffffff', alignItems: 'center' }}>
                                    <Image style={styles.pdfHeaderImg} src={Cabecera} alt="Cabecera" />
                                    <Image style={{ height: 50, position:"absolute", left: 25, top: 25, filter: "brightness(0) invert(1)"}} src={LogoNF} alt="Logo NF" />
                                    <View style={{ height: 50, left:100, top:25, marginLeft: 0, paddingLeft: 10, borderLeft: '2px solid #ffffff', color: '#ffffff', fontSize: 15, position: 'absolute' }}>
                                        <Text style={{ marginTop: 10, lineHeight: 1.2, fontWeight: 'bold' }}>HOJA DE VISITA</Text>
                                        <Text style={{ margin: 0, lineHeight: 1.2 }}>Identificación de riesgos</Text>
                                    </View>
                                </View> 
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <View>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 0, marginBottom: 5, fontSize: 10, padding: 2 }}>HOJA DE SEGUIMIENTO</Text>
                                    <View style={styles.table}> 
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text fontWeight="bold">Comunidad de propietarios:</Text></Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita.comunidad?.razonSocial}</Text> 
                                            </View> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text fontWeight="bold">Actividad:</Text></Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>COMUNIDAD DE PROPIETARIOS</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text fontWeight="bold">Centro de trabajo:</Text> </Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{centrosTrabajo?.nombre}</Text> 
                                            </View> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text fontWeight="bold">Localidad:</Text> </Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.comunidad.localidad}</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}>Técnico:</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.tecnico.nombre}</Text> 
                                            </View> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}>Código Postal:</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.comunidad.codigoPostal}</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}>Fecha de visita:</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{ hojasVisita?.fechaMostrar}</Text> 
                                                {/* <Text style={styles.tableCell}>{hojasVisita?.fechaVisitaRenovacion === undefined || new Date(hojasVisita?.fechaVisitaRenovacion).getFullYear() === 1970 || (hojasVisita?.fechaVisitaRenovacion < hojasVisita?.fechaVisita) && (Date.parse(hojasVisita?.fechaVisitaRenovacion) < Date.parse(hojasVisita?.fechaVisita)) ? hojasVisita?.fechaVisita : hojasVisita?.fechaVisitaRenovacion}</Text>  */}
                                            </View> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}>Administrador de Fincas:</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.aaff.razonSocial}</Text> 
                                            </View> 
                                        </View>
                                    </View>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 10, marginBottom: 5, fontSize: 10, padding: 2 }}>ACTUACIONES</Text>
                                    <View style={styles.table}> 
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol1}> 
                                                <Text style={styles.tableCell1}><Text fontWeight="bold">Visita al centro de trabajo. Se realiza toma de datos para la identificación de riesgos.</Text></Text> 
                                            </View> 
                                        </View>
                                    </View>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 10, marginBottom: 5, fontSize: 10, padding: 2 }}>IDENTIFICACIÓN DE RIESGO</Text>
                                    <View style={styles.table}> 
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader1}> 
                                                <Text style={styles.tableCell}>Una vez realizada la visita en la comunidad, se observa:</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol1}> 
                                                <View style={styles.tableCell2}>
                                                    {inc.map((e, i) => 
                                                        <View style={styles.ol}>
                                                            <Text key={i} style={{ marginHorizontal: 4, marginRight: 5 }}>•</Text>
                                                            <Text key={i} style={{ width: '100%', marginRight: 5}}>{e}</Text>
                                                        </View>
                                                    )}
                                                </View> 
                                            </View> 
                                        </View>
                                    </View>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 10, marginBottom: 5, fontSize: 10, padding: 2 }}>OBSERVACIONES</Text>
                                    <View style={styles.table}> 
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol1}> 
                                                <Text style={styles.tableCell2}><Text style={{ whiteSpace: "pre-line" }}>{centrosTrabajo?.comentarios}</Text></Text> 
                                            </View> 
                                        </View>
                                    </View>
                                </View>
                                {/* {imagenes?.length > 0 && imagenes !== undefined ? 
                                <>
                                    <View break>
                                        <View style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>IMAGENES</View>
                                        <View style={{padding: 10, textAlign: "center"}}>
                                                {imagenes.map(url => {
                                                    return <Image src={url.img} alt={url.titulo} style={{width: '100%'}} />
                                                }) }
                                        </View>
                                    </View>
                                    </>
                                : null } */}
                            </View>
                            {/* <View style={{ marginHorizontal: '1cm' }} break>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 10, marginBottom: 5, fontSize: 10, padding: 2 }}>IMAGENES</Text>  
                                    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                            <View style={{ width: '25%', padding: 5 }}>
                                                <Image style={{ width: '100%' }} src={imagenes1} alt="a" />
                                            </View>
                                    </View> 
                            </View> */}
                            {imagenes1?.length > 0 ? 
                                <View style={{ marginHorizontal: '1cm' }} break>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 10, marginBottom: 5, fontSize: 10, padding: 2 }}>IMAGENES</Text>  
                                    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {imagenes1.map((img, i) => (
                                            <View key={i} style={{ width: '25%', padding: 5 }}>
                                                <Image style={{ width: '100%' }} src={img} alt="a" />
                                            </View>
                                        ))}
                                    </View> 
                                </View>
                            : null}
                            <View style={{ marginHorizontal: '1cm' }}>
                                <View style={{ display: 'flex', justifyContent: 'left', marginTop: 10, marginBottom: 0 }}>
                                    <View style={{ marginRight: 75, fontSize:8 }}>
                                        <Text style={styles.bold}>TÉCNICO DE SEGURIDAD</Text>
                                        <Text style={{ marginTop: 20 }}>Firma: {hojasVisita?.tecnico.nombre}</Text>
                                        <Text style={{ marginTop: 10, fontSize: 0.8 }}>Técnico en PRL</Text>
                                    </View>
                                    <div style={{ marginLeft: 75 }}>
                                    </div>
                                </View>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            }
        </>
        
    );
}
export default withRouter(HojaVisitaRender);