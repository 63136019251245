import React from "react";
import { Link } from "react-router-dom";
import { firestore, auth } from "../../../providers/firebase";
import HeaderBackground from "../../../images/banner_proveedores.jpg"
import { LinearProgress, withStyles, Box, CircularProgress } from "@material-ui/core";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: props => ({
    borderRadius: 5,
    backgroundColor: props.value < 25 ? '#FF5252' : (props.value < 75 ? "#fcba03" : "#7bc977"),
  })
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        {`${Math.round(
          props.value * 100
        ) / 100}%`}
      </Box>
    </Box>
  );
}


class Proveedores extends React.Component {
  constructor() {
    super()
    this.state = {
      proveedores: [],
      isLoading: true
    }
  }
  componentDidMount() {
    firestore.collection("fincas").where("nifAAFF", "==", auth.currentUser.uid).get().then(snapshot => {
      let nifProveedores = []
      snapshot.docs.forEach(e => nifProveedores = nifProveedores.concat(e.data().nifProveedores))
      nifProveedores = [...new Set(nifProveedores)]
      let promises = []
      let proveedores = []
      nifProveedores.forEach(e => {
        promises.push(firestore.collection("proveedores").doc(e).get().then(prov => prov.exists ? proveedores.push(prov.data()) : null))
      })
      Promise.all(promises).then(() => {
        this.setState({ proveedores: proveedores, isLoading: false })
      })

    })
  }

  render() {
    return (
      <>
        <div className="pageHeader"  ><span>Proveedores</span></div>

        <div className="pageContent tableContainer">
          <div className="tableWrapper">
            <table>
              <thead>
                <tr>
                  <th>Proveedor</th>
                  <th>Municipio</th>
                  <th style={{ textAlign: "center" }}>Cumplimiento</th>
                </tr>
              </thead>
              <tbody>
                {this.state.isLoading ? <tr><td colSpan='3' style={{textAlign:'center'}}><CircularProgress style={{color:'#848474'}} /> </td></tr> : 
                this.state.proveedores.length === 0 ? <tr><td colSpan='3' style={{textAlign:'center'}}>No hay proveedores</td></tr> :
                this.state.proveedores.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td><Link to={"/proveedores/" + e.nif}>{e.razonSocial}</Link></td>
                      <td><Link to={"/proveedores/" + e.nif}>{e.municipio}</Link></td>
                      <td style={{ textAlign: "center" }}><Link to={"/proveedores/" + e.nif}><LinearProgressWithLabel value={e.estado} variant="determinate" /></Link></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

export default Proveedores;

