import React, {Component} from "react";
import { firestore } from "../../../providers/firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ExportToExcel } from "../../../providers/export-excel";


class ExportarListaTecnicos extends Component {
  constructor() {
    super()
    this.state = {
      tecnicos: [],
      activar: false
    }
  }
  componentDidMount() {
    let promises = []
      let tec = [];
      let hojasVisita = []
      let fVisitadas = []
      let HvRealizadas = []
      let promises2 = []
      let totalFincas = []
      let nifAAFF = []
      firestore.collection('hojasVisita').get().then(hv => {
          hv.docs.forEach( h => {
            promises2.push(hojasVisita.push(h.data()))
          })

          promises2.push(firestore.collection('fincas').get().then(finca => {
            finca.docs.forEach( f => {
              totalFincas.push(f.data())
              nifAAFF.push(f.data().nifAAFF)
            })
          }))
          
          Promise.all(promises2).then(() => {
            this.props.tecnicos.forEach((e, i) => {
              //BUCLE PARA VER LAS FINCAS VISITADAS POR TECNICO
              /* hojasVisita.forEach( hv => {
                if(hv.tecnico?.nif === e.nif){
                  fVisitadas.push(hv.comunidad.nif)
                  HvRealizadas.push(hv)
                  //fVisitadas++
                }
              }) */ 
              //console.log(fVisitadas)
              firestore.collection("aaff").where('nifTecnico', '==', e.nif).get().then(snapshot => {
                  //console.log(e.nif, snapshot.size)
                  let nFincas = 0
                  let nPendientes = 0
                  let nPendientesRenovadas = 0
                  let nVisitadas = 0
                  let nVisitadasRenovadas = 0
                  snapshot.docs.forEach(aaff => {
                    //BUCLE PARA VER LAS FINCAS VISITADAS POR AAFF
                    hojasVisita.forEach( hv => {
                      if(hv.aaff?.nif === aaff.data().nif){
                        fVisitadas.push(hv.comunidad.nif)
                        HvRealizadas.push(hv)
                        //fVisitadas++
                      }
                    })
                      //promises.push(firestore.collection("fincas").where('nifAAFF', '==', aaff.data().nif).get().then(finca => {
                          //nFincas = nFincas +finca.size
                          //finca.docs.forEach( f => {
                            if(nifAAFF.includes(aaff.data().nif)){
                              //console.log(aaff.data().nif)
                              totalFincas.forEach(f => {
                                if(f.nifAAFF === aaff.data().nif){
                                  if(f.activo === true || f.activo === "true" ||f.activo === undefined){
                                    //console.log(fVisitadas)
                                    nFincas++
                                    if(fVisitadas.includes(f.nif)){
                                      //console.log(f.renovada, f.renovadaVisitada, f.activo)
                                      HvRealizadas.forEach( fHv => {
                                        if(fHv.comunidad.nif === f.nif){
                                          let dias = ''
                                          let year = new Date(fHv.fechaVisitaRenovacion?.seconds*1000).getFullYear()
                                          if(f.renovada && fHv.fechaVisitaRenovacion !== undefined && year !== 1970){
                                            dias = this.daysDiff(new Date(fHv.fechaVisitaRenovacion?.seconds*1000), new Date())
                                          }else{
                                            dias = this.daysDiff(new Date(fHv.fechaVisita?.seconds*1000), new Date())
                                          }
                                          //console.log(f.nif, f.renovada, f.renovadaVisitada, dias)
                                          if(f.renovada && !f.renovadaVisitada && dias > 304){
                                            //console.log(e.nif+ f.nif+ " RENOVADA!")
                                            nPendientesRenovadas++
                                          }else if(f.renovadaVisitada){
                                            //console.log(e.nif+ f.nif+ " RENOVADA VISITADA!")
                                            nVisitadasRenovadas++
                                          }else{
                                            //console.log(e.nif+ f.nif+ " VISITADA!")
                                            nVisitadas++
                                          }
                                        }
                                      })
                                    }else{
                                      //console.log(e.nif+ f.nif+ " PENDIENTE!")
                                      nPendientes++
                                    }
                                    //console.log(e.nif, nPendientes, nPendientesRenovadas, nVisitadas, nVisitadasRenovadas, nFincas)
                                  }
                                }
                              })
                          }
                          //})
                      //}))
                  })
                  Promise.all(promises).then(() => {
                    //console.log(e.nif, nPendientes, nPendientesRenovadas, nVisitadas, nVisitadasRenovadas, nFincas)
                    //console.log(e.nif, tec)
                    let promises1 = []
                    //let pendientes = nFincas-fVisitadas
                    let nTotal = nPendientes+nVisitadas
                    let porcentajeVisitadas = nVisitadas === 0 && nTotal === 0 ? 0 : Math.round(((nVisitadas/nTotal)*100 + Number.EPSILON) * 100) / 100
                    let nTotalRenovadas = nPendientesRenovadas+nVisitadasRenovadas
                    let porcentajeVisitadasRenovacion = nVisitadasRenovadas === 0 && nTotalRenovadas === 0 ? 0 : Math.round(((nVisitadasRenovadas/nTotalRenovadas)*100 + Number.EPSILON) * 100) / 100
                    let estimacion = Math.round(((nPendientes/160) + Number.EPSILON) * 100) / 100
                    let estimacionRenovacion = Math.round(((nPendientesRenovadas/160) + Number.EPSILON) * 100) / 100
                    const data = {
                        nombreTecnico:e.nombre+" "+e.apellidos,
                        nif: e.nif,
                        porcentajeVisitado: porcentajeVisitadas,
                        pendientes: nPendientes,
                        //visitadas: nVisitadas,
                        estimacion: estimacion,
                        porcentajeVisitadoRenovacion: porcentajeVisitadasRenovacion,
                        pendientesRenovacion: nPendientesRenovadas,
                        //visitadasRenovadas: nVisitadasRenovadas,
                        estimacionRenovacion: estimacionRenovacion
                    }
                    //console.log(data)
                    promises1.push(tec.push(data))
                    Promise.all(promises1).then(() => {
                        //console.log(tec)
                        this.setState({tecnicos:tec, activar:true})
                    })
                  })
              })
              
            })
          })
      })
  }

  daysDiff = (dateFrom, dateTo) => {
    var diasdif= dateTo.getTime()-dateFrom.getTime();
    var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
    return contdias 
  }

  render(){   
    
    return (
      <>
      {this.state.tecnicos.length === this.props.tecnicos.length ?
        <ExportToExcel apiData={this.state.tecnicos} fileName={"Listado de técnicos"} />
      :
        <span className='bigBtn' style={{ cursor:'context-menu' }}><CircularProgress style={{color:'#fff', width:'16px', height: '16px', marginRight: '10px'}} /> Generando listado</span>
      }
      </>
    );
  }
  
}


export default ExportarListaTecnicos;
