import React from "react";
import { Grid } from "@material-ui/core";
import { firestore, storage, auth } from "../../../providers/firebase";
import firebase from "firebase";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";

class HojaVisita extends React.Component {
  constructor() {
    super()
    this.state = {
      finca: undefined,
      nPlantas: undefined,
      comentarios: undefined,
      tecnico: undefined,
      nombreTecnico: undefined,
      fechaVisita: undefined,
      fechaVisitaRenovacion: undefined,
      imagen: undefined,
      imagenURL: undefined,
      aaff: undefined,
      objHojaVisita: undefined,
      imgCargada: false,
      img: undefined,
      porcentajeVisitado: 0,
      nFincas: 0,
      porcentajeVisitadoAAFF: 0,
      nFincasAAFF: 0,
      guardando: false,
      notificadoCAE: false,
      tecnicoACAE:{},
      tecnicoAAAFF:{},
      centroTrabajo:{},
      imgs: undefined,
      imagenes:[],
      imagenesUrl:[],
      imagenesToRemove:[],
      vaciarArr:false,
      tipo: "EDIFICIO",
      imagenComprimida: undefined,
      imagenComprimidaNombre: undefined,
      imagenesComprimidas:[],
      imagenesComprimidasNombres:[],
      existeHv:undefined
    }
  }
  componentDidMount() {
      firestore.collection("hojasVisita").doc(this.props.params.nif).get().then(hv => {
        if(hv.data()?.centrosTrabajo !== undefined){
          hv.data().centrosTrabajo.forEach(c => {
            if(c.nif === this.props.params.centroTrabajo){
              if(c !== undefined){
                if(c.imagenes?.length > 0 && c.imagenes !== undefined){
                  this.setState({imagenesToRemove: c.imagenes})
                  c.imagenes.forEach(i => {
                    storage.ref(i).getDownloadURL().then(img => {
                      this.setState({imagenesUrl: ([...this.state.imagenesUrl, img])})
                    })
                  })
                  //console.log(this.state.imagenesUrl)
                }
                if(c.img !== '' || c.img !== undefined){
                  storage.ref(c.img).getDownloadURL().then(img => {
                    
                      this.setState({imagenURL: img, imgCargada: true})
                      document.getElementById("imgComunidad").removeAttribute('required')
                    
                  })
                }
                var d = undefined
                if(c.fechaVisitaRenovacion === undefined){
                  d = new Date(0)
                }else{
                  new Date(c.fechaVisitaRenovacion?.seconds*1000)
                }
                var d = new Date(c.fechaVisitaRenovacion?.seconds*1000)
                var mm = d.getMonth() + 1 <10 ? '0'+(d.getMonth() + 1) : d.getMonth() + 1;
                var dd = d.getDate() <10 ? '0'+d.getDate():d.getDate();
                var yy = d.getFullYear();
                let nuevoFormatoRenovada = c.fechaVisitaRenovacion === undefined ? new Date(0) : yy+"-"+mm+"-"+dd

                //this.setState({objHojaVisita: hv.data()})
                var date = new Date(c.fechaVisita?.seconds*1000)
                var mm = date.getMonth() + 1 <10 ? '0'+(date.getMonth() + 1) : date.getMonth() + 1;
                var dd = date.getDate() <10 ? '0'+date.getDate():date.getDate();
                var yy = date.getFullYear();
                let nuevoFormatoVisita = yy+"-"+mm+"-"+dd
                this.setState({
                  existeHv: c.exists,
                  fechaVisita: nuevoFormatoVisita,
                  fechaVisitaRenovacion: nuevoFormatoRenovada,
                  nPlantas: c.nPlantas,
                  finca: hv.data().comunidad,
                  tecnico: hv.data().tecnico.nif,
                  nombreTecnico: hv.data().tecnico.nombre,
                  aaff: {
                    nif: hv.data().aaff.nif,
                    razonSocial: hv.data().aaff.razonSocial
                  },
                  img: c.img,
                  tipo: c.tipo === undefined ? 'EDIFICIO' : c.tipo,
                  p1_0: c.p1_0,
                  p1_1: c.p1_1,
                  p1_2: c.p1_2,
                  p1_3: c.p1_3,
                  p1_4: c.p1_4,
                  p1_5: c.p1_5,
                  p1_6: c.p1_6,
                  p1_7: c.p1_7,
                  p1_8: c.p1_8,
                  p2_0: c.p2_0,
                  p2_1: c.p2_1,
                  p2_2: c.p2_2,
                  p2_3: c.p2_3,
                  p2_4: c.p2_4,
                  p2_5: c.p2_5,
                  p2_6: c.p2_6,
                  p2_7: c.p2_7,
                  p2_8: c.p2_8,
                  p2_9: c.p2_9,
                  p2_10: c.p2_10,
                  p3_0: c.p3_0,
                  p3_1: c.p3_1,
                  p3_2: c.p3_2,
                  p3_3: c.p3_3,
                  p3_4: c.p3_4,
                  p4_0: c.p4_0,
                  p4_1: c.p4_1,
                  p4_2: c.p4_2,
                  p4_3: c.p4_3,
                  p4_4: c.p4_4,
                  p4_5: c.p4_5,
                  p4_6: c.p4_6,
                  p4_7: c.p4_7,
                  p4_8: c.p4_8,
                  p4_9: c.p4_9,
                  p4_10: c.p4_10,
                  p4_11: c.p4_11,
                  p4_12: c.p4_12,
                  p4_13: c.p4_13,
                  p4_14: c.p4_14,
                  p4_15: c.p4_15,
                  p4_16: c.p4_16,
                  p4_17: c.p4_17,
                  p5_0: c.p5_0,
                  p5_1: c.p5_1,
                  p5_2: c.p5_2,
                  p5_3: c.p5_3,
                  p6_0: c.p6_0,
                  p6_1: c.p6_1,
                  p6_2: c.p6_2,
                  p6_3: c.p6_3,
                  p10_0: c.p10_0,
                  p10_1: c.p10_1,
                  p10_2: c.p10_2,
                  p10_3: c.p10_3,
                  p10_4: c.p10_4,
                  p10_5: c.p10_5,
                  p10_6: c.p10_6,
                  p11_0: c.p11_0,
                  p11_1: c.p11_1,
                  p11_2: c.p11_2,
                  p12_0: c.p12_0,
                  p12_1: c.p12_1,
                  p12_2: c.p12_2,
                  p13_0: c.p13_0,
                  p13_1: c.p13_1,
                  p13_2: c.p13_2,
                  p13_3: c.p13_3,
                  p13_4: c.p13_4,
                  p13_5: c.p13_5,
                  p13_6: c.p13_6,
                  p13_7: c.p13_7,
                  p13_8: c.p13_8,
                  p13_9: c.p13_9,
                  p13_10: c.p13_10,
                  p13_11: c.p13_11,
                  p13_12: c.p13_12,
                  p13_13: c.p13_13,
                  p13_14: c.p13_14,
                  p13_15: c.p13_15,
                  p13_16: c.p13_16,
                  p13_17: c.p13_17,
                  p13_18: c.p13_18,
                  p13_19: c.p13_19,
                  p13_20: c.p13_20,
                  p13_21: c.p13_21,
                  comentarios: c.comentarios
                })
                this.tecFincasCAE()
                this.tecFincasAAFF()
              }else{
                this.setState({imgCargada:false})
              }
            }
          })
        }
        
      })
      let nifTecnico = ''
      let nifAAFF = ''
      let centroTrabajo = {}
    firestore.collection("fincas").doc(this.props.params.nif).get().then(finca => {
      firestore.collection("aaff").doc(finca.data().nifAAFF).get().then(aaff => {
        firestore.collection("tecnicos").doc(aaff.data().nifTecnico).get().then(tecnico => {
          nifTecnico = tecnico.data().nif
          nifAAFF = aaff.data().nif
          finca.data().centrosTrabajo.forEach(c => {
              if(c.nif === this.props.params.centroTrabajo){
                  centroTrabajo = c
            } 
          })
          this.setState({
            finca: finca.data(),
            centroTrabajo: centroTrabajo,
            tecnico: tecnico.data().nif,
            nombreTecnico: tecnico.data().nombre + ' ' + tecnico.data().apellidos,
            notificadoCAE: tecnico.data().notificadoCAE === undefined ? false : tecnico.data().notificadoCAE,
            
            aaff: {
              nif: aaff.data().nif,
              razonSocial: aaff.data().razonSocial,
              notificadoAAFF: aaff.data().notificadoAAFF === undefined ? false : aaff.data().notificadoAAFF,
              email: aaff.data().email
            }
          })
        })
      })
    })
  }

  tecFincasCAE(){ /* PORCENTAJE DE TODAS LAS FINCAS VISITADAS RESPECTO A LAS TOTALES ASIGNADAS  */
    let promises1 = []
    let data = {}
    let nfincas = 0 
    firestore.collection('hojasVisita').where('tecnico.nif', '==', auth.currentUser.uid).get().then(hv => {
      data.fVisitadas = hv.docs.length
    })
    firestore.collection('aaff').where('nifTecnico', '==', auth.currentUser.uid).get().then(aaff => {
      aaff.docs.forEach(a => {
        promises1.push(firestore.collection('fincas').where('nifAAFF', '==', a.data().nif).get().then(finca => {
          nfincas = nfincas + finca.docs.length
          data.nfincas = nfincas
        }))
      })
      Promise.all(promises1).then(() => {
        data.fincasSinVisita = data.nfincas-data.fVisitadas
        data.porcentaje = (data.fVisitadas/data.nfincas)*100
        this.setState({tecnicoACAE: data})
      })
    })
  }
  tecFincasAAFF(){ /* PORCENTAJE DE TODAS LAS FINCAS VISITADAS RESPECTO A UN AAFF */
    let promises1 = []
    let data = {}
    let nfincas = 0  
    setTimeout(() => {
      let aaffNif = this.state.aaff.nif
      firestore.collection('hojasVisita').where('tecnico.nif', '==', auth.currentUser.uid).where('aaff.nif', '==', aaffNif).get().then(hv => {
        data.fVisitadas = hv.docs.length
      })
      promises1.push(firestore.collection('fincas').where('nifAAFF', '==', aaffNif).get().then(finca => {
        nfincas = nfincas + finca.docs.length
        data.nfincas = nfincas
      }))
      Promise.all(promises1).then(() => {
        data.fincasSinVisita = data.nfincas-data.fVisitadas
        data.porcentaje = (data.fVisitadas/data.nfincas)*100
        this.setState({tecnicoAAAFF: data})
      })
    }, 5000)
    
  }

  handleChange = e => {
    //console.log(this.state.finca)
    this.setState({
      //...this.state.objHojaVisita,
      [e.target.name]: e.target.value
    })
    if (e.target.files?.[0]) {
      //console.log(e.target.name)
       if(e.target.name === 'imagen'){
        let nombre = e.target.files[0].name
        nombre = nombre.split('.');
        let nuevoNombre = nombre[0]+"_"+Date.now()+"."+nombre[1]
        this.comprimirImagen(e.target.files[0], 14).then(res =>{
          /* let imccompr = []
          imccompr.push({...res, name: e.target.files[0].name}) */
          this.setState({
            imagenComprimida: res,
            imagenComprimidaNombre: nuevoNombre,
            imagenURL: URL.createObjectURL(res),
            imgCargada: true
          })
        })
        /* this.setState({
          imagenURL: URL.createObjectURL(e.target.files[0]),
          imgCargada: true
        }) */
      }
      if(e.target.name === 'imagenes'){
        if(!this.state.vaciarArr){
          this.state.imagenes.splice(0, this.state.imagenes.length)
          this.state.imagenesUrl.splice(0, this.state.imagenesUrl.length)
          this.setState({vaciarArr:true})
        }
        let nombres = e.target.files[0].name
        nombres = nombres.split('.');
        let nuevosNombres = nombres[0]+"_"+Date.now()+"."+nombres[1]
        this.comprimirImagen(e.target.files[0], 14).then(res1 =>{
          this.setState({
            imagenesComprimidas: ([...this.state.imagenesComprimidas, res1]),
            imagenesComprimidasNombres: ([...this.state.imagenesComprimidasNombres, nuevosNombres]),
            imagenes: ([...this.state.imagenes, res1]),
            imagenesUrl: ([...this.state.imagenesUrl, URL.createObjectURL(res1)])
          })
        })
        /* this.setState({
          imagenes:([...this.state.imagenes, e.target.files[0]]),
          imagenesUrl: ([...this.state.imagenesUrl, URL.createObjectURL(e.target.files[0])])
        })  */
        //console.log(this.state.imagenes)
      }
    }
    //console.log(this.state.imagenesToRemove)
  }

  comprimirImagen = (imagenComoArchivo, porcentajeCalidad) => {
		/*
			https://parzibyte.me/blog
		*/
		return new Promise((resolve, reject) => {
			const $canvas = document.createElement("canvas");
			const imagen = new Image();
			imagen.onload = () => {
				$canvas.width = imagen.width;
				$canvas.height = imagen.height;
				$canvas.getContext("2d").drawImage(imagen, 0, 0);
				$canvas.toBlob(
					(blob) => {
						if (blob === null) {
							return reject(blob);
						} else {
							resolve(blob);
						}
					},
					"image/jpeg",
					porcentajeCalidad / 100,
				);
			};
			imagen.src = URL.createObjectURL(imagenComoArchivo);
		});
	};

  handleSubmit = (event) => {
    event.preventDefault()
    const toastInfo = toast.info("Guardando Hoja de visita",{autoClose:false})
    this.setState({ guardando: true })
    /* let nombreImg = ''
    let ruta = ''
    if(this.state.imgCargada){
      const n = this.state.img.split("/")
      nombreImg = n[2].split('.')
      ruta = this.state.img
    }else{
      let img = document.getElementById("imgComunidad").files[0]
      ruta = "hojasVisita/" + this.state.finca.nif + "/" + img["name"]
      nombreImg = img
      
    } */
    let promises=[]
    let imgsUrl=[]
    let imgPrincipal = undefined
    let imgPrincipalNombre = undefined
    /* console.log(document.getElementById("imgComunidad").files[0])
    console.log(this.state.imagenURL) */
    if(document.getElementById("imgComunidad").files[0]?.name !==undefined ){
      imgPrincipal = this.state.imagenComprimida
      imgPrincipalNombre = this.state.imagenComprimidaNombre
      promises.push( 
        storage.ref("hojasVisita/" + this.state.finca.nif + "/" + this.state.centroTrabajo.nif + "/" + imgPrincipalNombre).put(imgPrincipal).then(snapshot => {
          this.setState({img: snapshot.ref.fullPath})
        }))
    }
    promises.push(
      this.state.imagenesComprimidas.forEach((img, i) => {
      //console.log(img)
      storage.ref("hojasVisita/" + this.state.finca.nif + "/" + this.state.centroTrabajo.nif + "/" + this.state.imagenesComprimidasNombres[i]).put(img).then(snapshot => {
        //console.log(snapshot.ref.fullPath)
        imgsUrl.push(snapshot.ref.fullPath)
      })
    }))
    return Promise.all(promises).then(() => {
      let promises1 = []
      this.setState({imagenesUrl: imgsUrl})
      let q = {}
      if (this.state['p2_0'] === "s") {
        q['p2_1'] = this.state['p2_1']
        q['p2_2'] = this.state['p2_2']
        q['p2_3'] = this.state['p2_3']
        q['p2_4'] = this.state['p2_4']
        q['p2_5'] = this.state['p2_5']
        if(this.state['p2_5'] === 'n'){
          q['p2_6'] = this.state['p2_6']
          q['p2_7'] = this.state['p2_7']
          q['p2_8'] = this.state['p2_8']
        }
        q['p2_9'] = this.state['p2_9']
        q['p2_10'] = this.state['p2_10']
      }
      if (this.state['p3_0'] === "s") {
        q['p3_1'] = this.state['p3_1']
        q['p3_2'] = this.state['p3_2']
        q['p3_3'] = this.state['p3_3']
        q['p3_4'] = this.state['p3_4']
      }
      if (this.state['p4_0'] === "s") {
        q['p4_1'] = this.state['p4_1']
        q['p4_2'] = this.state['p4_2']
        q['p4_3'] = this.state['p4_3']
        q['p4_4'] = this.state['p4_4']
        q['p4_5'] = this.state['p4_5']
        q['p4_6'] = this.state['p4_6']
        q['p4_7'] = this.state['p4_7']
        q['p4_8'] = this.state['p4_8']
        q['p4_9'] = this.state['p4_9']
        q['p4_10'] = this.state['p4_10']
        q['p4_11'] = this.state['p4_11']
        q['p4_12'] = this.state['p4_12']
        q['p4_13'] = this.state['p4_13']
        q['p4_14'] = this.state['p4_14']
        q['p4_15'] = this.state['p4_15']
      }
      if (this.state['p5_0'] === "s") {
        q['p5_1'] = this.state['p5_1']
        if (this.state['p5_1'] === "s"){
          q['p5_2'] = this.state['p5_2']
          if (this.state['p5_2'] === "s"){
            q['p5_3'] = this.state['p5_3']
          }
        }
      }
      if (this.state['p6_0'] === "s") {
        q['p6_1'] = this.state['p6_1']
        q['p6_2'] = this.state['p6_2']
        q['p6_3'] = this.state['p6_3']
      }
      if (this.state['p10_0'] === "s") {
        q['p10_1'] = this.state['p10_1']
        q['p10_2'] = this.state['p10_2']
        q['p10_3'] = this.state['p10_3']
        q['p10_4'] = this.state['p10_4']
        q['p10_5'] = this.state['p10_5']
        q['p10_6'] = this.state['p10_6']
      }
      if (this.state['p11_0'] === "s") {
        q['p11_1'] = this.state['p11_1']
        q['p11_2'] = this.state['p11_2']
      }
      if (this.state['p12_0'] === "s") {
        q['p12_1'] = this.state['p12_1']
        q['p12_2'] = this.state['p12_2']
      }
      if (this.state['p13_0'] === "s") {
        q['p13_1'] = this.state['p13_1']
        q['p13_2'] = this.state['p13_2']
        q['p13_3'] = this.state['p13_3']
        q['p13_4'] = this.state['p13_4']
        q['p13_5'] = this.state['p13_5']
        q['p13_6'] = this.state['p13_6']
        q['p13_7'] = this.state['p13_7']
        q['p13_8'] = this.state['p13_8']
        q['p13_9'] = this.state['p13_9']
        q['p13_10'] = this.state['p13_10']
        q['p13_11'] = this.state['p13_11']
        q['p13_12'] = this.state['p13_12']
        q['p13_13'] = this.state['p13_13']
        q['p13_14'] = this.state['p13_14']
        q['p13_15'] = this.state['p13_15']
        q['p13_16'] = this.state['p13_16']
        q['p13_17'] = this.state['p13_17']
        q['p13_18'] = this.state['p13_18']
        q['p13_19'] = this.state['p13_19']
        q['p13_20'] = this.state['p13_20']
        q['p13_21'] = this.state['p13_21']
      }
      let toRemove = []
      promises1.push(firestore.collection("hojasVisita").doc(this.state.finca.nif).get().then(snap => {
        if(snap.data()?.centrosTrabajo !== undefined ){
          snap.data().centrosTrabajo.forEach(c => {
            if(c.nif === this.props.params.centroTrabajo){
              toRemove.push(c)
            }
          })
          //console.log(toRemove)
        }
      }))
      return Promise.all(promises1).then(() => {
        console.log(this.state)
        setTimeout(() => {
          //Si no se añaden nuevas imagenes se mantienen las anteriores
          if(this.state.imagenesUrl.length===0){
            this.setState({imagenesUrl: this.state.imagenesToRemove})
          }
          //
          //Si existe el centro de trabajo se elimina primero para modificar los nuevos datos.
          if(toRemove.length > 0){
            firestore.collection("hojasVisita").doc(this.state.finca.nif).set({
                centrosTrabajo: firebase.firestore.FieldValue.arrayRemove(...toRemove)
            }, {merge: true})
          }
          firestore.collection("hojasVisita").doc(this.state.finca.nif).set({
              
            comunidad: {
              nif: this.state.finca.nif,
              razonSocial: this.state.finca.razonSocial,
              direccion: this.state.finca.direccion,
              localidad: this.state.finca.municipio,
              codigoPostal: this.state.finca.codigoPostal,
              telefono: this.state.finca.contacto.telefono,
              proveedores: this.state.finca.proveedores,
            },
            tecnico: {
              nif: this.state.tecnico,
              nombre: this.state.nombreTecnico
            },
            aaff: this.state.aaff,
            centrosTrabajo:
            firebase.firestore.FieldValue.arrayUnion({
              tipo: this.state.tipo,
              comentarios: this.state.comentarios,
              nPlantas: this.state.nPlantas,
              fechaVisita: new Date(this.state.fechaVisita),
              fechaVisitaRenovacion: this.state.fechaVisitaRenovacion === undefined ? new Date(0) : new Date(this.state.fechaVisitaRenovacion),
              img: this.state.img,
              nombre: this.state.centroTrabajo.nombre,
              nif: this.state.centroTrabajo.nif,
              imagenes: this.state.imagenesUrl,
              p1_0: this.state['p1_0'],
              p1_1: this.state['p1_1'],
              p1_2: this.state['p1_2'],
              p1_3: this.state['p1_3'],
              p1_4: this.state['p1_4'],
              p1_5: this.state['p1_5'],
              p1_6: this.state['p1_6'],
              p1_7: this.state['p1_7'],
              p1_8: this.state['p1_8'],
              p2_0: this.state['p2_0'],
              p3_0: this.state['p3_0'],
              p4_0: this.state['p4_0'],
              p5_0: this.state['p5_0'],
              p6_0: this.state['p6_0'],
              p10_0: this.state['p10_0'],
              p11_0: this.state['p11_0'],
              p12_0: this.state['p12_0'],
              p13_0: this.state['p13_0'],
              ...q
              })
          }, {merge: true}).then(() => {
            /* console.log(this.state.imagenesUrl?.length)
            console.log(this.state.imagenesToRemove?.length)
            console.log(this.state.imagenes)
            console.log(this.state.imagenes.length) */
            /* if(this.state.imagenes.length > 0){
              if(this.state.imagenesToRemove?.length > 0 && this.state.imagenesUrl?.length > 0){
                
                firestore.collection("hojasVisita").doc(this.state.finca.nif).update({
                  centroTrabajo:{
                    imagenes: firebase.firestore.FieldValue.arrayRemove(...this.state.imagenesToRemove), 
                  }
                })
              }
              //console.log(this.state.imagenesUrl)
              if(this.state.imagenesUrl.length > 0){
                //console.log("entra para subir array")
                firestore.collection("hojasVisita").doc(this.state.finca.nif).update({
                  centroTrabajo:{
                    imagenes: firebase.firestore.FieldValue.arrayUnion(...this.state.imagenesUrl),
                  }
                })
              }else{
                firestore.collection("hojasVisita").doc(this.state.finca.nif).update({
                  centroTrabajo: {
                    imagenes: [],
                  }
                })
              }
            }else{
              firestore.collection("hojasVisita").doc(this.state.finca.nif).update({
                centroTrabajo: {
                  imagenes: [],
                }
              })
            } */
            toast.update(toastInfo, {
              render: "Hoja de visita guardada con éxito",
              type: toast.TYPE.SUCCESS,
              autoClose: true
            });
            this.setState({ guardando: false })
            /* console.log(this.state.finca)
            console.log(this.state.centroTrabajo) */
            const generarHojaVisitaCentroTrabajo = firebase.app().functions('europe-west1').httpsCallable('generarHojaVisitaCentroTrabajo');
            generarHojaVisitaCentroTrabajo({finca: this.state.finca, centroTrabajo: this.state.centroTrabajo })
            .then( result => {
              //console.log(result.data);
              //toast.success("Email enviado con éxito")
            })
  
            let promises2 = []
            let toRemove = []
            let numRenovadasVisitadas = 0
            //console.log(this.state.centroTrabajo)
            this.state.finca.centrosTrabajo.map(ct => {
              //console.log(ct.renovadaVisitada)
              if(ct.renovadaVisitada){
                numRenovadasVisitadas++
              }
            })
            //console.log(this.state.finca.centrosTrabajo.length, numRenovadasVisitadas)
            if(this.state.finca.centrosTrabajo.length === numRenovadasVisitadas){
              firestore.collection('fincas').doc(this.state.finca.nif).update({
                renovadaVisitada: true
              })
            }
            /* promises2.push(firestore.collection("fincas").doc(this.state.finca.nif).get().then(snap => {
              if(snap.data()?.centrosTrabajo !== undefined ){
                snap.data().centrosTrabajo.forEach(c => {
                  if(c.nif === this.props.params.centroTrabajo){
                    console.log(c)
                    toRemove.push(c)
                  }
                })
                //console.log(toRemove)
              }
            }))
            Promise.all(promises2).then(() => {
              
              if(toRemove.length > 0){
                firestore.collection("fincas").doc(this.state.finca.nif).set({
                    centrosTrabajo: firebase.firestore.FieldValue.arrayRemove(...toRemove)
                }, {merge: true})
              }
              firestore.collection("fincas").doc(this.state.finca.nif).set({
                  
                centrosTrabajo:
                firebase.firestore.FieldValue.arrayUnion({
                  
                })
              }, {merge: true})
            }) */
  
  
            setTimeout(() => {
              window.location.replace("/comunidades")
            }, 4000);
          })
          .catch((err) => {
            console.log(err)
            toast.error("Hubo un problema al guardar la hoja de visita")
          })
        },500)
      })
      
    })
  }

  render() {
    if (!this.state.finca) return null
    return <div className="hojaVisitaContainer">
      <h1>Hoja de visita</h1>
      <form onSubmit={this.handleSubmit}>
        <h2>Datos de la comunidad</h2>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={5}>
          <Grid item xs={12} sm={6}>
            <label htmlFor="tipo">Tipo de edificio</label>
            <select value={this.state.tipo} onChange={e => this.setState({ tipo: e.target.value })} name="tipo">
              <option value="EDIFICIO">EDIFICIO</option>
              <option value="GARAJE">GARAJE</option>
            </select>
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid>
          <Grid item xs={12} sm={6}>
            <label htmlFor="razonSocial">Comunidad</label>
            <input name="razonSocial" type="text" required disabled value={this.state.finca.razonSocial+ " - "+ this.state.centroTrabajo.nombre} ></input>
          </Grid>
          <Grid item xs={12} sm={6}>
            <label htmlFor="nPlantas">Nº de plantas</label>
            <input name="nPlantas" type="number" required value={this.state.nPlantas} onChange={this.handleChange}></input>
          </Grid>
          <Grid item xs={12} sm={6}>
            <label htmlFor="nombreTecnico">Técnico PRL</label>
            <input name="nombreTecnico" type="text" required disabled value={this.state.nombreTecnico} onChange={this.handleChange}></input>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.state.finca.renovada && this.state.existeHv ?
              <>
                <label htmlFor="fechaVisitaRenovacion">Fecha de renovacion</label>
                <input name="fechaVisitaRenovacion" type="date" required value={this.state.fechaVisitaRenovacion} onChange={this.handleChange}></input>
              </>
            :
              <>
                <label htmlFor="fechaVisita">Fecha de la visita</label>
                <input name="fechaVisita" type="date" required value={this.state.fechaVisita} onChange={this.handleChange}></input>
              </>
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <label htmlFor="imagen">Imagen comunidad</label>
            <input id="imgComunidad" name="imagen" type="file" required accept="image/png, image/jpeg" value={this.state.imagen} onChange={this.handleChange}></input>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.state.imgCargada ? <div style={{ paddingTop: '66.66%', backgroundImage: `url(${this.state.imagenURL})`, backgroundPosition: 'center', backgroundSize: 'cover' }} ></div> : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <label htmlFor="imagenes">Imagenes comunidad</label>
            <input id="imgsComunidad" name="imagenes" type="file" accept="image/png, image/jpeg" value={this.state.imgs} onChange={this.handleChange}></input>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div>
              {this.state.imagenesUrl.map(i=> {
                //console.log(i)
                return ( <img key={i} style={{width:'150px', height:'120px'}} src={i} />)
              })}
            </div>
          </Grid>
        </Grid>
        <h2>Riesgos del edificio generales</h2>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={5}>
        <Grid item xs={12} sm={6}>
            <h3>1.- ¿El estado general del edificio es correcto?</h3>
            <div>
              <label>
                <input type="radio" checked={this.state['p1_0'] === 's'} name="p1_0" value="s" onChange={this.handleChange} required />
                Sí
              </label>
              <label>
                <input type="radio" checked={this.state['p1_0'] === 'n'} name="p1_0" value="n" onChange={this.handleChange} required />
                No
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.state['p1_0'] !== undefined ?
              <>
                <label>1.1. ¿Hay riesgo de desprendimiento de baldosas o elementos?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p1_1'] === 's'} name="p1_1" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p1_1'] === 'n'} name="p1_1" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>1.2. ¿Hay cristales rotos o en mal estado de puertas/ventanas?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p1_2'] === 's'} name="p1_2" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p1_2'] === 'n'} name="p1_2" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>1.3. ¿Hay baldosas rotas o ausencia de las mismas?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p1_3'] === 's'} name="p1_3" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p1_3'] === 'n'} name="p1_3" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>1.4. ¿Hay cables eléctricos sueltos?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p1_4'] === 's'} name="p1_4" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p1_4'] === 'n'} name="p1_4" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>1.5. ¿Las cajas de registro están abiertas?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p1_5'] === 's'} name="p1_5" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p1_5'] === 'n'} name="p1_5" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>1.6. ¿Hay riesgo de deslizamiento en las rampas de acceso?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p1_6'] === 's'} name="p1_6" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p1_6'] === 'n'} name="p1_6" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>1.7.	¿El cuadro eléctrico se encuentra abierto?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p1_7'] === 's'} name="p1_7" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p1_7'] === 'n'} name="p1_7" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>1.8. ¿El cuadro eléctrico se encuentra señalizado?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p1_8'] === 's'} name="p1_8" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p1_8'] === 'n'} name="p1_8" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
              </>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3>2.- ¿La comunidad dispone de ascensores?</h3>
            <div>
              <label>
                <input type="radio" checked={this.state['p2_0'] === 's'} name="p2_0" value="s" onChange={this.handleChange} required />
                Sí
              </label>
              <label>
                <input type="radio" checked={this.state['p2_0'] === 'n'} name="p2_0" value="n" onChange={this.handleChange} required />
                No
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.state['p2_0'] === "s" ?
              <>
                <label>2.1. ¿Están revisados?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p2_1'] === 's'} name="p2_1" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p2_1'] === 'n'} name="p2_1" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>2.2. ¿Dispone de iluminación 24h interior?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p2_2'] === 's'} name="p2_2" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p2_2'] === 'n'} name="p2_2" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>2.3. ¿Dispone de iluminación 24h exterior?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p2_3'] === 's'} name="p2_3" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p2_3'] === 'n'} name="p2_3" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>2.4. ¿Dispone de alarma?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p2_4'] === 's'} name="p2_4" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p2_4'] === 'n'} name="p2_4" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>2.5. ¿Está cerrada la sala de máquinas?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p2_5'] === 's'} name="p2_5" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p2_5'] === 'n'} name="p2_5" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                {this.state['p2_5'] === "n" ?
                  <>
                    <label>2.6. ¿Está protegido el motor de la sala de máquinas?</label>
                    <div className="radioGroup">
                      <label>
                        <input type="radio" checked={this.state['p2_6'] === 's'} name="p2_6" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p2_6'] === 'n'} name="p2_6" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p2_6'] === 'np'} name="p2_6" value="np" onChange={this.handleChange} required />
                        No Procede
                      </label>
                    </div>
                    <label>2.7.	¿Dispone de señalización de riesgo eléctrico?</label>
                    <div className="radioGroup">
                      <label>
                        <input type="radio" checked={this.state['p2_7'] === 's'} name="p2_7" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p2_7'] === 'n'} name="p2_7" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    <label>2.8. ¿Existe dentro de la sala de máquinas extintor?</label>
                    <div className="radioGroup">
                      <label>
                        <input type="radio" checked={this.state['p2_8'] === 's'} name="p2_8" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p2_8'] === 'n'} name="p2_8" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                  </>
                : null}
                <label>2.9. ¿Dispone de extintor en el exterior de la sala de máquinas?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p2_9'] === 's'} name="p2_9" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p2_9'] === 'n'} name="p2_9" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>2.10. ¿Existe un cartel en las inmediaciones del cuarto de máquinas con el texto:<br />"Cuarto de maquinaria del ascensor. Peligro. Se prohíbe la entrada a toda persona ajena al servicio."?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p2_10'] === 's'} name="p2_10" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p2_10'] === 'n'} name="p2_10" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
              </>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3>3.- ¿Dispone de azotea transitable?</h3>
            <div>
              <label>
                <input type="radio" checked={this.state['p3_0'] === 's'} name="p3_0" value="s" onChange={this.handleChange} required />
                Sí
              </label>
              <label>
                <input type="radio" checked={this.state['p3_0'] === 'n'} name="p3_0" value="n" onChange={this.handleChange} required />
                No
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.state['p3_0'] === "s" ?
              <>
                <label>3.1. ¿El acceso a la azotea se realiza de forma segura?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p3_1'] === 's'} name="p3_1" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p3_1'] === 'n'} name="p3_1" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>3.2. ¿Si el acceso es mediante escaleras, se accede de forma segura?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p3_2'] === 's'} name="p3_2" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p3_2'] === 'n'} name="p3_2" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>3.3. ¿Dispone de protección perimetral que impida la caida de personas a diferente nivel?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p3_3'] === 's'} name="p3_3" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p3_3'] === 'n'} name="p3_3" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>3.4. ¿Dispone de anclajes para la línea de vida?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p3_4'] === 's'} name="p3_4" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p3_4'] === 'n'} name="p3_4" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p3_4'] === 'np'} name="p3_4" value="np" onChange={this.handleChange} required />
                    No Procede
                  </label>
                </div>
              </>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3>4.- ¿Dispone de parking?</h3>
            <div>
              <label>
                <input type="radio" checked={this.state['p4_0'] === 's'} name="p4_0" value="s" onChange={this.handleChange} required />
                Sí
              </label>
              <label>
                <input type="radio" checked={this.state['p4_0'] === 'n'} name="p4_0" value="n" onChange={this.handleChange} required />
                No
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.state['p4_0'] === "s" ?
              <>
                <label>4.1. ¿Dispone de número suficiente de extintores? (aproximadamente uno cada 15 metros de recorrido hasta la salida más cercana)</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_1'] === 's'} name="p4_1" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_1'] === 'n'} name="p4_1" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>4.2. ¿Están correctamente revisados los extintores? (si está caducada la fecha indicarlo en observaciones)</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_2'] === 's'} name="p4_2" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_2'] === 'n'} name="p4_2" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>4.3. ¿Están correctamente señalizados los extintores?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_3'] === 's'} name="p4_3" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_3'] === 'n'} name="p4_3" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>4.4. ¿Están colgados a una altura adecuada los extintores?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_4'] === 's'} name="p4_4" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_4'] === 'n'} name="p4_4" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>4.5. ¿Están en buen estado de conservación los extintores?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_5'] === 's'} name="p4_5" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_5'] === 'n'} name="p4_5" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>4.6. ¿Están señalizados los recorridos de evacuación?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_6'] === 's'} name="p4_6" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_6'] === 'n'} name="p4_6" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>4.7.	¿Están señalizadas las salidas de emergencia?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_7'] === 's'} name="p4_7" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_7'] === 'n'} name="p4_7" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>4.8. ¿Están señalizadas las direcciones de circulación (+500m²)?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_8'] === 's'} name="p4_8" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_8'] === 'n'} name="p4_8" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_8'] === 'np'} name="p4_8" value="np" onChange={this.handleChange} required />
                    No Procede
                  </label>
                </div>
                <label>4.9. ¿Están señalizadas las puertas de entrada y salida (2 puertas de acceso)?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_9'] === 's'} name="p4_9" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_9'] === 'n'} name="p4_9" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>4.10. ¿Dispone de sistemas automáticos de extinción de incendios (rociadores) (+500m² o 2 pisos)?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_10'] === 's'} name="p4_10" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_10'] === 'n'} name="p4_10" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_10'] === 'np'} name="p4_10" value="np" onChange={this.handleChange} required />
                    No procede
                  </label>
                </div>
                <label>4.11. ¿Dispone de sistemas de extracción de humos (+500m² o 2 pisos)?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_11'] === 's'} name="p4_11" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_11'] === 'n'} name="p4_11" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_11'] === 'np'} name="p4_11" value="np" onChange={this.handleChange} required />
                    No procede
                  </label>
                </div>
                <label>4.12. ¿Dispone de BIE's?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_12'] === 's'} name="p4_12" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_12'] === 'n'} name="p4_12" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_12'] === 'np'} name="p4_12" value="np" onChange={this.handleChange} required />
                    No procede
                  </label>
                </div>
                <label>4.13. ¿Dispone de detectores de humo?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_13'] === 's'} name="p4_13" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_13'] === 'n'} name="p4_13" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_13'] === 'np'} name="p4_13" value="np" onChange={this.handleChange} required />
                    No procede
                  </label>
                </div>
                <label>4.14. ¿El cuadro eléctrico está cerrado/protegido?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_14'] === 's'} name="p4_14" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_14'] === 'n'} name="p4_14" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>4.15. ¿Está señalizado el cuadro eléctrico con el panel de "Riesgo eléctrico"?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p4_15'] === 's'} name="p4_15" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p4_15'] === 'n'} name="p4_15" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
              </>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3>5.- ¿Dispone de jardin?</h3>
            <div>
              <label>
                <input type="radio" checked={this.state['p5_0'] === 's'} name="p5_0" value="s" onChange={this.handleChange} required />
                Sí
              </label>
              <label>
                <input type="radio" checked={this.state['p5_0'] === 'n'} name="p5_0" value="n" onChange={this.handleChange} required />
                No
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.state['p5_0'] === "s" ?
              <>
                <label>5.1. ¿Se almacenan productos fitosanitarios?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p5_1'] === 's'} name="p5_1" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p5_1'] === 'n'} name="p5_1" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                {this.state['p5_1'] === "s" ?
                  <>
                    <label>5.2. ¿Se guardan los productos bajo llave?</label>
                    <div className="radioGroup">
                      <label>
                        <input type="radio" checked={this.state['p5_2'] === 's'} name="p5_2" value="s" onChange={this.handleChange} required />
                        Sí
                      </label>
                      <label>
                        <input type="radio" checked={this.state['p5_2'] === 'n'} name="p5_2" value="n" onChange={this.handleChange} required />
                        No
                      </label>
                    </div>
                    {this.state['p5_2'] === "s" ? 
                      <>
                        <label>5.3. ¿Se dispone de las fichas de seguridad de los productos fitosanitarios?</label>
                        <div className="radioGroup">
                          <label>
                            <input type="radio" checked={this.state['p5_3'] === 's'} name="p5_3" value="s" onChange={this.handleChange} required />
                            Sí
                          </label>
                          <label>
                            <input type="radio" checked={this.state['p5_3'] === 'n'} name="p5_3" value="n" onChange={this.handleChange} required />
                            No
                          </label>
                        </div>
                      </> 
                    : null}
                  </>
                : null}
              </>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3>6.- ¿Dispone de piscina?</h3>
            <div>
              <label>
                <input type="radio" checked={this.state['p6_0'] === 's'} name="p6_0" value="s" onChange={this.handleChange} required />
                Sí
              </label>
              <label>
                <input type="radio" checked={this.state['p6_0'] === 'n'} name="p6_0" value="n" onChange={this.handleChange} required />
                No
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.state['p6_0'] === "s" ?
              <>
                <label>6.1. ¿Se utilizan productos para el mantenimiento de la piscina?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p6_1'] === 's'} name="p6_1" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p6_1'] === 'n'} name="p6_1" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>6.2. ¿Se dispone de protección perimetral de la piscina?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p6_2'] === 's'} name="p6_2" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p6_2'] === 'n'} name="p6_2" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>6.3. ¿Se dispone de aros salvavidas?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p6_3'] === 's'} name="p6_3" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p6_3'] === 'n'} name="p6_3" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
              </>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3>7.- ¿Disponen de extintores?</h3>
            <div>
              <label>
                <input type="radio" checked={this.state['p10_0'] === 's'} name="p10_0" value="s" onChange={this.handleChange} required />
                Sí
              </label>
              <label>
                <input type="radio" checked={this.state['p10_0'] === 'n'} name="p10_0" value="n" onChange={this.handleChange} required />
                No
              </label>
              <label>
                <input type="radio" checked={this.state['p10_0'] === 'np'} name="p10_0" value="np" onChange={this.handleChange} required />
                No procede
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.state['p10_0'] === "s" ?
              <>
                <label>7.1. ¿Dispone de número suficiente de extintores?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p10_1'] === 's'} name="p10_1" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p10_1'] === 'n'} name="p10_1" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>7.2. ¿Dispone de un extintor en cada piso/rellano?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p10_2'] === 's'} name="p10_2" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p10_2'] === 'n'} name="p10_2" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>7.3. ¿Están correctamente revisados? (si está caducada la fecha indicarlo en observaciones)</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p10_3'] === 's'} name="p10_3" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p10_3'] === 'n'} name="p10_3" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>7.4. ¿Están correctamente señalizados?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p10_4'] === 's'} name="p10_4" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p10_4'] === 'n'} name="p10_4" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>7.5. ¿Están colgados a una altura adecuada?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p10_5'] === 's'} name="p10_5" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p10_5'] === 'n'} name="p10_5" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>7.6. ¿Están en buen estado de conservación?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p10_6'] === 's'} name="p10_6" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p10_6'] === 'n'} name="p10_6" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
              </>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3>8.- ¿Disponen de iluminación de emergencias?</h3>
            <div>
              <label>
                <input type="radio" checked={this.state['p11_0'] === 's'} name="p11_0" value="s" onChange={this.handleChange} required />
                Sí
              </label>
              <label>
                <input type="radio" checked={this.state['p11_0'] === 'n'} name="p11_0" value="n" onChange={this.handleChange} required />
                No
              </label>
              <label>
                <input type="radio" checked={this.state['p11_0'] === 'np'} name="p11_0" value="np" onChange={this.handleChange} required />
                No procede
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.state['p11_0'] === "s" ?
              <>
                <label>8.1. ¿Están iluminados los recorridos de evacuación?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p11_1'] === 's'} name="p11_1" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p11_1'] === 'n'} name="p11_1" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>8.2. ¿Están iluminadas las salidas de la finca?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p11_2'] === 's'} name="p11_2" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p11_2'] === 'n'} name="p11_2" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
              </>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3>9.- ¿Disponen de señalización de emergencias?</h3>
            <div>
              <label>
                <input type="radio" checked={this.state['p12_0'] === 's'} name="p12_0" value="s" onChange={this.handleChange} required />
                Sí
              </label>
              <label>
                <input type="radio" checked={this.state['p12_0'] === 'n'} name="p12_0" value="n" onChange={this.handleChange} required />
                No
              </label>
              <label>
                <input type="radio" checked={this.state['p12_0'] === 'np'} name="p12_0" value="np" onChange={this.handleChange} required />
                No procede
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.state['p12_0'] === "s" ?
              <>
                <label>9.1. ¿Están señalizadas todas las salidas de la finca?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p12_1'] === 's'} name="p12_1" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p12_1'] === 'n'} name="p12_1" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>9.2. ¿Están señalizados los recorridos de evacuación?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p12_2'] === 's'} name="p12_2" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p12_2'] === 'n'} name="p12_2" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
              </>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3>10.- ¿Dispone de trabajador propio (conserje o portero)?</h3>
            <div>
              <label>
                <input type="radio" checked={this.state['p13_0'] === 's'} name="p13_0" value="s" onChange={this.handleChange} required />
                Sí
              </label>
              <label>
                <input type="radio" checked={this.state['p13_0'] === 'n'} name="p13_0" value="n" onChange={this.handleChange} required />
                No
              </label>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.state['p13_0'] === "s" ?
              <>
                <label>10.1. ¿Realiza la limpieza de cristales de la puerta?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_1'] === 's'} name="p13_1" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_1'] === 'n'} name="p13_1" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.2. ¿El punto de operación está a más de dos metros?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_2'] === 's'} name="p13_2" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_2'] === 'n'} name="p13_2" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.3. ¿Realiza la limpieza general de las escaleras?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_3'] === 's'} name="p13_3" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_3'] === 'n'} name="p13_3" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.4. ¿Dispone de la ficha de datos de seguridad de los productos utilizados?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_4'] === 's'} name="p13_4" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_4'] === 'n'} name="p13_4" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.5. ¿Dispone de señalización de suelos mojados?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_5'] === 's'} name="p13_5" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_5'] === 'n'} name="p13_5" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.6. ¿Realiza el cambio de bombillas?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_6'] === 's'} name="p13_6" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_6'] === 'n'} name="p13_6" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.7.	¿El punto de operación está a más de dos metros?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_7'] === 's'} name="p13_7" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_7'] === 'n'} name="p13_7" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.8. ¿Recoge las basuras de los vecinos?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_8'] === 's'} name="p13_8" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_8'] === 'n'} name="p13_8" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.9. ¿Realiza la gestión postal de los vecinos?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_9'] === 's'} name="p13_9" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_9'] === 'n'} name="p13_9" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.10. ¿Ha recibido formación en prevención de riesgos específica a su puesto de trabajo?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_10'] === 's'} name="p13_10" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_10'] === 'n'} name="p13_10" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.11. ¿Ha realizado el reconocimiento médico?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_11'] === 's'} name="p13_11" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_11'] === 'n'} name="p13_11" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.12. ¿Realiza tratamientos a las piscinas?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_12'] === 's'} name="p13_12" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_12'] === 'n'} name="p13_12" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.13. ¿Si realiza trabajos de higienización de la piscina, dispone de los EPI's?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_13'] === 's'} name="p13_13" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_13'] === 'n'} name="p13_13" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.14. ¿Realiza tratamientos fitosanitarios?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_14'] === 's'} name="p13_14" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_14'] === 'n'} name="p13_14" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.15. ¿Si realiza trabajos fitosanitarios, dispone de los EPI's?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_15'] === 's'} name="p13_15" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_15'] === 'n'} name="p13_15" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.16. ¿Utiliza herramientas de poda?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_16'] === 's'} name="p13_16" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_16'] === 'n'} name="p13_16" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.17. ¿Utiliza herramientas de mano?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_17'] === 's'} name="p13_17" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_17'] === 'n'} name="p13_17" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.18. ¿Utiliza escaleras de mano?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_18'] === 's'} name="p13_18" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_18'] === 'n'} name="p13_18" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.19. ¿Conoce su Mutua de Accidentes?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_19'] === 's'} name="p13_19" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_19'] === 'n'} name="p13_19" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.20. ¿Conoce el trabajador el centro asistencial más cercano?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_20'] === 's'} name="p13_20" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_20'] === 'n'} name="p13_20" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
                <label>10.21. ¿Existe establecido un protocolo de comunicación entre el trabajador y la comunidad o el administrador de la finca?</label>
                <div className="radioGroup">
                  <label>
                    <input type="radio" checked={this.state['p13_21'] === 's'} name="p13_21" value="s" onChange={this.handleChange} required />
                    Sí
                  </label>
                  <label>
                    <input type="radio" checked={this.state['p13_21'] === 'n'} name="p13_21" value="n" onChange={this.handleChange} required />
                    No
                  </label>
                </div>
              </>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <label htmlFor="comentarios">Comentarios</label>
            <textarea name="comentarios" required value={this.state.comentarios} onChange={this.handleChange} style={{width:"100%", height:"300px", backgroundColor:"white"}}></textarea>
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: 'flex-end' }}>
          <input type="submit" value="Guardar y enviar" id="guardar" disabled = {this.state.guardando ? "disabled" : ""} style={this.state.guardando ? {opacity: 0.5, cursor: "default"} : {}}></input>
        </div>
      </form>
    </div>
  }
}

export default withRouter(HojaVisita);
