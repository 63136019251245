import React from "react";
import "./App.css";
import "./routes/pages/Page.css"
import "./routes/pages/prov/provStyles.css"
import Apagar from "./icons/apagar.svg";
import { firestore, auth, storage } from "./providers/firebase";
import Logo from "./images/Logo.png";
import Menu from './components/Menu/Menu';

let roles = { aaff: "aaff", prov: "proveedores", tec: "tecnicos", admin: "admin", admon: "administrativos", com: "comerciales", tecAdmin: "tecAdmin" }

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      logoUrl: undefined,
      currentSection: undefined,
      mobile: false,
      menuOpen: false
    }
  }


  handleResize = () => {
    if (window.innerWidth <= 992) {
      this.setState({ mobile: true })
    } else {
      this.setState({ mobile: false })
    }
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    this.props.user.getIdTokenResult().then((idToken) => {
      firestore.collection(roles[idToken.claims.rol]).doc(this.props.user.uid).get().then(snapshot => {
        if (snapshot.data()?.logo) {
          storage.ref(snapshot.data().logo).getDownloadURL().then(url => this.setState({ logoUrl: url }))
        } else {
          this.setState({ logoUrl: Logo })
        }

      })
    })
  }

  render() {
    if (!this.state.logoUrl) return null;
    return (
      <div style={{
        display: 'grid',
        gridTemplateColumns: '170px auto',
        gridTemplateRows: '50px auto',
        height: '100vh',
        backgroundColor: '#fff'
      }}>
        {this.state.mobile ?
          <Menu logoUrl={this.state.logoUrl} menu={this.props.menu} /> :
          <>
            <div className="sidebar">
              {/* Top part of sidebar */}
              <div>
                <div className="logoContainer">
                  <img src={this.state.logoUrl} alt="logo" style={{ width: "80%", filter: 'invert(1)' }} />
                </div>
                {this.props.menu}
              </div>
              {/* Bottom part of sidebar */}
              <div>
                <div className="info"></div>
                <div className="info"></div>
              </div>
            </div>
            <div className="topbar">
              <div></div>
              <div className="cerrarSesion" onClick={() => auth.signOut()}>
                <img src={Apagar} alt="Cerrar sesión" />
              </div>
            </div>
          </>
        }
        <div className="content">
          {this.props.routes}
        </div>

      </div>
    );
  }
}

export default App;
