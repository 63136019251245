import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../../providers/firebase";
import HeaderBackground from "../../../images/banner_proveedores.jpg"
import { LinearProgress, withStyles, Box } from "@material-ui/core";
import { withRouter } from "../../../providers/withRouter";
import Desvincular from "../../../icons/desvincular.svg"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import orderArray from "../../../providers/orderArray";
import firebase from "firebase";
import { toast } from "react-toastify";
import Warning from "../../../icons/warning.svg"
import CircularProgress from '@material-ui/core/CircularProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: props => ({
    borderRadius: 5,
    backgroundColor: props.value < 25 ? '#FF5252' : (props.value < 75 ? "#fcba03" : "#7bc977"),
  })
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        {`${Math.round(
          props.value * 100
        ) / 100}%`}
      </Box>
    </Box>
  );
}


class Proveedores extends React.Component {
  constructor() {
    super()
    this.state = {
      aaff: {},
      proveedores: [],
      fincas: [],
      dialogDesvincular: {
        opened: false,
        title: "",
        proveedor: undefined
      },
      isLoading: true
    }
  }
  componentDidMount() {
    firestore.collection("aaff").doc(this.props.params.nif).get().then(doc => this.setState({ aaff: doc.data() })).catch(err => window.location.replace("/aaff"))
    this.loadPageContent()
    
  }
  loadPageContent = () => {
    let fincas = []
    firestore.collection("fincas").where("nifAAFF", "==", this.props.params.nif).get().then(snapshot => {
      let nifProveedores = []
      snapshot.docs.forEach(e => {
        fincas.push(e.data())
        nifProveedores = nifProveedores.concat(e.data().nifProveedores)
      })
      nifProveedores = [...new Set(nifProveedores)]
      let promises = []
      let proveedores = []
      nifProveedores.forEach(e => {
        promises.push(firestore.collection("proveedores").doc(e).get().then(prov => prov.exists ? proveedores.push(prov.data()) : null))
      })
      Promise.all(promises).then(() => {
        console.log(orderArray(proveedores))
        this.setState({ proveedores: orderArray(proveedores), fincas: fincas, isLoading: false })
      })

    })
  }
  monthDiff = (dateFrom, dateTo) => {
   return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  }
  
  desvincularProveedor = () => {
    let promises = []
    this.state.fincas.forEach(finca => {
      if (finca.proveedores !== []) {
        let toRemove = []
        finca.proveedores.forEach(provFinca => {
          if (provFinca.nif === this.state.dialogDesvincular.proveedor.nif) {
            toRemove.push(provFinca)
          }
        })
        if (toRemove.length !== 0) {
          promises.push(firestore.collection("fincas").doc(finca.nif).set({
            nifProveedores: firebase.firestore.FieldValue.arrayRemove(this.state.dialogDesvincular.proveedor.nif),
            proveedores: firebase.firestore.FieldValue.arrayRemove(...toRemove)
          }, { merge: true }))
        }
      }
    })
    Promise.all(promises).then(() => {
      toast.success("Proveedor desvinculado con éxito")
      this.setState({ dialogDesvincular: { opened: false, proveedor: undefined } })
      this.loadPageContent()
    })
  }

  render() {
    if (this.state.proveedores === []) return <></> //Loading data...
    return (
      <>
      
        <div className="pageHeader"  >Administrador / {this.state.aaff.razonSocial} / <b>Proveedores</b></div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              LISTADO PROVEEDORES
              <Link to={`/aaff`}><div className="gridHeadRight">{"<< volver al listado de AAFF"}</div></Link>
            </div>
            <div className="tableContainer">
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Proveedor</th>
                      <th>Última reclamación</th>
                      <th>Municipio</th>
                      <th style={{ textAlign: "center" }}>Cumplimiento</th>
                      <th></th>
                      <th style={{ textAlign: "center" }}>Desvincular</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.isLoading ? <tr><td colSpan='10' style={{textAlign:'center'}}><CircularProgress style={{color:'#848474'}} /> </td></tr> :
                    this.state.proveedores.length === 0 ? <tr><td colSpan='10' style={{textAlign:'center'}}>No tiene proveedores</td></tr> :
                    this.state.proveedores.map((e, i) => {
                      const ultimaReclamacion = e.ultimaReclamacion !==undefined ? e.ultimaReclamacion?.seconds : e.fechaAlta.seconds
                      return (
                        <tr key={i}>
                          <td>{i+1}</td>
                          <td><Link to={`/aaff/${this.props.params.nif}/proveedores/${e.nif}`}>{e.razonSocial}</Link></td>
                          <td><Link to={`/aaff/${this.props.params.nif}/proveedores/${e.nif}`}>{this.monthDiff(new Date(ultimaReclamacion*1000), new Date()) > 1 ? <span style={{color:"red"}}>{new Date(ultimaReclamacion*1000).toLocaleDateString()}</span> : <span style={{color:"black"}}>{new Date(ultimaReclamacion*1000).toLocaleDateString()}</span> }</Link></td>
                          <td><Link to={`/aaff/${this.props.params.nif}/proveedores/${e.nif}`}>{e.municipio}</Link></td>
                          <td style={{ textAlign: "center" }}><Link to={`/aaff/${this.props.params.nif}/proveedores/${e.nif}`}><LinearProgressWithLabel value={e.estado} variant="determinate" /></Link></td>
                          <td>{e.documentos.certificadoSS?.pendiente || e.documentos?.declaracionResponsable[this.props.params.nif]?.pendiente || e.documentos.evaluacionRiesgos?.pendiente || e.documentos.justificante?.pendiente
                              || e.documentos.lopd?.pendiente || e.documentos.planEmergencia?.pendiente || e.documentos.planPrevencion?.pendiente || e.documentos.planificacionPreventiva?.pendiente 
                              || e.documentos.seguro?.pendiente || e.documentos.vigilancia?.pendiente ? 
                              <Link to={`/aaff/${this.props.params.nif}/proveedores/${e.nif}`}><img src={Warning} alt="" width="30px"/></Link> : ""}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <img src={Desvincular} alt="Desvincular" className="tableIcon" onClick={() => this.setState({ dialogDesvincular: { opened: true, proveedor: e } })}></img>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* DESVINCULAR UNA FINCA */}
        <Dialog
          open={this.state.dialogDesvincular.opened}
          onClose={() => this.setState({ dialogDesvincular: { opened: false, proveedor: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">¿Quieres desvincular el proveedor?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Desvincular el proveedor <b>{this.state.dialogDesvincular.proveedor?.razonSocial}</b> de todas las fincas del AA.FF. <b>{this.state.aaff.razonSocial}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ dialogDesvincular: { opened: false, proveedor: undefined } })} color="inherit" style={{ color: "#FF5252" }}>
              Cancelar
            </Button>
            <Button onClick={this.desvincularProveedor} color="inherit" style={{ color: "#7bc977" }} autoFocus>
              <b>Desvincular</b>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withRouter(Proveedores);