import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PasswordSet from "./routes/PasswordSet";
import ProtectedRoute from "./providers/ProtectedRoute";
import Login from "./routes/Login";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HojaVisitaRender from "./routes/pages/tec/HojaVisitaRender";
import EvaluacionRiesgosRender from "./routes/pages/tec/EvaluacionRiesgosRender";
import PlanPrevencionRender from "./routes/pages/tec/PlanPrevencionRender";
import PlanEmergenciaRender from "./routes/pages/tec/PlanEmergenciaRender";
import HojaVisitaRenderCentroTrabajo from "./routes/pages/tec/HojaVisitaRenderCentroTrabajo";
import EvaluacionRiesgosRenderCentroTrabajo from "./routes/pages/tec/EvaluacionRiesgosRenderCentroTrabajo";
import PlanPrevencionRenderCentroTrabajo from "./routes/pages/tec/PlanPrevencionRenderCentroTrabajo";
import PlanEmergenciaRenderCentroTrabajo from "./routes/pages/tec/PlanEmergenciaRenderCentroTrabajo";
import InformeSituacionProveedores from "./routes/pages/admin/InformeSituacionProveedores";
import InformeSituacionComunidadesPDF from "./routes/pages/admin/InformeSituacionComunidadesPDF";
import InformeSituacionProveedoresPDF from "./routes/pages/admin/InformeSituacionProveedoresPDF";
import InformeSituacionRiesgosPDF from "./routes/pages/admin/InformeSituacionRiesgosPDF";
import InformeSituacionComunidades from "./routes/pages/admin/InformeSituacionComunidades";
import InformeSituacionRiesgos from "./routes/pages/admin/InformeSituacionRiesgos";
import CambioPassword from "./routes/cambiarPassword"
import PlanEmergenciaPDF from "./routes/pages/admin/PlanEmergenciaPDF";
import EvaluacionRiesgosPDF from "./routes/pages/admin/EvaluacionRiesgosPDF";
import HojaVisitaPDF from "./routes/pages/admin/HojaVisitaPDF";
import PlanEmergenciaPDFCentroTrabajo from "./routes/pages/admin/PlanEmergenciaPDFCentroTrabajo";
import EvaluacionRiesgosPDFCentroTrabajo from "./routes/pages/admin/EvaluacionRiesgosPDFCentroTrabajo";
import HojaVisitaPDFCentroTrabajo from "./routes/pages/admin/HojaVisitaPDFCentroTrabajo";
import Comunidad from './routes/pages/aaff/Comunidad'

ReactDOM.render(
  <>
    <Router>
      <Routes>
        
        <Route path="/cambiarPassword" element={<CambioPassword />} />
        <Route path="passwordset" element={<PasswordSet />} />
        <Route path="login" element={<Login />} />
        <Route path="/doc-render/:nif/hoja-visita" element={<HojaVisitaRender />} />
        <Route path="/doc-render/:nif/evaluacion-riesgos" element={<EvaluacionRiesgosRender />} />
        <Route path="/doc-render/:nif/plan-prevencion" element={<PlanPrevencionRender />} />
        <Route path="/doc-render/:nif/plan-emergencia" element={<PlanEmergenciaRender />} />
        <Route path="/doc-render/:nif/:nifCentroTrabajo/hoja-visita" element={<HojaVisitaRenderCentroTrabajo />} />
        <Route path="/doc-render/:nif/:nifCentroTrabajo/evaluacion-riesgos" element={<EvaluacionRiesgosRenderCentroTrabajo />} />
        <Route path="/doc-render/:nif/:nifCentroTrabajo/plan-prevencion" element={<PlanPrevencionRenderCentroTrabajo />} />
        <Route path="/doc-render/:nif/:nifCentroTrabajo/plan-emergencia" element={<PlanEmergenciaRenderCentroTrabajo />} />
        <Route path="/doc-render/:nif/situacion-proveedores" element={<InformeSituacionProveedores />} />
        <Route path="/doc-render/:nif/situacion-comunidades" element={<InformeSituacionComunidades />} />
        <Route path="/doc-render/:nif/situacion-riesgos" element={<InformeSituacionRiesgos />} />
        <Route path="/doc-render/:nif/situacion-comunidades-pdf" element={<InformeSituacionComunidadesPDF />} />
        <Route path="/doc-render/:nif/situacion-proveedores-pdf" element={<InformeSituacionProveedoresPDF />} />
        <Route path="/doc-render/:nif/situacion-riesgos-pdf" element={<InformeSituacionRiesgosPDF />} />
        <Route path="/doc-render/situacion-riesgos/:nif" element={<Comunidad />} />
        <Route path="/comunidades/:nifFinca/planEmergencia" element={<PlanEmergenciaPDF />} />
        <Route path="/comunidades/:nifFinca/evaluacion-riesgos" element={<EvaluacionRiesgosPDF />} />
        <Route path="/comunidades/:nifFinca/hoja-visita" element={<HojaVisitaPDF />} />
        <Route path="/comunidades/:nifFinca/:nifCentroTrabajo/hoja-visita" element={<HojaVisitaPDFCentroTrabajo />} />
        <Route path="/comunidades/:nifFinca/:nifCentroTrabajo/planEmergencia" element={<PlanEmergenciaPDFCentroTrabajo />} />
        <Route path="/comunidades/:nifFinca/:nifCentroTrabajo/evaluacion-riesgos" element={<EvaluacionRiesgosPDFCentroTrabajo />} />
        <ProtectedRoute path="/*" element={App} />

      </Routes>
    </Router>
    <ToastContainer
      position="top-right"
      autoClose={7000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover />
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
