import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../../providers/firebase";
import Add from "../../../icons/add.svg"
import HeaderBackground from "../../../images/banner_administradores.jpg"
import Lupa from "../../../icons/lupa.svg"

class Consultores extends React.Component {
  constructor() {
    super()
    this.state = {
      consultores: []
    }
  }
  componentDidMount() {
    firestore.collection("consultores").get().then(snapshot => {
      let consultores = []
      snapshot.docs.forEach(consultor => {
        consultores.push(consultor.data())
      })
      this.setState({ consultores: consultores })
    })
  }

  render() {
    if (this.state.consultores === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader"  >Consultores</div>
        <div className="pageContent tableContainer">
          <div className="contentBar">
            <div></div>
            <Link to="/consultores/nuevo"><div className="contentButton"><img src={Add} alt="Más" />Nuevo Consultor</div></Link>
          </div>
          <div className="tableWrapper">
            <table>
              <thead>
                <tr>
                  <th>Consultor</th>
                  <th>NIF</th>
                  <th style={{ textAlign: "center" }}>Administradores Fincas</th>
                </tr>
              </thead>
              <tbody>
                {this.state.consultores.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{e.nombre + " " + e.apellidos}</td>
                      <td>{e.nif}</td>
                      <td style={{ textAlign: "center" }}><Link to={"/consultores/" + e.nif + "/aaff"}><img src={Lupa} alt="Administradores de Fincas" className="tableIcon"></img></Link></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

export default Consultores;

