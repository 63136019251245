import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage"
import "firebase/functions"

firebase.initializeApp({
  apiKey: "AIzaSyBPni8j26Zg6gkNf4sqYi0xoFV9VcatHHo",
  authDomain: "servicios-comunidades.firebaseapp.com",
  databaseURL: "https://servicios-comunidades.firebaseio.com",
  projectId: "servicios-comunidades",
  storageBucket: "servicios-comunidades.appspot.com",
  messagingSenderId: "384417890396",
  appId: "1:384417890396:web:943771d1afa9bf6be9e699",
});

export const auth = firebase.auth()
export const firestore = firebase.firestore()
export const storage = firebase.storage()
export const functions = firebase.app().functions('europe-west1')