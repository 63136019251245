import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../../providers/firebase";
import Add from "../../../icons/add.svg"
import HeaderBackground from "../../../images/banner_administradores.jpg"
import Lupa from "../../../icons/lupa.svg"
import ExportarListaTecnicos from './ExportarListaTecnicos'
import Excel from "../../../icons/excel.svg"

class Tecnicos extends React.Component {
  constructor() {
    super()
    this.state = {
      tecnicos: [],
      show: false
    }
  }
  componentDidMount() {
    firestore.collection("tecnicos").get().then(snapshot => {
      let tecnicos = []
      snapshot.docs.forEach(tecnico => {
        if(tecnico.data().status){
          tecnicos.push(tecnico.data())
        }
      })
      this.setState({ tecnicos: tecnicos })
    })
    //this.tecFincas()
  }

  /* tecFincas(){
    let promises = []
    let promises1 = []
    firestore.collection('tecnicos').get().then(tec => {
      tec.docs.forEach(t => {
        let data = t.data()
        let nfincas = 0 
        firestore.collection('hojasVisita').where('tecnico.nif', '==', data.nif).get().then(hv => {
          data.fVisitadas = hv.docs.length
        })
        firestore.collection('aaff').where('nifTecnico', '==', data.nif).get().then(aaff => {
          aaff.docs.forEach(a => {
            promises1.push(firestore.collection('fincas').where('nifAAFF', '==', a.data().nif).get().then(finca => {
              nfincas = nfincas + finca.docs.length
              data.nfincas = nfincas
              
            }))
          })
          Promise.all(promises1).then(() => {
            data.porcentaje = (data.fVisitadas/data.nfincas)*100
            console.log(data)
          })
        })
      })
    })
  } */

  // tecnicosP(t){
  //     let promises = []
  //     let tec = [];
  //     let hojasVisita = []
  //     let promises2 = []
  //     firestore.collection('hojasVisita').get().then(hv => {
  //         hv.docs.forEach( h => {
  //           promises2.push(hojasVisita.push(h.data()))
  //         })
  //         /* fVisitadas = hv.docs.length
  //         console.log(hv.docs.length) */
  //         console.log(hojasVisita)
          
  //         Promise.all(promises2).then(() => {
  //           console.log(hojasVisita)
  //           t.forEach((e, i) => {
  //             let fVisitadas = 0
  //             //console.log(hojasVisita.comunidad.nif)
  //             hojasVisita.forEach( hv => {
  //               if(hv.tecnico?.nif === e.nif){
  //                 fVisitadas++
  //               }
  //             })
  //             firestore.collection("aaff").where('nifTecnico', '==', e.nif).get().then(snapshot => {
  //                 //console.log(e.nif, snapshot.size)
  //                 let nFincas = 0
  //                 snapshot.docs.forEach(aaff => {
  //                     promises.push(firestore.collection("fincas").where('nifAAFF', '==', aaff.data().nif).get().then(finca => {
  //                         nFincas = nFincas +finca.size
                          
  //                     }))
  //                 })
  //                 Promise.all(promises).then(() => {
  //                   console.log(e.nif, fVisitadas, nFincas)
  //                   console.log(e.nif, tec)
  //                   let promises1 = []
  //                   let porcentaje = fVisitadas === 0 && nFincas === 0 ? 0 : Math.round(((fVisitadas/nFincas)*100 + Number.EPSILON) * 100) / 100
  //                   let pendientes = nFincas-fVisitadas
  //                   let estimacion = Math.round(((pendientes/160) + Number.EPSILON) * 100) / 100
  //                   const data = {
  //                       nombreTecnico:e.nombre+" "+e.apellidos,
  //                       nif: e.nif,
  //                       porcentajeVisitado: porcentaje,
  //                       pendientes: pendientes,
  //                       estimacion: estimacion
  //                   }
  //                   //console.log(data)
  //                   promises1.push(tec.push(data))
  //                   Promise.all(promises1).then(() => {
  //                       //console.log(tec)
  //                       this.setState({tecnicos:tec, activar:true})
  //                   })
  //                 })
  //             })
              
  //           })
  //         })
  //     })
      
  // }

  render() {
    if (this.state.tecnicos === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader"  >Técnicos</div>
        <div className="pageContent tableContainer">
          <div className="contentBar">
            <div >
                {this.state.show ?
                  <ExportarListaTecnicos tecnicos={this.state.tecnicos} />
                :
                  <button className='bigBtn' onClick={() => this.setState({show:true})}><img src={Excel} alt="Generar listado" width="30px"/> Generar listado</button>
                }
              </div>
            <Link to="/tecnicos/nuevo"><div className="contentButton"><img src={Add} alt="Más" />Nuevo Técnico</div></Link>
          </div>
          <div className="tableWrapper">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Técnico</th>
                  <th>NIF</th>
                  <th>Zona</th>
                  <th style={{ textAlign: "center" }}>Administradores Fincas</th>
                </tr>
              </thead>
              <tbody>
                {this.state.tecnicos.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{i+1}</td>
                      <td>{e.nombre + " " + e.apellidos}</td>
                      <td>{e.nif}</td>
                      <td>{e.zona}</td>
                      <td style={{ textAlign: "center" }}><Link to={"/tecnicos/" + e.nif + "/aaff"}><img src={Lupa} alt="Administradores de Fincas" className="tableIcon"></img></Link></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

export default Tecnicos;

