import React from "react"
import { Routes, Route, Link } from "react-router-dom";
import TecnicoIcon from "../icons/tecnico.svg";

import Inicio from "./pages/tecAdmin/Inicio";
import Tecnicos from "./pages/tecAdmin/Tecnicos";
import AAFFTecnico from "./pages/tecAdmin/AAFFTecnico";
import HojasVisita from "./pages/tecAdmin/HojasVisita";

export function RouteBundle() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="/tecnicos" element={<Tecnicos />} />
      <Route path="/tecnicos/:nif/aaff" element={<AAFFTecnico />} />
      <Route path="/tecnicos/:nifTecnico/aaff/:nifAAFF/hojas-visita" element={<HojasVisita />} />
    </Routes>
  )
}

export function MenuLinks() {
  return (
    <>
      <Link to="/tecnicos">
        <div className="menuBtn">
          <img src={TecnicoIcon} alt="Técnicos" />
          Técnicos
        </div>
      </Link>
    </>
  )
}