import React, { useState, useEffect } from "react";
import Portada from "../../../images/PortadaRiesgos.png"
import Encabezado from "../../../images/EncabezadoRiesgos.png"
import Logo from "../../../images/Logo.png"
import LogoTSP from "../../../images/logo_tsp.png"
import salida from "../../../images/salida.png"
import lucesEmergencia from "../../../images/lucesEmergencia.png"
import salidaEmergencia from "../../../images/salidaEmergencia.png"
import salidaEmergencia1 from "../../../images/salidaEmergencia1.png"
import extintor from "../../../images/extintor.png"
import ascensor from "../../../images/ascensor.png"
import peligro from "../../../images/peligro.png"
import { firestore } from "../../../providers/firebase"
import { withRouter } from "../../../providers/withRouter";
import CircularProgress from '@material-ui/core/CircularProgress';
// import { BrowserRouter as Router, Link } from "react-router-dom";
import { PDFViewer, Document, View, Page, Text, Image, StyleSheet, Link } from "@react-pdf/renderer"

const InformeSituacionRiesgos = (props) => {

    const [fincas, setFincas] = useState([])
    const [fincasHV, setFincasHV] = useState([])
    const [aaff, setAAFFS] = useState([])
    const [numP, setNumP] = useState({})
    const [numPCt, setNumPCt] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        firestore.collection("aaff").doc(props.params.nif).get().then(snapshot => {
            let aaffs = snapshot.data()
            let promises = []
            promises.push(firestore.collection("tecnicos").doc(snapshot.data().nifTecnico).get().then( tecnico => {
                aaffs.tecnico = tecnico.data().nombre+ " "+tecnico.data().apellidos
            }))
            promises.push(firestore.collection("fincas").where('nifAAFF', '==', snapshot.data().nif).get().then( snapshot => {
                let fincas = []
                snapshot.docs.forEach(finca => fincas.push(finca.data()))
                setFincas(fincas)
            }))
            promises.push(firestore.collection("hojasVisita").where('aaff.nif', '==', snapshot.data().nif).get().then( snapshot => {
                let fincas = []
                let n1_1 = 0
                let n1_2 = 0
                let n1_4 = 0
                let n1_5 = 0
                let n1_6 = 0
                let n1_7 = 0
                let n1_8 = 0
                let n2_1 = 0
                let n2_2 = 0
                let n2_3 = 0
                let n2_4 = 0
                let n2_5 = 0
                let n2_6 = 0
                let n2_7 = 0
                let n2_8 = 0
                let n2_9 = 0
                let n2_10 = 0
                let n3_1 = 0
                let n3_2 = 0
                let n3_3 = 0
                let n4_1 = 0
                let n4_2 = 0
                let n4_3 = 0
                let n4_4 = 0
                let n4_6 = 0
                let n4_7 = 0
                let n4_8 = 0
                let n4_9 = 0
                let n4_10 = 0
                let n4_11 = 0
                let n4_12 = 0
                let n4_13 = 0
                let n4_14 = 0
                let n4_15 = 0
                let n5_1 = 0
                let n6_1 = 0
                let n6_2 = 0
                let n6_3 = 0
                let n10_0 = 0
                let n10_1 = 0
                let n10_2 = 0
                let n10_3 = 0
                let n10_4 = 0
                let n11_0 = 0
                let n11_1 = 0
                let n11_2 = 0
                let n12_0 = 0
                let n12_1 = 0
                let n12_2 = 0
                snapshot.docs.forEach(finca => {
                    fincas.push(finca.data())
                    if(finca.data().centrosTrabajo?.length === 0 || finca.data().centrosTrabajo == undefined){
                        if(finca.data().p1_1 === 's') {n1_1++}
                        if(finca.data().p1_2 === 's') {n1_2++}
                        if(finca.data().p1_4 === 's') {n1_4++}
                        if(finca.data().p1_5 === 's') {n1_5++}
                        if(finca.data().p1_6 === 's') {n1_6++}
                        if(finca.data().p1_7 === 's') {n1_7++}
                        if(finca.data().p1_8 === 'n') {n1_8++}
                        if(finca.data().p2_1 === 'n') {n2_1++}
                        if(finca.data().p2_2 === 'n') {n2_2++}
                        if(finca.data().p2_3 === 'n') {n2_3++}
                        if(finca.data().p2_4 === 'n') {n2_4++}
                        if(finca.data().p2_5 === 'n') {n2_5++}
                        if(finca.data().p2_6 === 'n') {n2_6++}
                        if(finca.data().p2_7 === 'n') {n2_7++}
                        if(finca.data().p2_8 === 'n') {n2_8++}
                        if(finca.data().p2_9 === 'n') {n2_9++}
                        if(finca.data().p2_10 === 'n') {n2_10++}
                        if(finca.data().p3_1 === 'n') {n3_1++}
                        if(finca.data().p3_2 === 'n') {n3_2++}
                        if(finca.data().p3_3 === 'n') {n3_3++}
                        if(finca.data().p4_1 === 'n') {n4_1++}
                        if(finca.data().p4_2 === 'n') {n4_2++}
                        if(finca.data().p4_3 === 'n') {n4_3++}
                        if(finca.data().p4_4 === 'n') {n4_4++}
                        if(finca.data().p4_6 === 'n') {n4_6++}
                        if(finca.data().p4_7 === 'n') {n4_7++}
                        if(finca.data().p4_8 === 'n') {n4_8++}
                        if(finca.data().p4_9 === 'n') {n4_9++}
                        if(finca.data().p4_10 === 'n') {n4_10++}
                        if(finca.data().p4_11 === 'n') {n4_11++}
                        if(finca.data().p4_12 === 'n') {n4_12++}
                        if(finca.data().p4_13 === 'n') {n4_13++}
                        if(finca.data().p4_14 === 'n') {n4_14++}
                        if(finca.data().p4_15 === 'n') {n4_15++}
                        if(finca.data().p5_1 === 's') {n5_1++}
                        if(finca.data().p6_1 === 's') {n6_1++}
                        if(finca.data().p6_2 === 'n') {n6_2++}
                        if(finca.data().p6_3 === 'n') {n6_3++}
                        if(finca.data().p10_0 === 'n') {n10_0++}
                        if(finca.data().p10_1 === 'n') {n10_1++}
                        // if(finca.data().p10_2 === 'n') {n10_2++}
                        if(finca.data().p10_3 === 'n') {n10_3++}
                        if(finca.data().p10_4 === 'n') {n10_4++}
                        if(finca.data().p11_0 === 'n') {n11_0++}
                        if(finca.data().p11_1 === 'n') {n11_1++}
                        if(finca.data().p11_2 === 'n') {n11_2++}
                        if(finca.data().p12_0 === 'n') {n12_0++}
                        if(finca.data().p12_1 === 'n') {n12_1++}
                        if(finca.data().p12_2 === 'n') {n12_2++}
                    }
                    //comprobar si tiene centro de trabajo y en caso afirmativo ver que preguntas tiene cada uno y sumarle 1 si corresponde
                    if(finca.data().centrosTrabajo?.length > 0){
                        let nc1_1 = 0
                        let nc1_2 = 0
                        let nc1_4 = 0
                        let nc1_5 = 0
                        let nc1_6 = 0
                        let nc1_7 = 0
                        let nc1_8 = 0
                        let nc2_1 = 0
                        let nc2_2 = 0
                        let nc2_3 = 0
                        let nc2_4 = 0
                        let nc2_5 = 0
                        let nc2_6 = 0
                        let nc2_7 = 0
                        let nc2_8 = 0
                        let nc2_9 = 0
                        let nc2_10 = 0
                        let nc3_1 = 0
                        let nc3_2 = 0
                        let nc3_3 = 0
                        let nc4_1 = 0
                        let nc4_2 = 0
                        let nc4_3 = 0
                        let nc4_4 = 0
                        let nc4_6 = 0
                        let nc4_7 = 0
                        let nc4_8 = 0
                        let nc4_9 = 0
                        let nc4_10 = 0
                        let nc4_11 = 0
                        let nc4_12 = 0
                        let nc4_13 = 0
                        let nc4_14 = 0
                        let nc4_15 = 0
                        let nc5_1 = 0
                        let nc6_1 = 0
                        let nc6_2 = 0
                        let nc6_3 = 0
                        let nc10_0 = 0
                        let nc10_1 = 0
                        let nc10_2 = 0
                        let nc10_3 = 0
                        let nc10_4 = 0
                        let nc11_0 = 0
                        let nc11_1 = 0
                        let nc11_2 = 0
                        let nc12_0 = 0
                        let nc12_1 = 0
                        let nc12_2 = 0
                        // console.log(finca.data().centrosTrabajo?.length)
                        finca.data().centrosTrabajo.forEach(c => {
                            // console.log(c)
                            if(c.p1_1 === 's') {nc1_1++}
                            if(c.p1_1 === 's') {nc1_2++}
                            if(c.p1_4 === 's') {nc1_4++}
                            if(c.p1_5 === 's') {nc1_5++}
                            if(c.p1_6 === 's') {nc1_6++}
                            if(c.p1_7 === 's') {nc1_7++}
                            if(c.p1_8 === 'n') {nc1_8++}
                            if(c.p2_1 === 'n') {nc2_1++}
                            if(c.p2_2 === 'n') {nc2_2++}
                            if(c.p2_3 === 'n') {nc2_3++}
                            if(c.p2_4 === 'n') {nc2_4++}
                            if(c.p2_5 === 'n') {nc2_5++}
                            if(c.p2_6 === 'n') {nc2_6++}
                            if(c.p2_7 === 'n') {nc2_7++}
                            if(c.p2_8 === 'n') {nc2_8++}
                            if(c.p2_9 === 'n') {nc2_9++}
                            if(c.p2_10 === 'n') {nc2_10++}
                            if(c.p3_1 === 'n') {nc3_1++}
                            if(c.p3_2 === 'n') {nc3_2++}
                            if(c.p3_3 === 'n') {nc3_3++}
                            if(c.p4_1 === 'n') {nc4_1++}
                            if(c.p4_2 === 'n') {nc4_2++}
                            if(c.p4_3 === 'n') {nc4_3++}
                            if(c.p4_4 === 'n') {nc4_4++}
                            if(c.p4_6 === 'n') {nc4_6++}
                            if(c.p4_7 === 'n') {nc4_7++}
                            if(c.p4_8 === 'n') {nc4_8++}
                            if(c.p4_9 === 'n') {nc4_9++}
                            if(c.p4_10 === 'n') {nc4_10++}
                            if(c.p4_11 === 'n') {nc4_11++}
                            if(c.p4_12 === 'n') {nc4_12++}
                            if(c.p4_13 === 'n') {nc4_13++}
                            if(c.p4_14 === 'n') {nc4_14++}
                            if(c.p4_15 === 'n') {nc4_15++}
                            if(c.p5_1 === 's') {nc5_1++}
                            if(c.p6_1 === 's') {nc6_1++}
                            if(c.p6_2 === 'n') {nc6_2++}
                            if(c.p6_3 === 'n') {nc6_3++}
                            if(c.p10_0 === 'n') {nc10_0++}
                            if(c.p10_1 === 'n') {nc10_1++}
                            // if(c.p10_2 === 'n') {nc10_2++}
                            if(c.p10_3 === 'n') {nc10_3++}
                            if(c.p10_4 === 'n') {nc10_4++}
                            if(c.p11_0 === 'n') {nc11_0++}
                            if(c.p11_1 === 'n') {nc11_1++}
                            if(c.p11_2 === 'n') {nc11_2++}
                            if(c.p12_0 === 'n') {nc12_0++}
                            if(c.p12_1 === 'n') {nc12_1++}
                            if(c.p12_2 === 'n') {nc12_2++}
                        })
                        setNumPCt({ 
                            numCT1_1: nc1_1, numCT1_2: nc1_2, numCT1_4: nc1_4, numCT1_5: nc1_5, numCT1_6: nc1_6, numCT1_7: nc1_7, numCT1_8: nc1_8,
                            numCT2_1: nc2_1, numCT2_2: nc2_2, numCT2_3: nc2_3, numCT2_4: nc2_4, numCT2_5: nc2_5, numCT2_6: nc2_6, numCT2_7: nc2_7, numCT2_8: nc2_8,  numCT2_9: nc2_9,  numCT2_10: nc2_10,
                            numCT3_1: nc3_1, numCT3_2: nc3_2, numCT3_3: nc3_3,
                            numCT4_1: nc4_1, numCT4_2: nc4_2, numCT4_3: nc4_3, numCT4_4: nc4_4, numCT4_6: nc4_6, numCT4_7: nc4_7, numCT4_8: nc4_8,  numCT4_9: nc4_9,  numCT4_10: nc4_10,  numCT4_11: nc4_11,  numCT4_12: nc4_12,  numCT4_13: nc4_13,  numCT4_14: nc4_14,  numCT4_15: nc4_15,
                            numCT5_1: nc5_1,
                            numCT6_1: nc6_1, numCT6_2: nc6_2, numCT6_3: nc6_3,
                            numCT10_0: nc10_0, numCT10_1: nc10_1, numCT10_2: nc10_2, numCT10_3: nc10_3, numCT10_4: nc10_4,
                            numCT11_0: nc11_0, numCT11_1: nc11_1, numCT11_2: nc11_2, 
                            numCT12_0: nc12_0, numCT12_1: nc12_1, numCT12_2: nc12_2, 
    
                         })
                    }
                })
                // console.log(fincas)
                setNumP({ 
                    num1_1: n1_1, num1_2: n1_2, num1_4: n1_4, num1_5: n1_5, num1_6: n1_6, num1_7: n1_7, num1_8: n1_8,
                    num2_1: n2_1, num2_2: n2_2, num2_3: n2_3, num2_4: n2_4, num2_5: n2_5, num2_6: n2_6, num2_7: n2_7, num2_8: n2_8,  num2_9: n2_9,  num2_10: n2_10,
                    num3_1: n3_1, num3_2: n3_2, num3_3: n3_3,
                    num4_1: n4_1, num4_2: n4_2, num4_3: n4_3, num4_4: n4_4, num4_6: n4_6, num4_7: n4_7, num4_8: n4_8,  num4_9: n4_9,  num4_10: n4_10, num4_11: n4_11, num4_12: n4_12, num4_13: n4_13, num4_14: n4_14, num4_15: n4_15,
                    num5_1: n5_1,
                    num6_1: n6_1, num6_2: n6_2, num6_3: n6_3,
                    num10_0: n10_0, num10_1: n10_1, num10_2: n10_2, num10_3: n10_3, num10_4: n10_4,
                    num11_0: n11_0, num11_1: n11_1, num11_2: n11_2, 
                    num12_0: n12_0, num12_1: n12_1, num12_2: n12_2,
                })
                setFincasHV(fincas)
            }))
            Promise.all(promises).then(() => {
    
                aaffs.fechaInforme = new Date().toLocaleDateString()
                setAAFFS( aaffs, () => {
                  var src = document.getElementById('portada').style.backgroundImage
                  var url = src.match(/\((.*?)\)/)[1].replace(/('|")/g, '');
          
                  var img = new Image();
                  img.onload = function () {
                    window.print()
                  }
                  img.src = url;
                  if (img.complete) img.onload();
                })
            })
            setIsLoading(false)
          })
    },[])
    const styles = StyleSheet.create({
        imgPrincipal:{
            height:'400px', 
            width:'100%', 
            marginTop:30
        },
        body: {
            //paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: '0cm',
        },
        table: { 
            display: "table", 
            width: "80%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderRightWidth: 0, 
            borderBottomWidth: 0, 
            margin:'auto'
            // padding:'25px 0'
        }, 
        tableRiesgos: { 
            display: "table", 
            width: "auto", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderRightWidth: 0, 
            borderBottomWidth: 0, 
            fontStyle: 'bold',
            // padding:'25px 0'
        }, 
        tableRowHeader: { 
            marginTop:"30px",
            margin: "auto", 
            flexDirection: "row" 
        }, 
        tableRow: { 
            margin: "auto", 
            flexDirection: "row" 
        }, 
        tableColHeader: { 
            width: "100%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize: 14 
        }, 
        /* tableColHeader1: { 
            width: "30%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize: 14 
        }, */ 
        tableCol: { 
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'left',
            alignItems:'left'
        }, 
        /* tableCol1: { 
            width: "30%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        },  */
        tableCell: { 
            margin: "auto", 
            marginTop: 5, 
            marginBottom: 5, 
            marginLeft:2,
            marginRight: 2,
            fontSize: 8 
        },
        tableCellR: { 
            margin: "auto", 
            marginTop: 5, 
            marginBottom: 5, 
            marginLeft:2,
            marginRight: 2,
            fontSize: 6 
        },
        tableColHeaderRiesgos1: { 
            width: "15%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize: 14 
        }, 
        tableColHeaderRiesgos2: { 
            width: "10%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize: 14 
        }, 
        tableColHeaderRiesgos3: { 
            width: "30%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize: 14 
        }, 
        tableColHeaderRiesgos4: { 
            width: "45%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize: 14 
        }, 
        tableColRiesgos1: { 
            width: "15%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        }, 
        tableColRiesgos2: { 
            width: "10%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        }, 
        tableColRiesgos3: { 
            width: "30%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        }, 
        tableColRiesgos4: { 
            width: "45%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        }, 
        tableCellRiesgos: { 
            margin: "auto", 
            marginTop: 5, 
            marginBottom: 5, 
            marginLeft:2,
            marginRight: 2,
            fontSize: 6 
        },
        tableCellRiesgos1: { 
            margin: "auto", 
            marginTop: 5, 
            marginBottom: 5, 
            marginLeft:2,
            marginRight: 2,
            fontSize: 5 
        },
        pdfHeader: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'fixed',
            top: 0,
            backgroundColor: '#ffffff',
            /* margin:'2cm', */
        },
        pdfHeaderImg:{
            width:'100%', 
            height:'60px',
            marginBottom:'30px',
        },
        tablePage: {
            width: '100%',
        },
        headerSpace: {
            height: '90px',
        },
        div: {
            fontSize: '10px'
        },
        h1: {
            /* marginLeft:'2cm',
            marginRight:'2cm', 
            marginTop: '50px',*/
            marginBottom: '30px',
            fontSize: '14px',
            fontWeight: '600',
        },
         h2: {
            fontSize: '11px',
            fontWeight: '600',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
            marginTop: '30px',
            marginBottom: '30px',
        },
        h3: {
            fontSize: '9px',
            width: '100%',
            padding: '5px 10px',
            // backgroundColor: '#f8f8f8',
            textTransform: 'uppercase',
            // border: '1px solid #e6e6e6',
            // marginTop: '25px',
            // marginBottom: '15px',
            fontWeight:'bold',
            /* marginLeft:'2cm',
            marginRight:'20cm', */
            textDecoration: 'underline'
        },
        h4: {
            color: '#ab2a3e',
            marginBottom: '10px',
            marginTop: '20px',
            fontSize: '9px',
            fontWeight:'bold',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
        },
        h5: {
            fontSize: '10px',
            marginTop: '15px',
            marginBottom: '5px',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
        },
        /* tableRiesgos: {
            borderCollapse: 'collapse',
            width: '100%',
            tableLayout: 'fixed',
            marginBottom: '24px',
            flexDirection: 'row',
            borderBottomColor: '#bff0fd',
            backgroundColor: '#bff0fd',
            borderBottomWidth: 1,
            alignItems: 'center',
            textAlign: 'center',
            fontStyle: 'bold',
            flexGrow: 1,
        }, */
        simpleTable: {
            borderCollapse: 'collapse',
            width: '80%',
            margin: '0 10% 24px 10%',
        },
        simpleTableTh: {
            textTransform: 'uppercase',
        },
        simpleTableTd: {
            border: '1px solid #000000',
            padding: '5px',
            lineHeight: '1.1'
        },
        ul: {
            // lineHeight: '1.8',
            // fontSize:'7px',
            flexDirection: "column", 
            width: 150,
        },
        ol: {
            lineHeight: '1.8',
            flexDirection: "row", 
            marginBottom: 4,
            fontSize: '7px',
        },
        p: {
            padding: '5px 10px',
            lineHeight: '1.8',
            textAlign: 'justify',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
            fontSize: '7px',
        },
        imgP:{
            width:'100px', 
            marginBottom:'30px',
        },
        subp: {
            lineHeight: '1.8',
            textAlign: 'justify',
            marginLeft: '8px',
            fontSize: '8px',
        },
        br: {
            display: 'block',
            margin: '10px 0 2px 0',
            lineHeight: '5px',
            content: " ",
        },
        brText: {
            display: 'block',
            margin: '2px 0 2px 0',
            lineHeight: '5px',
            content: " ",
        },
        footerSpace: {
            height: 50
        },
        espacio: {
            display:'block',
            marginBottom: '5px'
        },
        page: {
            size: 'A4 portrait',
            margin: '2cm',
        },
        pagebreak: {
            clear: 'both',
            pageBreakAfter: 'always',
        }
    });

    const itemsPerPage = 23; // Número de elementos por página
    const pages = Math.ceil(fincas.length / itemsPerPage); // Calcular el número total de páginas
  
    const getItemsForPage = (pageIndex) => {
      const startIndex = pageIndex * itemsPerPage;
      return fincas.slice(startIndex, startIndex + itemsPerPage);
    };

    const handleLinkClick = () => {
        console.log("aaaaaaaa")
    // Simula la acción de un enlace al hacer clic
    window.open('https://www.example.com', '_blank');
    };

    return ( 
        <>
        {console.log(numP)}
        {console.log(numPCt)}
            {isLoading ? <CircularProgress style={{color:'#848474', position: 'absolute', top: '50%', left: '50%'}} /> :
            <PDFViewer style={{ width:"100%", height:"100vh"}}>
                <Document size="A4" margin="2cm">
                <Page>
                    <View>
                        <Image src={Portada} alt="Portada" style={{height:"100vh"}}/>
                    </View>
                </Page>
                <Page style={styles.body}>
                    <View style={{marginTop: 0, marginHorizontal: '0cm'}} fixed>
                        <View >
                            <Image src={Encabezado} style={{marginHorizontal:'auto', marginTop: 20, width:'80%', float:'left', display:'inline'}} />
                            {/* <Image src={Logo} style={{marginTop: 20, width:'20%', float:'left', display:'inline'}} />
                            <Image src={LogoTSP} style={{marginTop: 20, width:'10%', float:'left', display:'inline'}} /> */}
                        </View>
                    </View>
                    <View style={{ marginHorizontal: '1cm' }}>
                        <Text style={styles.p}>
                            A continuación se detalla el resumen de los diferentes riesgos identificados en las comunidades de propietarios
                        </Text>
                        <Text style={styles.br}></Text>
                        <View style={styles.table}>
                            <View style={styles.tableRowHeader}> 
                                <View style={styles.tableColHeader}> 
                                    <Text style={styles.tableCell}>INFORME DE RIESGOS</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>Administrador de fincas:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.razonSocial}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>CIF:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.nif}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>Localidad:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.municipio}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>Teléfono:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.telefono}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>E-Mail:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.email}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>Técnico:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.tecnico}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>Informe:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{aaff.fechaInforme}</Text> 
                                </View> 
                            </View>
                            <View style={styles.tableRow}> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>Número de comunidades:</Text> 
                                </View> 
                                <View style={styles.tableCol}> 
                                    <Text style={styles.tableCell}>{fincas.length}</Text> 
                                </View> 
                            </View>
                        </View>
                        <Text style={styles.br}></Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.h3}>
                            Objeto
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.p}>
                            El informe de riesgos tiene por objeto proponer medidas en base a los riesgos identificados en las comunidades de propietarios.
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.h3}>
                            Alcance
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.p}>
                            El presente informe afecta a todas las comunidades de propietarios visitadas de {aaff.razonSocial}.
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.h3}>
                            Metodología
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.p}>
                            Para elaborar el informe se tendrán en cuenta los riesgos identificados durante la visita por parte del técnico de prevención de riesgos laborales, que propondrá medidas preventivas y/o correctoras con el fin de minimizar dichos riesgos.
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.br}></Text>
                    </View>
                    <View style={{ marginHorizontal: '1cm' }}>
                            
                        <View style={styles.tableRiesgos}>
                            <>
                                <View style={styles.tableRowHeader} fixed> 
                                    <View style={styles.tableColHeaderRiesgos1}> 
                                        <Text style={styles.tableCell}>Riesgo identificado</Text> 
                                    </View> 
                                    <View style={styles.tableColHeaderRiesgos2}> 
                                        <Text style={styles.tableCell}>Origen</Text> 
                                    </View> 
                                    <View style={styles.tableColHeaderRiesgos3}> 
                                        <Text style={styles.tableCell}>Medidas Propuestas</Text> 
                                    </View> 
                                    <View style={styles.tableColHeaderRiesgos4}> 
                                        <Text style={styles.tableCell}>Comunidades afectadas</Text> 
                                    </View> 
                                </View>
                                {
                                (numP.num1_2 > 0 || numPCt.numCT1_2 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Golpes y cortes por objetos y herramientas</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Zonas comunes</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Sustituir los cristales afectados, por otros en correcto estado</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p1_2 === 's' ? <Text style={styles.tableCellR} onClick={handleLinkClick}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p1_2 === 's' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {
                                (numP.num1_1 > 0 || numPCt.numCT1_1 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Caídas de personas a distinto nivel</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Zonas comunes</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Reparar los desperfectos encontrados en el suelo y/o paredes</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p1_1 === 's' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p1_1 === 's' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num1_4 > 0 || numPCt.numCT1_4 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Contactos eléctricos.</Text>
                                                <Text style={styles.brText}></Text> 
                                                <Text style={styles.tableCellRiesgos}>Cables sueltos o en mal estado</Text>
                                                
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Zonas comunes</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Canalizar todo el cableado de la instalación eléctrica mediante canaletas o pasacables.</Text> 
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>En especial las que crucen zonas de paso</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p1_4 === 's' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p1_4 === 's' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num1_5 > 0 || numPCt.numCT1_5 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Contactos eléctricos.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Cajas de registro abiertas</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Zonas comunes</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Realizar revisiones periódicas de la instalación eléctrica y de los equipos eléctricos por personal autorizado (también revisar el cableado, conectores, enchufes, etc.). Documentar las revisiones.</Text>
                                                <Text style={styles.brText}></Text> 
                                                <Text style={styles.tableCellRiesgos}>Cerrar y tapar con su respectiva caja y adecuadamente el cuadro eléctrico</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p1_5 === 's' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p1_5 === 's' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num1_6 > 0 || numPCt.numCT1_6 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Caídas de personas al mismo nivel</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>General</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Adecuar las rampas con tiras antideslizantes</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p1_6 === 's' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p1_6 === 's' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num1_7 > 0 || numPCt.numCT1_7 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Contactos eléctricos.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Cuadro eléctrico abierto.</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>General</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p1_7 === 's' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p1_7 === 's' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View>     
                                        </View> 
                                    </>
                                }
                                {(numP.num1_8 > 0 || numPCt.numCT1_8 > 0) && 
                                    <>
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Contactos eléctricos</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>General</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda señalizar el riesgo de contacto eléctrico en los cuadros eléctricos de las instalaciones mediante pictograma homologado.</Text> 
                                                <Text style={styles.brText}></Text>
                                                <Image style={{width:20}} src={peligro} alt="peligro riesgo eléctrico" />
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p1_8 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p1_8 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View>
                                    </>
                                }
                                {(numP.num2_1 > 0 || numPCt.numCT2_1 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Revisar periódicamente los ascensores mediante una empresa de mantenimiento y por personal autorizado.</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p2_1 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p2_1 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num2_2 > 0 || numPCt.numCT2_2 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Dotar de iluminación interior 24h. Revisarla periódicamente.</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p2_2 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p2_2 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num2_3 > 0 || numPCt.numCT2_3 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda dotar de iluminación exterior 24h. Por ejemplo; sensores de movimiento para activar luz al entrar y salir del ascensor. 
                                                    <Text style={styles.brText}></Text>
                                                    Revisarla periódicamente.
                                                </Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p2_3 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p2_3 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num2_4 >0 || numPCt.numCT2_4 >0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Dotar de alarma con conexión 24h. Revisarla periódicamente.</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p2_4 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p2_4 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num2_5 > 0 || numPCt.numCT2_5 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>La sala de máquinas deberá permanecer cerrada con acceso restringido a toda persona no autorizada.</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p2_5 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p2_5 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num2_6 > 0 || numPCt.numCT2_6 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>La sala de máquinas deberá permanecer cerrada con acceso restringido a toda persona no autorizada.</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p2_6 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p2_6 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num2_7 > 0 || numPCt.numCT2_7 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda señalizar el riesgo eléctrico.</Text> 
                                                <Text style={styles.brText}></Text>
                                                <Image style={{width:20}} src={peligro} alt="peligro riesgo eléctrico" />
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p2_7 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p2_7 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num2_8 > 0 || numPCt.numCT2_8 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda disponer de un extintor de CO² en el interior de la sala de máquinas (si no lo hay fuera), a una altura máxima sobre su punto de anclaje de 1,20 m del suelo según normativa actual.</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p2_8 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p2_8 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num2_9 > 0 || numPCt.numCT2_9 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda disponer de un extintor, preferentemente, de CO² en el exterior de la sala de máquinas.</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p2_9 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p2_9 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num2_10 > 0 || numPCt.numCT2_10 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Atrapamientos</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda la instalación de un cartel con el texto: “Cuarto de maquinaria del ascensor”.</Text> 
                                                <Text style={styles.brText}></Text>
                                                <Image style={{width:30}} src={ascensor} alt="cuarto de máquinas ascensor" />
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p2_10 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p2_10 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num2_10 > 0 || numPCt.numCT2_10 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Atrapamientos</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Ascensores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Colocar un cartel con el texto “Se prohíbe la entrada a toda persona no autorizada”.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p2_10 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p2_10 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num3_1 > 0 || numPCt.numCT3_1 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Caída de personal a distinto nivel</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Azotea</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Delimitar y señalizar la zona de acceso a la azotea para evitar el acceso de cualquier persona ajena a la actividad.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p3_1 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p3_1 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num3_2 > 0 || numPCt.numCT3_2 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Caída de personal a distinto nivel</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Azotea</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda instalar escaleras de servicio para el acceso seguro a la zona de azoteas , o valorar la instalación de un medio de acceso seguro.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p3_2 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p3_2 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num3_3 > 0 || numPCt.numCT3_3 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Caída de personal a distinto nivel</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Azotea</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Proteger el perímetro de la zona mediante barandillas. En caso de que la comunidad no tenga murete perimetral, éstas deben disponer de pasamanos, barra intermedia y rodapié.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>En caso de ser una azotea no transitable o se realicen trabajaos verticales se deberá estudiar la posibilidad de instalar línea de vida o verificar que todo el personal que acceda a la misma cuente con medidas anticaída, como por ejemplo una línea de vida provisional.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p3_3 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p3_3 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num3_3 > 0 && numP.num3_4 > 0) || (numPCt.numCT3_3 > 0 && numPCt.numCT3_4 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Caída de personas al mismo nivel</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Azotea</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda la instalación de anclajes para las líneas de vida para realizar los trabajos con mayor seguridad.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>En su defecto, se deberá verificar que el trabajador dispone de una línea de vida portátil.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p3_3 === 'n' && c.p3_4 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p3_3 === 'n'&& f.p3_4 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_1 > 0 || numPCt.numCT4_1 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda instalar extintores suficientes en las instalaciones.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>El número mínimo de extintores deberá ser el suficiente para que el recorrido real en cada planta desde cualquier origen de evacuación hasta un extintor no supere los 15 metros.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_1 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_1 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_2 > 0 || numPCt.numCT4_2 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Realizar revisiones periódicas reglamentarias de los extintores, según el Real Decreto 513/2017 por el que se aprueba el Reglamento de instalaciones de protección contra incendios.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_2 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_2 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_3 > 0 || numPCt.numCT4_3 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Señalizar los medios de extinción de incendios mediante pictograma fotoluminiscente (fondo rojo y letras blanca) instalando correctamente la señal tipo cartel indicativa del lugar de ubicación del extintor, de manera que indique claramente su ubicación.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Image style={{width:20}} src={extintor} alt="extintor" />
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_3 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_3 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_4 > 0 || numPCt.numCT4_4 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Colgar los extintores a una altura máxima sobre su punto de anclaje de 1,20 m del suelo según normativa actual (antes 1.70m).</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Recomendamos que los extintores instalados antes de la entrada en vigor del RD 513/2017 deberán de irse adaptando a la nueva normativa gradualmente.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_4 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_4 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_6 > 0 || numPCt.numCT4_6 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda señalizar los recorridos y las salidas de evacuación con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida según CTE.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Image style={{width:30}} src={salidaEmergencia1} alt="salida de emergencia" />
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_6 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_6 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_7 > 0 || numPCt.numCT4_7 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda instalar luces de emergencia sobre las puertas de salida, para dotar de suficiente visibilidad el recorrido de evacuación y las salidas de emergencia.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_7 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_7 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_8 > 0 || numPCt.numCT4_8 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Señalizar las direcciones de circulación en el interior del parking cuando éste supere los 500m2. Seguir normativa autonómica/municipal.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_8 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_8 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_9 > 0 || numPCt.numCT4_9 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Señalizar la dirección y sentido de acceso en las entradas al parking cuando se disponga de dos o más puertas.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_9 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_9 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_10 > 0 || numPCt.numCT4_10 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda dotar de sistemas automáticos de extinción de incendios (rociadores).</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Seguir normativa autonómica/municipal.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_10 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_10 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_11 > 0 || numPCt.numCT4_11 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda instalar sistemas de extracción de humos en el parking siempre que éste supere los 500m2.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Seguir normativa autonómica/municipal.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_11 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_11 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_12 > 0 || numPCt.numCT4_12 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Seguir normativa autonómica/municipal para la instalación y mantenimiento de la BIE.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_12 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_12 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_13 > 0 || numPCt.numCT4_13 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Seguir normativa autonómica/municipal para la instalación y mantenimiento de detectores de humo.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_13 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_13 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_14 > 0 || numPCt.numCT4_14 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Contactos eléctricos</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_14 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_14 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num4_15 > 0 || numPCt.numCT4_15 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Contactos eléctricos</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Parking</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Señalizar el cuadro eléctrico con “señal riesgo eléctrico”. Pictograma triangular amarillo con letras negras </Text>
                                                <Text style={styles.brText}></Text>
                                                <Image style={{width:20}} src={peligro} alt="peligro" />
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p4_15 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p4_15 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num5_1 > 0 || numPCt.numCT5_1 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Exposición a contaminantes químicos</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Jardín</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Disponer de un armario cerrado bajo llave  para guardar los productos químicos.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Se solicitarán las Fichas de datos de seguridad química, a los proveedores, de todos los productos fitosanitarios que se utilizan y se pondrán a disposición de las personas que los manipulen</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p5_1 === 's' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p5_1 === 's' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num6_1 > 0 || numPCt.numCT6_1 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Exposición a contaminantes químicos</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Piscina</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Almacenar y manipular los productos químicos según las indicaciones de las Fichas de datos de seguridad de productos químicos.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Disponer de un armario cerrado bajo llave para guardar los productos químicos. </Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Se solicitarán las Fichas de datos de seguridad química, a los proveedores, de todos los productos fitosanitarios que se utilizan y se pondrán a disposición de las personas que los manipulen.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p6_1 === 's' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p6_1 === 's' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num6_2 > 0 || numPCt.numCT6_2 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Caídas de personal a diferente nivel</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Piscina</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Durante el trabajo en las inmediaciones de la piscina deberá respetarse la señalización y el vallado de la misma.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Las piscinas en las que el acceso de niños a la zona de baño no esté controlado dispondrán de barreras de protección que impidan su acceso al vaso excepto a través de puntos previstos para ello, los cuales tendrán elementos practicables con sistemas de cierre y bloqueo. (Documento Básico SU Seguridad de Utilización Apartado Piscinas punto 1.1)</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Seguir normativa autonómica/municipal.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p6_2 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p6_2 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num6_3 > 0 || numPCt.numCT6_3 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Caídas de personal a diferente nivel</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Piscina</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Durante el trabajo en las inmediaciones de la piscina deberá respetarse la señalización y el vallado de la misma.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Las piscinas en las que el acceso de niños a la zona de baño no esté controlado dispondrán de barreras de protección que impidan su acceso al vaso excepto a través de puntos previstos para ello, los cuales tendrán elementos practicables con sistemas de cierre y bloqueo. (Documento Básico SU Seguridad de Utilización Apartado Piscinas punto 1.1)</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Seguir normativa autonómica/municipal.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p6_3 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p6_3 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num10_0 > 0 || numPCt.numCT10_0 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Extintores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Los edificios construidos o rehabilitados a partir de 1996 deberán contar con los medios de extinción necesarios y suficientes según las características del edificio de acuerdo al NBE-CPI/96,</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Para los construidos antes de ésta fecha siempre es muy recomendable su instalación</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p10_0 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p10_0 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num10_1 > 0 || numPCt.numCT10_1 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Extintores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda consultar con empresa especializada en extinción de incendios si los extintores disponibles son suficientes para las características del edificio y realizar el mantenimiento periódico de los mismos.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>Según CTE se dispondrán extintores en número suficiente para que el recorrido real en cada planta desde cualquier origen de evacuación hasta un extintor no supere los 15 m.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p10_1 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p10_1 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {/* {(numP.num10_2 > 0 || numPCt.numCT10_2 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Extintores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Realizar revisiones periódicas reglamentarias de los extintores, según el Real Decreto 513/2017 por el que se aprueba el Reglamento de instalaciones de protección contra incendios.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p10_2 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p10_2 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                } */}
                                {(numP.num10_3 > 0 || numPCt.numCT10_3 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Extintores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Señalizar los medios de extinción de incendios mediante pictograma fotoluminiscente (fondo rojo y letras blanca) instalando correctamente la señal tipo cartel indicativa del lugar de ubicación del extintor, de manera que indique claramente su ubicación.  Es muy importante que los extintores de incendios se encuentren colocados en lugares visibles y accesibles.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p10_3 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p10_3 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num10_4 > 0 || numPCt.numCT10_4 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Extintores</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Colgar los extintores a una altura máxima sobre su punto de anclaje de 1,20 m del suelo según normativa actual (antes 1.70m). Recomendamos que los extintores instalados antes de la entrada en vigor del RD 513/2017 deberán de irse adaptando a la nueva normativa gradualmente.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p10_4 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p10_4 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num11_0 > 0 || numPCt.numCT11_0 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Iluminación</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda la instalación de iluminación de emergencia tanto en recorrido de evacuación como en las salidas de la finca.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos}>La iluminación de emergencia está destinada a alertar a los usuarios de un edificio ante una situación de riesgo, e indicar los medios de evacuación. También está pensada para indicar junto con la señalización el protocolo de actuación dependiendo la situación, y la posterior evacuación.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Image style={{width:30}} src={lucesEmergencia} alt="luces de emergencia" />
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p11_0 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p11_0 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num11_1 > 0 || numPCt.numCT11_1 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Iluminación</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda equipar las vías y salidas de evacuación con iluminación de emergencia de suficiente intensidad  según Reglamento actual.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p11_1 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p11_1 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num11_2 > 0 || numPCt.numCT11_2 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Iluminación</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda equipar las salidas de la finca con iluminación de emergencia de suficiente intensidad con el fin de reducir consecuencias en caso de emergencia y ayudar a la evacuación del edificio.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p11_2 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p11_2 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num12_0 > 0 || numPCt.numCT12_0 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Señalización</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Se recomienda la instalación de señalización de emergencia tanto en recorrido de evacuación como en las salidas de la finca.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p12_0 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p12_0 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num12_1 > 0 || numPCt.numCT12_1 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Señalización</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Señalizar las salidas de la finca con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Image style={{width:30}} src={salida} alt="salida de emergencia" />
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos1}>Las salidas o salidas de emergencia constituyen una vía de escape ante cualquier accidente o situación de riesgo. Por esta razón, deben de estar señalizadas y visibles. La finalidad es poder prevenir pérdidas de vidas, evitar lesiones o proteger los bienes del edificio.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p12_1 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p12_1 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                                {(numP.num12_2 > 0 || numPCt.numCT12_2 > 0) && 
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColRiesgos1}> 
                                                <Text style={styles.tableCellRiesgos}>Incendios</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos2}> 
                                                <Text style={styles.tableCellRiesgos}>Señalización</Text> 
                                            </View> 
                                            <View style={styles.tableColRiesgos3}> 
                                                <Text style={styles.tableCellRiesgos}>Señalizar los recorridos y las salidas de evacuación con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida.</Text>
                                                <Text style={styles.brText}></Text>
                                                <Image style={{width:30}} src={salidaEmergencia} alt="salida de emergencia" />
                                                <Text style={styles.brText}></Text>
                                                <Text style={styles.tableCellRiesgos1}>La señalización de emergencia está destinada a alertar a los usuarios de un edificio ante una situación de riesgo, e indicar los medios de evacuación. También está pensada para indicar el protocolo de actuación dependiendo la situación, y la posterior evacuación.</Text>
                                            </View> 
                                            <View style={styles.tableColRiesgos4}> 
                                                {fincasHV.map(f => {
                                                    return (
                                                        f.centrosTrabajo?.length > 0 ?
                                                        f.centrosTrabajo.map(c=>{
                                                            return(
                                                                c.p12_2 === 'n' ? <Text style={styles.tableCellR}>{c.nombre}</Text>  : null 
                                                                )
                                                            })
                                                        :                                    
                                                            f.p12_2 === 'n' ? <Text style={styles.tableCellR}>{f.comunidad.direccion}</Text>  : null 
                                                    )
                                                })}
                                            </View> 
                                        </View> 
                                    </>
                                }
                            </>
                        </View>
                    </View>
                    <View style={{ marginHorizontal: '1cm' }}>
                        <Text style={styles.br}></Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.p}>
                            Agradecemos la atención prestada por el personal de {aaff.razonSocial} en la gestión de las visitas a las comunidades así como en la continua colaboración con los proveedores a los que solicitamos la documentación necesaria para cumplir la coordinación de actividades empresariales.
                        </Text>
                        <Text style={styles.br}></Text>
                        <Text style={styles.p}>
                            Quedamos a su entera disposición para aclarar cualquier duda,
                        </Text>
                        <Image alt='logo' src={Logo} style={styles.imgP} />
                    </View>
                </Page>
                
                </Document>
            </PDFViewer>
        
        }
        <style jsx>{`
            .header-space {
            height: 100px;
            background-image: url(${`'${Encabezado}'`});
            background-position: bottom;
            background-size: cover;
            margin-bottom: 50px;
            }
            .footer-space {
            height: 30px;
            }
            .portada {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-position: bottom;
            background-size: cover;
            }
            .pagina {
            margin-top: 150px;
            padding: 0px 100px;
            font-size: 10px;
            }
            p {
            line-height: 1.8;
            text-align: justify;
            }
            br {
            display: block;
            margin: 18px 0 20px 0;
            line-height: 22px;
            content: " ";
            }
        .simpleTable {
            border-collapse: collapse;
            margin-bottom: 30px;
            }
            .simpleTable th {
            text-transform: uppercase
            }
            .simpleTable th, .simpleTable td {
            border: 1px solid #000000;
            padding: 5px;
            line-height: 1.1
            }
            .simpleTable img {
            height: 25px
            }
            @page {
            size: A4 portrait;
            margin: 0;
            }
        `}</style>
        </>
     );
}

export default withRouter(InformeSituacionRiesgos);
