import { auth, firestore } from "../providers/firebase";
import React from "react";
import "./Login.css"
import "./PasswordSet.css"
import Tick from "../icons/tick.svg"
import Cross from "../icons/cross.svg"
import { toast } from "react-toastify";
import BackgroundImage from "../images/loginFondo.jpg"
import Logo from "../images/Logo.png"

class PasswordSet extends React.Component {
  constructor() {
    super()
    this.state = {
      newPassword: "",
      repeatnewPassword: "",
      validCode: undefined,
      oobCode: null,
      mode: null,
      success: false,
      primerAcceso: false,
      userId: '',
      rol: '',
      roles: { aaff: "aaff", prov: "proveedores", tec: "tecnicos", admin: "admin", admon: "administrativos", com: "comerciales", tecAdmin: "tecAdmin" }
    }
  }
  componentDidMount() {
    auth.onAuthStateChanged(user =>{
      //console.log(user.uid)
      user.getIdTokenResult().then((idToken) => {
        //console.log(this.state.roles[idToken.claims.rol])
        this.state.rol = this.state.roles[idToken.claims.rol]
      })
      this.state.userId = user.uid
    })
    const urlParams = new URLSearchParams(window.location.search);
    auth.verifyPasswordResetCode(urlParams.get("oobCode")).then(res => {
      this.setState({ validCode: true })
    }).catch(err => {
      this.setState({ validCode: false })
    })
  }
  redirect(){
    setTimeout(() => {
      return window.location.replace("/login")
    }, 2000);
  }
  changePassword(e, code) {
    e.preventDefault()
    if(this.state.newPassword === this.state.repeatnewPassword){
      //console.log(this.state.rol)
      auth.confirmPasswordReset(code, this.state.newPassword).then(res => {
        if(this.state.rol!==""){
          firestore.collection(this.state.rol).doc(this.state.userId).update({
            primerAcceso: true
          }).then(() => {
            console.log("actualizado")
      
          }).catch(err => {
            console.log(err)
          })
        }
        this.setState({ success: true })
        this.redirect()
      }).catch(err => {
        console.log(err)
        toast("Ocurrió un problema al cambiar la contraseña, inténtelo de nuevo. Si el problema persiste pongase en contacto con nosotros.")
      })
    }else{
      toast.error("Las contraseñas no son iguales")
    }
  }
  
  render() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("oobCode") === null || urlParams.get("mode") !== "resetPassword") window.location.replace("/")
    if (this.state.success) {
      return (
        <div className="successMsg">
          <img src={Tick} alt="Correcto" />
          La contraseña ha sido cambiada con éxito
        </div>
      )
    }
    if (this.state.validCode === undefined) return null //Yet loading
    if (!this.state.validCode) {
      return (
        <div className="successMsg">
          <img src={Cross} alt="Error" />
          La contraseña ya ha sido cambiada o ha expirado el tiempo para hacerlo.<br /> Vuelva a solicitar el cambio de contraseña si fuera necesario
        </div>
      )
    }
    return ( //Valid Code
      <div style={{ backgroundImage: `url(${BackgroundImage})` }} className="backgroundDiv">
        <div className="overlay"></div>
        <form className="loginForm" onSubmit={e => this.changePassword(e, urlParams.get("oobCode"))} style={{border: "1px solid #c1bdbd"}}>
          {/* <div>Cambia la contraseña</div> */}
          <div><img src={Logo} className="loginLogo" alt="Logo" /></div>
          <label style={{margin: "0 30px 20px 30px"}}>Contraseña nueva</label>
          <input type="password" required minLength="8" value={this.state.newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} />
          <label style={{margin: "0 30px 20px 30px"}}>Repetir contraseña nueva</label>
          <input type="password" required minLength="8" value={this.state.repeatnewPassword} onChange={(e) => this.setState({ repeatnewPassword: e.target.value })} />
          <button type="submit">Cambiar</button>
        </form>
      </div>
    );
  }
}

export default PasswordSet;
