import { auth } from './firebase';
import React from 'react'

import * as Admin from "../routes/Admin"
import * as AAFF from "../routes/AAFF"
import * as Tecnico from "../routes/Tecnico"
import * as TecnicoAdmin from "../routes/TecnicoAdmin"
import * as Proveedor from "../routes/Proveedor"
import * as Comercial from "../routes/Comercial"

class ProtectedRoute extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rol: null,
            user: undefined
        }
    }
    componentDidMount() {
        auth.onAuthStateChanged(userAuth => {
            this.setState({ user: userAuth })
            userAuth.getIdTokenResult().then((idToken) => {
                this.setState({ rol: idToken.claims.rol })
            })
        });
    }

    render() {
        const Component = this.props.element
        if (this.state.user === undefined) {
            //Loading auth
            return null
        }
        if (this.state.user === null) {
            //Auth loaded and not signed in
            return window.location.replace("/login")
        } else {
            //Auth loaded and signed in
            if (this.state.rol === "admin") {
                return <Component user={this.state.user} routes={<Admin.RouteBundle />} menu={<Admin.MenuLinks />} />
            }
            if (this.state.rol === "aaff") {
                return <Component user={this.state.user} routes={<AAFF.RouteBundle />} menu={<AAFF.MenuLinks />} />
            }
            if (this.state.rol === "tec") {
                return <Component user={this.state.user} routes={<Tecnico.RouteBundle />} menu={<Tecnico.MenuLinks />} />
            }
            if (this.state.rol === "prov") {
                return <Component user={this.state.user} routes={<Proveedor.RouteBundle />} menu={<Proveedor.MenuLinks />} />
            }
            if (this.state.rol === "tecAdmin") {
                return <Component user={this.state.user} routes={<TecnicoAdmin.RouteBundle />} menu={<TecnicoAdmin.MenuLinks />} />
            }
            /*if (this.state.rol === "admon") {
                return <Component user={this.state.user} routes={<Administracion/>}/>
            }*/
            if (this.state.rol === "com") {
                return <Component user={this.state.user} routes={<Comercial.RouteBundle/>} menu={<Comercial.MenuLinks />}/>
            } 
            return null //Loading
        }
    }
}

export default ProtectedRoute;