import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledAccordions(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [aaff, setAaff] = useState([]);
  
    
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
};

useEffect(() => {
  let aaff = []
  props.AAFFS.forEach( a => {
    let numFincas = 0
    props.fincas.forEach( f => {
      if(f.nifAAFF === a.nif ){
        console.log(f)
        numFincas++
      }
    })
    if(numFincas > 0 ){
      aaff.push(a)
    }
    console.log(a.nif, numFincas)
  })
  setAaff(aaff)
},[])

  return (
    <div className={classes.root} style={{margin:'15'}}>
        {aaff.map((e, i) => 
            <Accordion key={i} style={{margin:'0'}} expanded={expanded === 'panel'+i} onChange={handleChange('panel'+i)}>
            <AccordionSummary style={{background:'rgb(251 251 251)'}}
              aria-controls="panel1bh-content"
              id='panel1bh-header'
            >
              <Typography style={{flex: 'auto'}} className={classes.heading}>{e.razonSocial}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {props.fincas.map((f, j) => f.nifAAFF === e.nif ? 
                    <>
                    <span key={j} style={{margin:'10px 0'}}>{f.razonSocial} <br/>
                    <small><b>Evaluacion de riesgos:</b> {f.documentos.evaluacionRiesgos.ultLectura}</small><br/>
                    <small><b>Plan de emergencia: </b>{f.documentos.planEmergencia.ultLectura}</small><br/> 
                    <small><b>Servicio: </b>{f.servicios}</small></span><br/></>
                    : null)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
      
      
    </div>
  );
}

