import React, { useState, useEffect, useRef } from 'react'
import noVisitada from "../icons/noVisitada.svg"
import notaNV from "../icons/notaNV.svg"
import { withRouter } from "../providers/withRouter";
import { firestore, auth } from "../providers/firebase";
import Swal from 'sweetalert2';
import { Popover, Tooltip, Typography } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import firebase from "firebase";

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  customWidth: {
    maxWidth: 500,
  },
  noMaxWidth: {
    maxWidth: 'none',
  },
}));

const NoVisitado = (props) => {
// console.log(props)
  const classes = useStyles();
    // const textareaRef = useRef(null);
    const [rol, setRol] = useState('')
    const finca = props.finca
    // const initialText = finca.observacionNoVisita === undefined || finca.observacionNoVisita === '' ? new Date().toLocaleDateString()+": "+auth.currentUser.displayName+" - \n" : finca.observacionNoVisita
    const [activado, setNoActivado] = useState(false)
    const [ObsTexto, setObsTexto] = useState('')
    const [recargar, setRecargar] = useState(false)
    
    useEffect(() => {
        // console.log(finca)
        /* console.log(auth.currentUser)
        console.log(auth.currentUser.displayName) */
        auth.onAuthStateChanged(userAuth => {
            userAuth.getIdTokenResult().then((idToken) => {
                setRol(idToken.claims.rol)
            })
        });
        // console.log("AAAAAAAAAAAA")
        // console.log(initialText)
        if(props.tipo === 'finca'){
          // console.log(finca.nif, finca.noVisitada, finca.nif, props.nif)
          if(finca.noVisitada !== false  && finca.nif === props.nif){
            // console.log("FINCA NO VISITADA")
            setNoActivado(finca.noVisitada)
            setObsTexto(new Date(finca.fechaNoVisita?.seconds *1000).toLocaleDateString()+" - "+finca.observacionNoVisita)
            // console.log(finca)
          }
        }
        if(props.tipo === 'centroTrabajo'){
          finca.centrosTrabajo.forEach(ct=>{
            // console.log(ct.nif, ct.nif === props.nif && ct.noVisitada !== false, ct.noVisitada)
            if(ct.nif === props.nif && ct.noVisitada !== false){
              setNoActivado(ct.noVisitada)
              setObsTexto(new Date(ct.fechaNoVisita?.seconds *1000).toLocaleDateString()+" - "+ct.observacionNoVisita)
              // console.log(ct)
            }
          })
        }
    },[finca, recargar])
    
    /* const handleKeydown = (event) => {
      const textarea = textareaRef.current;
      if (textarea) {
        const cursorPosition = textarea.selectionStart;
        if (cursorPosition < initialText.length) {
          event.preventDefault();
        }
      }
    }; */

    const handleAdd = () => {
        console.log(finca)
        Swal.fire({
            title: 'Motivo de la no visita',
            // html: `<textarea id="motivoTexto" style="width: 373px; height: 210px; font-size:11px; padding:10px;" class="swal2-input" ></textarea>`,
            html: `<select id="motivoTexto" style="width: 373px; height: auto; font-size:14px; padding:10px;" class="swal2-input" >
                    <option>No nos dejan entrar </option>
                    <option>Garaje sin llave</option>
                    <option>Finca no localizada </option>
                    <option>Otros</option>
                   </select>`,
            confirmButtonText: 'Guardar',
            confirmButtonColor: '#05811b',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#d33',
            focusConfirm: false,
            /* didOpen: () => {
              const textarea = document.getElementById('motivoTexto');
              textarea.value = initialText;
              textarea.setSelectionRange(initialText.length, initialText.length);
              textarea.addEventListener('keydown', handleKeydown);
              textarea.focus();
      
              // Asignar el elemento textarea al useRef
              textareaRef.current = textarea;
            },
            willClose: () => {
              const textarea = textareaRef.current;
              if (textarea) {
                textarea.removeEventListener('keydown', handleKeydown);
                textareaRef.current = null; // Limpiar la referencia al elemento
              }
            }, */
            preConfirm: () => {
              const texto = Swal.getPopup().querySelector('#motivoTexto').value
              console.log(texto)
              if (!texto) {
                Swal.showValidationMessage(`insertar texto`)
              }
              return {texto: texto }
              /* const textarea = textareaRef.current;
              if (textarea) {
                const inputValue = textarea.value;
                // const texto = Swal.getPopup().querySelector('#motivoTexto').value
                if (!inputValue) {
                  Swal.showValidationMessage(`insertar texto`)
                }
              return {texto: inputValue }
              } */
            }
          }).then((result) => {
            if(result.value !== undefined){
              Swal.fire({
                icon: 'success',
                title: 'Datos guardados'
              })
              console.log(result.value.texto, finca.nif, new Date())
              console.log(new Date()+": "+auth.currentUser.displayName+" - "+result.value.texto)
              /* firestore.collection("fincas").doc(finca.nif).get().then(f => {
                console.log(f.data())
              }) */
              if(props.tipo === 'centroTrabajo'){
                console.log(props.tipo)
                let centros = finca.centrosTrabajo
                const indiceObjetoModificar = finca.centrosTrabajo.findIndex(objeto => objeto.nif === props.nif); //saca el indice del centro de trabajo en el array
                console.log(indiceObjetoModificar)
                if (indiceObjetoModificar !== -1) {

                  const objetoModificar = centros.find(objeto => objeto.nif === props.nif);
                  console.log(centros)
                  if (objetoModificar) {
                    //se le añaden estos nuevos campos al centro de trabajo concreto
                    objetoModificar.observacionNoVisita = result.value.texto;
                    objetoModificar.fechaNoVisita = new Date();
                    objetoModificar.noVisitada = true;
                  }
                    
                  

                  console.log(centros)
                  firestore.collection("fincas").doc(finca.nif).update({ 
                    centrosTrabajo: centros
                  })
                }
              }
              if(props.tipo === 'finca' ){
                console.log(props.tipo)
                console.log(finca)
                firestore.collection("fincas").doc(finca.nif).update({ 
                  observacionNoVisita: result.value.texto,
                  fechaNoVisita: new Date(),
                  noVisitada: true
                })
              }
              setRecargar(!recargar)
              setNoActivado(true)
            }
          })
    }
    const handleQuit = () => {
        console.log("B")
    }
    
    return ( 
        <>
            {rol === 'tec' && activado !== true &&
                <button type="button" onClick={handleAdd}><img src={notaNV} alt="No visitado" className="tableIcon" /></button>
            }
            {activado && 
            <ThemeProvider theme={theme}>
              <Tooltip title={ObsTexto} classes={{ tooltip: classes.customWidth }}>
                <img src={noVisitada} alt="No visitado" className="tableIcon"/>
              </Tooltip>
            </ThemeProvider>
            }
            <style jsx="true">{`
                .MuiTooltip-popper div{
                    padding:14px;
                    font-size:14px;
                    visibility:visible;
                    white-space: pre-line;
                }                 
            `}</style>
        </>
     );
}
 
export default withRouter(NoVisitado);