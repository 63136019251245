export const incidencias = {
    p1_1: "Hay riesgo de desprendimiento de baldosas o elementos",
    p1_2: "Hay cristales rotos o en mal estado de puertas/ventanas",
    p1_3: "Hay baldosas rotas o ausencia de las mismas",
    p1_4: "Hay cables eléctricos sueltos",
    p1_5: "Las cajas de registro están abiertas",
    p1_6: "Hay riesgo de deslizamiento en las rampas de acceso",
    p1_7: "El cuadro eléctrico se encuentra abierto",
    p1_8: "El cuadro eléctrico no se encuentra señalizado",
    p2_1: "Los ascensores no están revisados",
    p2_2: "Los ascensores no disponen de iluminación 24h interior",
    p2_3: "Los ascensores no disponen de iluminación 24h exterior",
    p2_4: "Los ascensores no disponen de alarma",
    p2_5: "La sala de máquinas de los ascensores no está cerrada",
    p2_6: "El motor de la sala de máquinas no se encuentra debidamente cerrado",
    p2_7: "No hay señal de riesgo eléctrico en la sala de máquinas de los ascensores",
    p2_8: "No existe extintor dentro de la sala de máquinas del ascensor.",
    p2_9: "No hay extintor en el exterior de la sala de máquinas del ascensor",
    p2_10: "No existe un cartel en las inmediaciones del cuarto de máquinas del ascensor alertando del riesgo y la prohibición de acceso a toda persona ajena al servicio",
    p3_1: "El acceso a la azotea no se realiza de forma segura",
    p3_2: "El acceso a la azotea mediante escaleras no es seguro",
    p3_3: "La azotea no dispone de protección perimetral que impida la caída de personas a diferente nivel",
    p3_4: "La azotea no dispone de anclajes para la línea de vida.",
    p4_1: "El parking no dispone de número suficiente de extintores. De acuerdo al CTE aprobado en Real Decreto 314/2006 se deben instalar cada 15 m de recorrido en cada planta, como máximo, desde todo origen de evacuación",
    p4_2: "Los extintores del parking no están correctamente revisados. De acuerdo al Real Decreto 513/2017, se deben revisar los extintores de forma anual y retimbrarlos cada 5 años por una empresa especializada.",
    p4_3: "Algunos extintores del parking no están correctamente señalizados. De acuerdo al Real Decreto 513/2017, debe estar señalizada la ubicación de los extintores.",
    p4_4: "Algunos extintores del parking no están colgados a una altura adecuada. De acuerdo al Real Decreto 513/2017, deben estar colgados a máximo 1,20m del suelo.",
    p4_5: "Algunos extintores del parking no están en buen estado de conservación",
    p4_6: "Faltan recorridos de evacuación del parking por señalizar de acuerdo al CTE.",
    p4_7: "Faltan salidas de emergencia del parking por señalizar de acuerdo al CTE.",
    p4_8: "Las direcciones de circulación del parking no están señalizadas de acuerdo al CTE.",
    p4_9: "Las puertas de entrada y/o salida del parking no están señalizadas.",
    p4_10: "El parking no dispone de sistemas automáticos de extinción de incendios (rociadores) (+500m² o 2 pisos) de acuerdo al CTE.",
    p4_11: "El parking no dispone de sistemas de extracción de humos (+500m² o 2 pisos) de acuerdo al CTE.",
    p4_12: "El parking no dispone de BIE's de acuerdo al CTE.",
    p4_13: "El parking no dispone de detectores de humo de acuerdo al CTE.",
    p4_14: "El cuadro eléctrico del parking debe estar cerrado de acuerdo al RD 842/2002 sobre instalaciones de Baja Tensión.",
    p4_15: "No está señalizado el cuadro eléctrico con el panel de 'Riesgo eléctrico' en el parking de acuerdo al RD 842/2002 sobre instalaciones de Baja Tensión.",
    p5_2: "Los productos fitosanitarios no se guardan bajo llave",
    p5_3: "No se disponen de las fichas de seguridad de los productos fitosanitarios",
    p6_2: "No se dispone de protección perimetral en la piscina",
    p6_3: "No se dispone de aros salvavidas en la piscina",
    p10_0: "No dispone de extintor de incendios. Según NBE-CPI/96, sólo es obligatorio la instalación de extintores en una comunidad de vecinos en aquellos edificios que han sido construidos o rehabilitados a partir del año 1996. Para los edificios construidos antes de 1996 será muy aconsejable disponer de extintores en cada planta para minimizar riesgos en caso de incendio.",
    p10_1: "No dispone de número suficiente de extintores en la comunidad. De acuerdo al CTE se deben instalar cada 15 m de recorrido en cada planta, como máximo, desde todo origen de evacuación.",
    p10_2: "No dispone de un extintor en todos los pisos/rellanos de acuerdo al CTE.",
    p10_3: "Los extintores de la comunidad no están correctamente revisados. De acuerdo al Real Decreto 513/2017, se deben revisar los extintores de forma anual y retimbrarlos cada 5 años por una empresa especializada.",
    p10_4: "Algunos extintores de la comunidad no están correctamente señalizados de acuerdo al CTE.",
    p10_5: "Algunos extintores de la comunidad no están colgados adecuadamente. De acuerdo al Real Decreto 513/2017, deben estar colgados a máximo 1,20m del suelo.",
    p10_6: "Los extintores de la comunidad no están en buen estado de conservación.",
    p11_0: "No se dispone de iluminación de emergencia según Código Técnico de Edificación (CTE)",
    p11_1: "Existen recorridos de evacuación que no están iluminados de acuerdo al CTE.",
    p11_2: "Existen salidas de la finca que no están iluminadas de acuerdo al CTE.",
    p12_0: "No se dispone de señalización de emergencia según Código Técnico de Edificación (CTE)",
    p12_1: "Existen salidas de las fincas que no están señalizadas de acuerdo al CTE.",
    p12_2: "Existen recorridos de evacuación que no están señalizados de acuerdo al CTE.",
}