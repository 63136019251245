import { Grid } from "@material-ui/core";
import React/* , { useEffect, useState } */ from "react";
import InicioCard from "../../../components/InicioCard";
import ComunidadesIcon from "../../../images/comunidades.png"
import ProveedoresIcon from "../../../images/proveedores.png"

/* import {auth, firestore} from "../../../providers/firebase"
import Button from '@material-ui/core/Button';
import { toast } from "react-toastify"; */

function Inicio() {
  /* const user = auth.currentUser  
  const [primerAcceso, setPrimerAcceso] = useState()
  const [email, setEmail] = useState('')
  useEffect(() =>{
    firestore.collection('aaff').doc(user.uid).get().then(snapshot=>{
      let usuario = snapshot.data()
      setEmail(usuario.email)
      if(usuario.primerAcceso== undefined){
        setPrimerAcceso(false)
      }else{
        setPrimerAcceso(usuario.primerAcceso)
      }
    })
  },[user]) */
  
  /* const enviarEmail = (e) =>{
    e.preventDefault()
    if(email!==""){
        auth.sendPasswordResetEmail(email)
        .then(() => {
          console.log("Email enviado")
          toast.success("Email enviado con éxito")
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage)
          toast.error("El email no se ha podido enviar")
        });
    }
  } */
    /* if(primerAcceso!=undefined){
      if(primerAcceso){ */
        return (<>
          <div style={{
            textAlign: 'center',
            fontSize: 28,
            color: "#303248",
            fontWeight: 'bold',
            marginTop: 90
          }}></div>
          <div style={{
            textAlign: 'center',
            fontSize: 22,
            color: "#303248",
            marginTop: 10,
            marginBottom: 70
          }}></div>
          <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
            <Grid item><InicioCard texto1="Bienvenido a tu gestor de fincas" texto2="Donde podrás gestionar toda la documentación de comunidades y proveedores." /></Grid>
          </Grid>
        </>);
    /*   }else{
        return (
          <div className="passAdvice">
            <h1>Cambio de contraseña</h1>
            <p>Por seguridad es necesario cambiar la contraseña antes de seguir.</p>
            <p>Por favor, pincha en el botón para recibir el correo con el enlace para realizar el cambio.</p>
            <Button style={{margin: '30px auto'}} className="btn-Secondary" variant="contained" color="secondary" onClick={enviarEmail}>Enviar</Button>
          </div>
        )
      }
    }else{
      return <></>;
    } */
}

export default Inicio;
