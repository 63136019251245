import React from 'react';
import { auth } from "../../providers/firebase";
import { Navbar, Nav } from 'react-bootstrap';
import Apagar from "../../icons/apagar.svg";
import './Menu.css';

class Menu extends React.Component {
    constructor() {
        super()
        this.state = {
            navExpanded: false
        }
    }

    setNavExpanded = (expanded) => {
        this.setState({ navExpanded: expanded });
    }

    closeNav = () => {
        this.setState({ navExpanded: false });
        this.handleMenu();
    }
    handleMenu = () => {
        document.querySelector('.animated-icon1').classList.toggle('open');
    }
    render() {
        return (
            <Navbar onToggle={this.setNavExpanded} expanded={this.state.navExpanded} className="bg-topNavbar" sticky="top" expand="lg">
                <Navbar.Brand>
                    <Navbar.Toggle onClick={this.handleMenu}>
                        <div className="animated-icon1"><span></span><span></span><span></span></div>
                    </Navbar.Toggle>
                    <img src={this.props.logoUrl} alt="logo" className="logoUrl" style={{ width: "31px", marginLeft: 5, marginTop: 3 }} />
                </Navbar.Brand>
                <div className="topbar">
                    <div></div>
                    <div className="cerrarSesion" onClick={() => auth.signOut()}>
                        <img src={Apagar} alt="Cerrar sesión" />
                    </div>
                </div>
                <Navbar.Collapse className="bg-topNavbar mt-2">
                    <Nav onClick={this.closeNav}>
                        {this.props.menu}
                    </Nav>
                    <div>
                        <div className="navbar-info"></div>
                        <div className="navbar-info"></div>
                    </div>
                </Navbar.Collapse>

            </Navbar>
        );
    }
}

export default Menu;