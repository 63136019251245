import React from "react";
import { firestore, storage } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
/* import LogoMC from "../../../images/MC.png" */
import LogoNF from "../../../images/Logo.png"
import Cabecera from "../../../images/cabecera.png"
import { incidencias } from "../../../providers/incidencias";

class HojaVisitaRender extends React.Component {
    constructor() {
        super()
        this.state = {
            hojaVisita: undefined,
            centroTrabajo: undefined,
            incidencias: [],
            imagenes: []
        }
    }
    componentDidMount() {
        firestore.collection("hojasVisita").doc(this.props.params.nif).get().then(doc => {
            const hojaVisita = doc.data()
            let centroTrabajo = undefined
            doc.data().centrosTrabajo.forEach(ct => {
                if(ct.nif === this.props.params.nifCentroTrabajo){
                    centroTrabajo = ct
                    if(centroTrabajo.imagenes !== undefined){
                        console.log(centroTrabajo.imagenes)
                        centroTrabajo.imagenes.forEach(i => {
                            storage.ref(i).getDownloadURL().then(img => {
                                this.setState({imagenes: ([...this.state.imagenes, img])})
                            })
                        })
                    }
                }
            })
            let inc = []
            if (centroTrabajo?.p1_1 === "s") inc.push(incidencias.p1_1)
            if (centroTrabajo?.p1_2 === "s") inc.push(incidencias.p1_2)
            if (centroTrabajo?.p1_3 === "s") inc.push(incidencias.p1_3)
            if (centroTrabajo?.p1_4 === "s") inc.push(incidencias.p1_4)
            if (centroTrabajo?.p1_5 === "s") inc.push(incidencias.p1_5)
            if (centroTrabajo?.p1_6 === "s") inc.push(incidencias.p1_6)
            if (centroTrabajo?.p1_7 === "s") inc.push(incidencias.p1_7)
            if (centroTrabajo?.p1_8 === "n") inc.push(incidencias.p1_8)
            if (centroTrabajo?.p2_0 === "s") {
                if (centroTrabajo?.p2_1 === "n") inc.push(incidencias.p2_1)
                if (centroTrabajo?.p2_2 === "n") inc.push(incidencias.p2_2)
                if (centroTrabajo?.p2_3 === "n") inc.push(incidencias.p2_3)
                if (centroTrabajo?.p2_4 === "n") inc.push(incidencias.p2_4)
                if (centroTrabajo?.p2_5 === "n") inc.push(incidencias.p2_5)
                if (centroTrabajo?.p2_5 === "n") {
                    if (centroTrabajo?.p2_6 === "n") inc.push(incidencias.p2_6)
                    if (centroTrabajo?.p2_7 === "n") inc.push(incidencias.p2_7)
                    if (centroTrabajo?.p2_8 === "n" && centroTrabajo?.p2_9 === "n") inc.push(incidencias.p2_8)
                }
                if (centroTrabajo?.p2_9 === "n" && centroTrabajo?.p2_8 === "n") inc.push(incidencias.p2_9)
                if (centroTrabajo?.p2_10 === "n") inc.push(incidencias.p2_10)
            }
            if (centroTrabajo?.p3_0 === "s") {
                if (centroTrabajo?.p3_1 === "n") inc.push(incidencias.p3_1)
                if (centroTrabajo?.p3_2 === "n") inc.push(incidencias.p3_2)
                if (centroTrabajo?.p3_3 === "n") inc.push(incidencias.p3_3)
                if (centroTrabajo?.p3_4 === "n") inc.push(incidencias.p3_4)
            }
            if (centroTrabajo?.p4_0 === "s") {
                if (centroTrabajo?.p4_1 === "n") inc.push(incidencias.p4_1)
                if (centroTrabajo?.p4_2 === "n") inc.push(incidencias.p4_2)
                if (centroTrabajo?.p4_3 === "n") inc.push(incidencias.p4_3)
                if (centroTrabajo?.p4_4 === "n") inc.push(incidencias.p4_4)
                if (centroTrabajo?.p4_5 === "n") inc.push(incidencias.p4_5)
                if (centroTrabajo?.p4_6 === "n") inc.push(incidencias.p4_6)
                if (centroTrabajo?.p4_7 === "n") inc.push(incidencias.p4_7)
                if (centroTrabajo?.p4_8 === "n") inc.push(incidencias.p4_8)
                if (centroTrabajo?.p4_9 === "n") inc.push(incidencias.p4_9)
                if (centroTrabajo?.p4_10 === "n") inc.push(incidencias.p4_10)
                if (centroTrabajo?.p4_11 === "n") inc.push(incidencias.p4_11)
                if (centroTrabajo?.p4_12 === "n") inc.push(incidencias.p4_12)
                if (centroTrabajo?.p4_13 === "n") inc.push(incidencias.p4_13)
                if (centroTrabajo?.p4_14 === "n") inc.push(incidencias.p4_14)
                if (centroTrabajo?.p4_15 === "n") inc.push(incidencias.p4_15)
                if (centroTrabajo?.p4_16 === "n") inc.push(incidencias.p4_16)
                if (centroTrabajo?.p4_17 === "n") inc.push(incidencias.p4_17)
            }
            if (centroTrabajo?.p5_0 === "s") {
                if (centroTrabajo?.p5_2 === "n") inc.push(incidencias.p5_2)
                if (centroTrabajo?.p5_3 === "n") inc.push(incidencias.p5_3)
            }
            if (centroTrabajo?.p6_0 === "s") {
                if (centroTrabajo?.p6_2 === "n") inc.push(incidencias.p6_2)
                if (centroTrabajo?.p6_3 === "n") inc.push(incidencias.p6_3)
            }
            if (centroTrabajo?.p7_0 === "s") {
                if (centroTrabajo?.p7_1 === "n") inc.push(incidencias.p7_1)
                if (centroTrabajo?.p7_2 === "n") inc.push(incidencias.p7_2)
                if (centroTrabajo?.p7_3 === "n") inc.push(incidencias.p7_3)
            }
            if (centroTrabajo?.p8_0 === "s") {
                if (centroTrabajo?.p8_1 === "n") inc.push(incidencias.p8_1)
                if (centroTrabajo?.p8_2 === "n") inc.push(incidencias.p8_2)
                if (centroTrabajo?.p8_3 === "n") inc.push(incidencias.p8_3)
                if (centroTrabajo?.p8_4 === "n") inc.push(incidencias.p8_4)
                if (centroTrabajo?.p8_5 === "n") inc.push(incidencias.p8_5)
            }
            if (centroTrabajo?.p9_0 === "s") {
                if (centroTrabajo?.p9_1 === "n") inc.push(incidencias.p9_1)
                if (centroTrabajo?.p9_2 === "n") inc.push(incidencias.p9_2)
            }
            if (centroTrabajo?.p10_0 === "s") {
                if (centroTrabajo?.p10_1 === "n") inc.push(incidencias.p10_1)
                // if (centroTrabajo?.p10_2 === "n") inc.push(incidencias.p10_2)
                if (centroTrabajo?.p10_3 === "n") inc.push(incidencias.p10_3)
                if (centroTrabajo?.p10_4 === "n") inc.push(incidencias.p10_4)
                if (centroTrabajo?.p10_5 === "n") inc.push(incidencias.p10_5)
                if (centroTrabajo?.p10_6 === "n") inc.push(incidencias.p10_6)
            }
            if (centroTrabajo?.p10_0 === "n") inc.push(incidencias.p10_0)
            if (centroTrabajo?.p11_0 === "s") {
                if (centroTrabajo?.p11_1 === "n") inc.push(incidencias.p11_1)
                if (centroTrabajo?.p11_2 === "n") inc.push(incidencias.p11_2)
            }
            if (centroTrabajo?.p11_0 === "n") inc.push(incidencias.p11_0)
            if (centroTrabajo?.p12_0 === "s") {
                if (centroTrabajo?.p12_1 === "n") inc.push(incidencias.p12_1)
                if (centroTrabajo?.p12_2 === "n") inc.push(incidencias.p12_2)
            }
            if (centroTrabajo?.p12_0 === "n") inc.push(incidencias.p12_0)

            let fechaVisita = new Date(centroTrabajo?.fechaVisita.seconds * 1000)
            let fechaVisitaRenovacion = new Date(centroTrabajo?.fechaVisitaRenovacion.seconds * 1000)
            hojaVisita['diffFechas'] = hojaVisita?.fechaVisitaRenovacion?.seconds < hojaVisita?.fechaVisita?.seconds
            hojaVisita['fechaVisita'] = ("0" + fechaVisita.getDate()).slice(-2) + "-" + ("0" + (fechaVisita.getMonth() + 1)).slice(-2) + "-" + fechaVisita.getFullYear()
            hojaVisita['fechaVisitaRenovacion'] = ("0" + fechaVisitaRenovacion.getDate()).slice(-2) + "-" + ("0" + (fechaVisitaRenovacion.getMonth() + 1)).slice(-2) + "-" + fechaVisitaRenovacion.getFullYear()
            this.setState({ hojaVisita: hojaVisita, centroTrabajo: centroTrabajo, incidencias: inc })
        }).catch(() => window.location.replace('/'))
    }

    render() {
        if (!this.state.hojaVisita) return null
        return <>
            <table className="tablePage">
                <thead><tr><td style={{ border: 'none' }}>
                    <div className="header-space">&nbsp;</div>
                </td></tr></thead>
                <tbody>
                    <div style={{ padding: '0 30px' }}>
                        <div style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>HOJA DE SEGUIMIENTO</div>
                        <table>
                            <tbody>
                            <tr>
                                <th>Comunidad de propietarios:</th>
                                <td>{this.state.hojaVisita?.comunidad.razonSocial}</td>
                                <th>Actividad:</th>
                                <td>COMUNIDAD DE PROPIETARIOS</td>
                            </tr>
                            <tr>
                                <th>Centro de trabajo:</th>
                                <td>{this.state.centroTrabajo?.nombre}</td>
                                <th>Localidad:</th>
                                <td>{this.state.hojaVisita?.comunidad.localidad}</td>
                            </tr>
                            <tr>
                                <th>Técnico:</th>
                                <td>{this.state.hojaVisita?.tecnico.nombre}</td>
                                <th>Código Postal:</th>
                                <td>{this.state.hojaVisita?.comunidad.codigoPostal}</td>
                            </tr>
                            <tr>
                                <th>Fecha de visita:</th>
                                <td>{this.state.hojaVisita?.fechaVisitaRenovacion === undefined || this.state.hojaVisita?.diffFechas === true ? this.state.hojaVisita?.fechaVisita : this.state.hojaVisita?.fechaVisitaRenovacion}</td>
                                {/* <td>{this.state.hojaVisita?.fechaVisitaRenovacion === undefined || new Date(this.state.hojaVisita?.fechaVisitaRenovacion).getFullYear() === 1970 || (this.state.hojaVisita?.fechaVisitaRenovacion < this.state.hojaVisita?.fechaVisita) && (Date.parse(this.state.hojasVisita?.fechaVisitaRenovacion) < Date.parse(this.state.hojasVisita?.fechaVisita)) ? this.state.hojaVisita?.fechaVisita : this.state.hojaVisita?.fechaVisitaRenovacion}</td> */}
                                <th>Administrador de Fincas:</th>
                                <td>{this.state.hojaVisita?.aaff.razonSocial}</td>
                            </tr>
                            </tbody>
                        </table>
                        <div style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>ACTUACIONES</div>
                        <table>
                            <tbody>
                                <tr><td><div style={{ minHeight: 100 }}>Visita al centro de trabajo. Se realiza toma de datos para la identificación de riesgos.</div></td></tr>
                            </tbody>
                        </table>
                        <div style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>IDENTIFICACIÓN DE RIESGOS</div>
                        <table id="tableIncidencias">
                            <tbody>
                                <tr><th>Una vez realizada la visita en la comunidad, se observa:</th></tr>
                                <tr><td><ul style={{ minHeight: 200 }}>
                                    {this.state.incidencias.map((e, i) => <li>{e}</li>)}
                                </ul></td></tr>
                            </tbody>
                        </table>
                        <div style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>OBSERVACIONES</div>
                        <table>
                            <tbody>
                                <tr><td>
                                <div style={{ minHeight: 100 }}><p style={{ whiteSpace: "pre-line" }}>{this.state.centroTrabajo?.comentarios}</p></div>
                                </td></tr>
                            </tbody>
                        </table>
                        {this.state.centroTrabajo?.imagenes?.length > 0 && this.state.centroTrabajo?.imagenes !== undefined ? 
                            <>
                                <div className="pagebreak"></div>
                                <div style={{ color: '#ffffff', backgroundColor: '#152849', textAlign: 'center', marginTop: 20, marginBottom: 5, fontSize: 14 }}>IMAGENES</div>
                                <div style={{padding: 10, textAlign: "center"}}>
                                        {this.state.imagenes.map((url, i) => {
                                            return <img key={i} src={url} style={{width: 200}} />
                                        }) }
                                </div>
                            </>
                            : null }
                        <div style={{ display: 'flex', justifyContent: 'left', marginTop: 30, marginBottom: 30 }}>
                            <div style={{ marginRight: 75 }}>
                                <p><b>TÉCNICO DE SEGURIDAD</b></p>
                                <p style={{ marginTop: 50 }}>Firma: {this.state.hojaVisita?.tecnico.nombre}</p>
                                <p style={{ marginTop: 10 }}><small>Técnico en PRL</small></p>
                            </div>
                            <div style={{ marginLeft: 75 }}>
                            </div>
                        </div>
                    </div>
                </tbody>
            </table>
            <div style={{
                width: '100%',
                height: 120,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 40px',
                backgroundImage: `url(${Cabecera})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: "fixed",
                top: 0,
                left: 0
            }}>
                <div style={{ display: 'flex', color: '#ffffff', alignItems: 'center' }}>
                    <img style={{ height: 60, filter: "brightness(0) invert(1)" }} src={LogoNF} alt="Logo NF" />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 20, paddingLeft: 20, borderLeft: '2px solid #ffffff', height: 'fit-content', fontSize: 20 }}>
                        <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold' }}>HOJA DE VISITA</p>
                        <p style={{ margin: 0, lineHeight: 1.2 }}>Identificación de riesgos</p>
                    </div>
                </div>
                {/* <img style={{ height: 60, filter: "brightness(0) invert(1)" }} src={LogoMC} alt="Logo M&C" /> */}
            </div>
            <style jsx>{`
            div {
                font-size: 10px
            }
            table {
                border-collapse: collapse;
                width: 100%;
                table-layout: fixed;
            }
            th, td {
                border: 1px solid #000000;
                padding: 5px;
                line-height: 1.1;
                text-align: left
            }
            th {
                background-color: #eaecee;
            }
            p {
                margin: 0
            }
            ul {
                padding-left: 20px
            }
            li {
                margin-top: 5px
            }
            .header-space {
                height: 120px;
            }
            @page {
                size: A4 portrait;
                margin: 0 0 30px 0;
            }
        `}</style>
        </>
    }
}

export default withRouter(HojaVisitaRender);
