import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../../providers/firebase";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import Lupa from "../../../icons/lupa.svg"

class Tecnicos extends React.Component {
  constructor() {
    super()
    this.state = {
      tecnicos: []
    }
  }
  componentDidMount() {
    firestore.collection("tecnicos").get().then(snapshot => {
      let tecnicos = []
      snapshot.docs.forEach(tecnico => {
        tecnicos.push(tecnico.data())
      })
      this.setState({ tecnicos: tecnicos })
    })
  }

  render() {
    if (this.state.tecnicos === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader"  >Técnicos</div>
        <div className="pageContent tableContainer">
          
          <div className="tableWrapper">
            <table>
              <thead>
                <tr>
                  <th>Técnico</th>
                  <th>NIF</th>
                  <th style={{ textAlign: "center" }}>Administradores Fincas</th>
                </tr>
              </thead>
              <tbody>
                {this.state.tecnicos.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{e.nombre + " " + e.apellidos}</td>
                      <td>{e.nif}</td>
                      <td style={{ textAlign: "center" }}><Link to={"/tecnicos/" + e.nif + "/aaff"}><img src={Lupa} alt="Administradores de Fincas" className="tableIcon"></img></Link></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

export default Tecnicos;

