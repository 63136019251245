import React from "react";
import { firestore } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
import firebase from "firebase";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

class NuevoCentroTrabajo extends React.Component {
  constructor() {
    super()
    this.state = {
      nombre: "",
      finca: []
    }
  }
  componentDidMount() {
    this.loadPageContent() 
  }
  loadPageContent = () => {
    firestore.collection('fincas').doc(this.props.params.nifFinca).get().then(finca => {
       let data = finca.data()
        this.setState({finca:data})
    })
  }

  crearCentroTrabajo = (e) => {
    e.preventDefault();
    firestore.collection('fincas').doc(this.props.params.nifFinca).update({
        centrosTrabajo: firebase.firestore.FieldValue.arrayUnion({
          nombre: this.state.nombre,
          nif: this.props.params.nifFinca+"_"+(this.state.finca.centrosTrabajo === undefined ? 1 : this.state.finca.centrosTrabajo.length+1),
          documentos: {
            evaluacionRiesgos: {
              ruta: "",
              validado: false,
              pendiente: false
            },
            hojaVisita: {
              ruta: "",
              validado: false,
              pendiente: false
            },
            lopd: {
              ruta: "",
              validado: false,
              pendiente: false
            },
            planEmergencia: {
              ruta: "",
              validado: false,
              pendiente: false
            },
            prevencionRiesgos: {
              ruta: "",
              validado: false,
              pendiente: false
            }
          },
        })
    }).then(() => {
        toast.success("centro de trabajo creado")
        window.location.replace("/aaff/"+ this.state.finca.nifAAFF+"/comunidades")
    }).catch(err => {
        toast.error("Error al crear centro de trabajo")
        console.log(err)
    })
  }

  

  render() {
    return <>
      <div className="pageHeader"  >Nuevo centro de trabajo</div>
      <div className="pageContent">
        <div className="titleContent">Crear centro de trabajo</div>
        <form className="nuevoAAFFForm" onSubmit={this.crearCentroTrabajo}>
          <Grid container spacing={5} style={{ marginTop: 20, marginBottom: 20 }}>
            <Grid item xs={12} sm={6}><input type="text" required placeholder="Nombre" value={this.state.nombre} onChange={e => this.setState({ nombre: e.target.value })}></input></Grid>
          </Grid>
          <input type="submit" value="Guardar"></input>
        </form>
      </div>
    </>
  }
}

export default withRouter(NuevoCentroTrabajo);