import React from "react";
import { firestore } from "../../../providers/firebase";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { Grid } from "@material-ui/core";

class NuevoTecnico extends React.Component {
  constructor() {
    super()
    this.state = {
      nif: "",
      nombre: "",
      apellidos: "",
      email: "",
      zona:"",
      status:""
    }
  }

  crearConsultor = (e) => {
    e.preventDefault();
    firestore.collection("tecnicos").doc(this.state.nif).get().then(snapshot => {
      if (snapshot.exists) {
        toast.error("Ya existe un Técnico registrado con el NIF introducido")
      } else {
        firestore.collection("tecnicos").doc(this.state.nif?.toUpperCase()).set({ nif: this.state.nif?.toUpperCase(), nombre: this.state.nombre?.toUpperCase(), apellidos: this.state.apellidos?.toUpperCase(), email: this.state.email, zona: this.state.zona?.toUpperCase(), status: true }).then(() => {
          toast.success("Técnico creado correctamente")
          window.location.replace("/tecnicos")
        }).catch((err) => {
          toast.error("Ha ocurrido un error al crear el Técnico")
          console.log(err)
        })
      }
    })

  }

  render() {
    return <>
      <div className="pageHeader"  >Técnicos</div>
      <div className="pageContent">
        <div className="titleContent">Crear nuevo Técnico</div>
        <form className="nuevoAAFFForm" onSubmit={this.crearConsultor}>
          <Grid container spacing={5} style={{ marginTop: 20, marginBottom: 20 }}>
            <Grid item xs={12} sm={6}><input type="text" required placeholder="NIF" pattern="(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)" title="Introduce un NIF válido (CIF o DNI con letra mayúscula)" value={this.state.nif} onChange={e => this.setState({ nif: e.target.value })}></input></Grid>
            <Grid item xs={12} sm={6}><input type="email" required placeholder="Email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })}></input></Grid>
            <Grid item xs={12} sm={6}><input type="text" required placeholder="Nombre" value={this.state.nombre} onChange={e => this.setState({ nombre: e.target.value })}></input></Grid>
            <Grid item xs={12} sm={6}><input type="text" required placeholder="Apellidos" value={this.state.apellidos} onChange={e => this.setState({ apellidos: e.target.value })}></input></Grid>
            <Grid item xs={12} sm={6}><input type="text" required placeholder="Zona" value={this.state.zona} onChange={e => this.setState({ zona: e.target.value })}></input></Grid>
          </Grid>
          <input type="submit" value="Guardar"></input>
        </form>
      </div>
    </>
  }
}

export default NuevoTecnico;
