import React, { useState, useEffect } from "react";
import Portada from "../../../images/PortadaFinca.png"
import Cabecera from "../../../images/Encabezado.jpg"
import Logo from "../../../images/Logo.png"
import Tick from "../../../icons/tick.png"
import Cross from "../../../icons/cross.png"
import { firestore } from "../../../providers/firebase"
import { withRouter } from "../../../providers/withRouter";
import { PDFViewer, Document, View, Page, Text, Image, StyleSheet } from "@react-pdf/renderer"
import CircularProgress from '@material-ui/core/CircularProgress';

const InformeSituacionComunidades = (props) => {

  const [fincas, setFincas] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    console.log(props.params)
    firestore.collection("fincas").where("nifAAFF", "==", props.params.nif).orderBy('razonSocial').get().then(snapshot => {
      let fincas = []

      snapshot.docs.forEach(finca => fincas.push(finca.data()))
      setFincas(fincas, () => {
        var src = document.getElementById('portada').style.backgroundImage
        var url = src.match(/\((.*?)\)/)[1].replace(/('|")/g, '');

        var img = new Image();
        img.onload = function () {
          window.print()
        }
        img.src = url;
        if (img.complete) img.onload();
      })
      setIsLoading(false)
    })
  },[])
  const styles = StyleSheet.create({
    imgPrincipal:{
        height:'400px', 
        width:'100%', 
        marginTop:30
    },
    body: {
        //paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: '0cm',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0 
    }, 
    tableRowHeader: { 
        marginTop:"30px",
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableColHeader: { 
        width: "16.66%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        /* backgroundColor: '#375491',
        color: '#ffffff', */
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        fontSize: 14 
    }, 
    tableColHeader1: { 
        width: "16.67%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        /* backgroundColor: '#375491',
        color: '#ffffff', */
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        fontSize: 14 
    }, 
    tableCol: { 
        width: "16.66%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        display: 'flex',
        justifyContent:'center',
        alignItems:'center'
    }, 
    tableCol1: { 
        width: "16.67%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        display: 'flex',
        justifyContent:'center',
        alignItems:'center'
    }, 
    tableCell: { 
        margin: "auto", 
        marginTop: 5, 
        marginBottom: 5, 
        marginLeft:2,
        marginRight: 2,
        fontSize: 6 
    },
    tableCellD: { 
        margin: "auto", 
        marginTop: 5, 
        marginBottom: 5, 
        marginLeft:2,
        marginRight: 2,
        fontSize: 10 
    },
    pdfHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        backgroundColor: '#ffffff',
        /* margin:'2cm', */
    },
    pdfHeaderImg:{
        width:'100%', 
        height:'60px',
        marginBottom:'30px',
    },
    tablePage: {
        width: '100%',
    },
    headerSpace: {
        height: '90px',
    },
    div: {
        fontSize: '10px'
    },
    h1: {
        /* marginLeft:'2cm',
        marginRight:'2cm', 
        marginTop: '50px',*/
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: '600',
    },
     h2: {
        fontSize: '11px',
        fontWeight: '600',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
        marginTop: '10px',
        marginBottom: '10px',
    },
    h3: {
        fontSize: '9px',
        width: '100%',
        padding: '5px 10px',
        backgroundColor: '#f8f8f8',
        textTransform: 'uppercase',
        border: '1px solid #e6e6e6',
        marginTop: '25px',
        marginBottom: '15px',
        fontWeight:'bold',
        /* marginLeft:'2cm',
        marginRight:'20cm', */
    },
    h4: {
        color: '#ab2a3e',
        marginBottom: '10px',
        marginTop: '20px',
        fontSize: '9px',
        fontWeight:'bold',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
    },
    h5: {
        fontSize: '10px',
        marginTop: '15px',
        marginBottom: '5px',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
    },
    tableRiesgos: {
        borderCollapse: 'collapse',
        width: '100%',
        tableLayout: 'fixed',
        marginBottom: '24px',
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    simpleTable: {
        borderCollapse: 'collapse',
        width: '80%',
        margin: '0 10% 24px 10%',
    },
    simpleTableTh: {
        textTransform: 'uppercase',
    },
    simpleTableTd: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: '1.1'
    },
    ul: {
        // lineHeight: '1.8',
        // fontSize:'7px',
        flexDirection: "column", 
        width: 150,
    },
    ol: {
        lineHeight: '1.8',
        flexDirection: "row", 
        marginBottom: 4,
        fontSize: '7px',
    },
    p: {
        lineHeight: '1.8',
        textAlign: 'justify',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
        fontSize: '7px',
    },
    imgP:{
        width:'100px', 
        marginBottom:'30px',
    },
    subp: {
        lineHeight: '1.8',
        textAlign: 'justify',
        marginLeft: '8px',
        fontSize: '8px',
    },
    br: {
        display: 'block',
        margin: '10px 0 2px 0',
        lineHeight: '5px',
        content: " ",
    },
    footerSpace: {
        height: 50
    },
    page: {
        size: 'A4 portrait',
        margin: '2cm',
    },
    pagebreak: {
        clear: 'both',
        pageBreakAfter: 'always',
      }
  });

  const itemsPerPage = 23; // Número de elementos por página
  const pages = Math.ceil(fincas.length / itemsPerPage); // Calcular el número total de páginas

  const getItemsForPage = (pageIndex) => {
    const startIndex = pageIndex * itemsPerPage;
    return fincas.slice(startIndex, startIndex + itemsPerPage);
  };

/*   const rowHeight = 10;
  const usableHeight = 690;
  const itemsPerPage = 15;
  console.log(itemsPerPage) */
  return (
    <>
      {isLoading ? <CircularProgress style={{color:'#848474', position: 'absolute', top: '50%', left: '50%'}} /> :
      <PDFViewer style={{ width:"100%", height:"100vh"}}>
        <Document size="A4" margin="2cm">
        <Page>
            <View>
                <Image src={Portada} alt="Portada" style={{height:"100vh"}}/>
            </View>
        </Page>
        {Array.from({ length: pages }).map((_, pageIndex) => (
            <Page key={pageIndex} style={styles.body}>
                <View style={{marginTop: 0, paddingBottom: 20, marginHorizontal: '1cm'}} fixed>
                    {/* <View style={{ display: 'flex', color: '#ffffff', alignItems: 'center' }}>
                        <Image width={170} src={Logo} alt="Cabecera" />
                    </View>  */}
                    <View >
                        <Image src={Logo} style={{marginTop: 20, width:'20%'}} />
                    </View>
                </View>
                <View style={{ marginHorizontal: '1cm' }}>
                    {pageIndex === 0 && (
                        <View>
                            <Text style={styles.p}>
                                Estimado administrador de fincas.
                            </Text>
                            <Text style={styles.br}></Text>
                            <Text style={styles.p}>
                                Tras los primeros meses de trabajo administrativo, visitas presenciales en las comunidades y redacción de informes de prevención de riesgos os mostramos a continuación la situación documental actual:
                            </Text>
                            <Text style={styles.br}></Text>
                            <Text style={styles.br}></Text>
                        </View>
                    )}
                    <View style={styles.table}>
                        <>
                            <View style={styles.tableRowHeader} fixed> 
                                <View style={styles.tableColHeader}> 
                                    <Text style={styles.tableCell}>Finca</Text> 
                                </View> 
                                <View style={styles.tableColHeader}> 
                                    <Text style={styles.tableCell}>NIF</Text> 
                                </View> 
                                <View style={styles.tableColHeader}> 
                                    <Text style={styles.tableCell}>Certificado LOPD</Text> 
                                </View> 
                                <View style={styles.tableColHeader1}> 
                                    <Text style={styles.tableCell}>Hoja de Visita</Text> 
                                </View> 
                                <View style={styles.tableColHeader}> 
                                    <Text style={styles.tableCell}>Identificación de riesgos y medidas preventivas</Text> 
                                </View> 
                                <View style={styles.tableColHeader1}> 
                                    <Text style={styles.tableCell}>Plan de emergencia</Text> 
                                </View> 
                            </View>
                            
                            {getItemsForPage(pageIndex).map((e, i) => (
                                e.activo === true || e.activo === "true" || e.activo === undefined ?
                                    <>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{e.razonSocial}</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{e.nif}</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCellD}>{(e.documentos?.lopd?.validado || e.documentos?.lopd?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                            </View> 
                                            {(e.centrosTrabajo === undefined || e.centrosTrabajo.length === 0) ?
                                                <>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCellD}>{(e.documentos?.hojaVisita?.validado || e.documentos?.hojaVisita?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCellD}>{(e.documentos?.evaluacionRiesgos?.validado || e.documentos?.evaluacionRiesgos?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} /> /* <Text>-</Text> */ }</Text> 
                                                    </View> 
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCellD}>{(e.documentos?.planEmergencia?.validado || e.documentos?.planEmergencia?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                                    </View> 
                                                </>
                                            :
                                                <>
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View> 
                                                    <View style={styles.tableCol}> 
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View> 
                                                    <View style={styles.tableCol1}>
                                                        <Text style={styles.tableCell}></Text> 
                                                    </View> 
                                                </>
                                            }
                                        </View> 
                                            {(e.centrosTrabajo === undefined || e.centrosTrabajo.length === 0) ? null :
                                                <>
                                                    {e.centrosTrabajo.map((c,j) => {
                                                        return(
                                                            <>
                                                                <View style={styles.tableRow}>
                                                                    <View style={styles.tableCol1}>
                                                                        <Text style={styles.tableCell}></Text> 
                                                                    </View> 
                                                                    <View style={styles.tableCol1}>
                                                                        <Text style={styles.tableCell}>{c.nombre}</Text> 
                                                                    </View> 
                                                                    <View style={styles.tableCol1}>
                                                                        <Text style={styles.tableCell}></Text> 
                                                                    </View> 
                                                                    <View style={styles.tableCol1}>
                                                                        <Text style={styles.tableCellD}>{(c.documentos?.hojaVisita?.validado || c.documentos?.hojaVisita?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                                                    </View> 
                                                                    <View style={styles.tableCol}> 
                                                                        <Text style={styles.tableCellD}>{(c.documentos?.evaluacionRiesgos?.validado || c.documentos?.evaluacionRiesgos?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />  /*<Text>-</Text>*/}</Text> 
                                                                    </View> 
                                                                    <View style={styles.tableCol1}>
                                                                        <Text style={styles.tableCellD}>{(c.documentos?.planEmergencia?.validado || c.documentos?.planEmergencia?.pendiente) ? <Image alt='valido' src={Tick} /> : <Image alt='no valido' src={Cross} />}</Text> 
                                                                    </View> 
                                                                </View>
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            }
                                    </>

                                :
                                    null
                                )
                            )}
                        </>
                    </View>
                    {pageIndex === pages - 1 && (
                        <View>
                            <Text style={styles.br}></Text>
                            <Text style={styles.br}></Text>
                            <Text style={styles.p}>
                                Nuestro departamento técnico seguirá trabajando mensualmente en la reclamación activa de documentación.
                            </Text>
                            <Text style={styles.br}></Text>
                            <Text style={styles.p}>
                                Quedamos a su entera disposición para aclarar cualquier duda que puedan tener,
                            </Text>
                            <Text style={styles.p}>
                                Atentamente,
                            </Text>
                            <Image alt='logo' src={Logo} style={styles.imgP} />
                        </View>
                    )}
                    
                </View>
            </Page>
        ))}
        </Document>
      </PDFViewer>
        
      }
      <style jsx>{`
        .header-space {
          height: 100px;
          background-image: url(${`'${Cabecera}'`});
          background-position: bottom;
          background-size: cover;
          margin-bottom: 50px;
        }
        .footer-space {
          height: 30px;
        }
        .portada {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-position: bottom;
          background-size: cover;
        }
        .pagina {
          margin-top: 150px;
          padding: 0px 100px;
          font-size: 10px;
        }
        p {
          line-height: 1.8;
          text-align: justify;
        }
        br {
          display: block;
          margin: 18px 0 20px 0;
          line-height: 22px;
          content: " ";
        }
       .simpleTable {
          border-collapse: collapse;
          margin-bottom: 30px;
        }
        .simpleTable th {
          text-transform: uppercase
        }
        .simpleTable th, .simpleTable td {
          border: 1px solid #000000;
          padding: 5px;
          line-height: 1.1
        }
        .simpleTable img {
          height: 25px
        }
        @page {
          size: A4 portrait;
          margin: 0;
        }
      `}</style>
    </>
  )
}
 

export default withRouter(InformeSituacionComunidades);
