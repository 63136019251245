import React from "react";
import Portada from "../../../images/PortadaFinca.png"
import Encabezado from "../../../images/Encabezado.jpg"
import Logo from "../../../images/Logo.png"
import Tick from "../../../icons/tick.svg"
import Cross from "../../../icons/cross.svg"
import { firestore } from "../../../providers/firebase"
import { withRouter } from "../../../providers/withRouter";

class InformeSituacionComunidades extends React.Component {
  constructor() {
    super()
    this.state = {
      fincas: undefined
    }
  }
  componentDidMount() {
    firestore.collection("fincas").where("nifAAFF", "==", this.props.params.nif).orderBy('razonSocial').get().then(snapshot => {
      let fincas = []

      snapshot.docs.forEach(finca => fincas.push(finca.data()))
      this.setState({ fincas: fincas }, () => {
        var src = document.getElementById('portada').style.backgroundImage
        var url = src.match(/\((.*?)\)/)[1].replace(/('|")/g, '');

        var img = new Image();
        img.onload = function () {
          window.print()
        }
        img.src = url;
        if (img.complete) img.onload();
      })
    })
  }

  render() {
    if (this.state.fincas === undefined) return null
    return (
      <>
        <div className="pagina" style={{ paddingTop: 0, paddingBottom:55}}>
            <div id="portada" className="portada" style={{ backgroundImage: `url('${Portada}')` }}></div>
        </div>
        <table>
          <thead><tr><td style={{ border: 'none' }}>
            <div ><img alt='logo' src={Logo} className="logoHeader" /></div>
          </td></tr></thead>
          <tbody>
            {/* <div id="portada" className="portada" style={{ backgroundImage: `url('${Portada}')` }}>
            </div> */}
            {/* <div className="pagebreak"></div> */}
            <div className="pagina paginaPrimera">
              <div style={{ height: 60 }}></div>
              <p style={{paddingTop: 40,  paddingBottom: 30 }}>
                Estimado administrador de fincas.
              </p>
              <p style={{ paddingBottom: 20 }}>
                Tras los primeros meses de trabajo administrativo, visitas presenciales en las comunidades y redacción de informes de prevención de riesgos os mostramos a continuación la situación documental actual:
              </p>
              <table className="simpleTable" style={{display:'table'}}>
                <thead>
                  <tr style={{marginBottom:250, paddingBottom:200}}>
                    <th>Finca</th>
                    <th>NIF</th>
                    <th style={{ textAlign: "center" }}>Certificado LOPD</th>
                    <th style={{ textAlign: "center" }}>Hoja de Visita</th>
                    {/* <th style={{ textAlign: "center" }}>Plan de prevención</th> */}
                    <th style={{ textAlign: "center" }}>Identificación de riesgos y medidas preventivas</th>
                    <th style={{ textAlign: "center" }}>Plan de emergencia</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.fincas.map((e, i) => {
                    return ((e.activo === true || e.activo === "true" || e.activo === undefined) ? 
                    <>
                      <tr key={i}>
                        <td>{e.razonSocial}</td>
                        <td>{e.nif}</td>
                        <td style={{ textAlign: "center" }}>{(e.documentos?.lopd?.validado || e.documentos?.lopd?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                        {(e.centrosTrabajo === undefined || e.centrosTrabajo.length === 0) ?
                          <>
                            <td style={{ textAlign: "center" }}>{(e.documentos?.hojaVisita?.validado || e.documentos?.hojaVisita?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                            {/* <td style={{ textAlign: "center" }}>{(e.documentos?.prevencionRiesgos?.validado || e.documentos?.prevencionRiesgos?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td> */}
                            <td style={{ textAlign: "center" }}>{(e.documentos?.evaluacionRiesgos?.validado || e.documentos?.evaluacionRiesgos?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} /> /* <span>-</span> */  }</td>
                            <td style={{ textAlign: "center" }}>{(e.documentos?.planEmergencia?.validado || e.documentos?.planEmergencia?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                          </>
                        : 
                          <>
                            <td></td>
                            <td></td>
                            <td></td>
                          </>
                        }
                      </tr>
                      {(e.centrosTrabajo === undefined || e.centrosTrabajo.length === 0) ? null :
                        <>
                          {e.centrosTrabajo.map((c,j) => {
                            return(
                              <tr key={j}>
                                <td></td>
                                <td>{c.nombre}</td>
                                <td style={{ textAlign: "center" }}></td>
                                <td style={{ textAlign: "center" }}>{(c.documentos?.hojaVisita?.validado || c.documentos?.hojaVisita?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                                <td style={{ textAlign: "center" }}>{(c.documentos?.evaluacionRiesgos?.validado || c.documentos?.evaluacionRiesgos?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} /> /* <span>-</span> */}</td>
                                <td style={{ textAlign: "center" }}>{(c.documentos?.planEmergencia?.validado || c.documentos?.planEmergencia?.pendiente) ? <img alt='valido' src={Tick} /> : <img alt='no valido' src={Cross} />}</td>
                              </tr>
                          )})}
                        </>
                      }
                    </> : null)
                  })}
                </tbody>
              </table>
              <p>
                Nuestro departamento técnico seguirá trabajando mensualmente en la reclamación activa de documentación.<br />
                Quedamos a su entera disposición para aclarar cualquier duda que puedan tener,<br />
                Atentamente,<br />
              </p>
              <img alt='logo' src={Logo} width={170} />
            </div>
          </tbody>
          <tfoot><tr><td style={{ border: 'none' }}>
            <div class="footer-space">&nbsp;</div>
          </td></tr></tfoot>
        </table>

        <style jsx>{`
          .header-space {
            height: 100px;
            background-image: url(${`'${Encabezado}'`});
            background-position: bottom;
            background-size: cover;
            margin-bottom: 50px;
          }
          .logoHeader{
            width: 120px;
            margin: 30px 100px;
          }
          .footer-space {
            height: 30px;
          }
          .portada {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-position: bottom;
            background-size: cover;
          }
          .pagina {
            margin-top: 150px;
            padding: 0px 100px;
            font-size: 10px;
          }
          .paginaPrimera{
            padding-top: 450px;
            margin-bottom: 0px;
          }
          .paginaSegunda{
            margin-top:450px;
          }
          p {
            line-height: 1.8;
            text-align: justify;
          }
          br {
            display: block;
            margin: 18px 0 20px 0;
            line-height: 22px;
            content: " ";
          }
         .simpleTable {
            border-collapse: collapse;
            margin-bottom: 30px;
          }
          .simpleTable th {
            text-transform: uppercase
          }
          .simpleTable td {
            page-break-inside: avoid;
          }
          .simpleTable th, .simpleTable td {
            border: 1px solid #000000;
            padding: 5px;
            line-height: 1.1
          }
          .simpleTable img {
            height: 25px
          }
          @page {
            size: A4 portrait;
            margin: 0;
          }
        `}</style>
      </>
    )
  }
}

export default withRouter(InformeSituacionComunidades);
