import React from "react";
import { Grid } from "@material-ui/core";
import InicioCard from "../../../components/InicioCard";

function Inicio() {

  return (<> 
    <div style={{
      textAlign: 'center',
      fontSize: 28,
      color: "#303248",
      fontWeight: 'bold',
      marginTop: 90
    }}></div>
    <div style={{
      textAlign: 'center',
      fontSize: 22,
      color: "#303248",
      marginTop: 10,
      marginBottom: 70
    }}></div>
    <Grid container direction="row" justify="center" alignItems="center" spacing={5}>
      <Grid item><InicioCard texto1="Bienvenido a tu gestor de fincas" texto2="Donde podrás gestionar toda la documentación de comunidades y proveedores." /></Grid>
    </Grid>
  </>);
}

export default Inicio;
