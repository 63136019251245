import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../../providers/firebase";
import Add from "../../../icons/add.svg"
import HeaderBackground from "../../../images/banner_administradores.jpg"
import Delete from "../../../icons/delete.svg"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from "react-toastify";

class TecnicoAdmin extends React.Component {
  constructor() {
    super()
    this.state = {
      tecnicos: [],
      dialog: {
        opened: false,
        title: "",
        docName: "",
        tecnico: undefined
      },
      eliminarTecnico: {
        opened: false,
        tecnico: undefined
      }
    }
  }
  componentDidMount() {
    this.loadPageContent()
  }
  loadPageContent() {
    firestore.collection("tecAdmin").get().then(snapshot => {
      let tecnicos = []
      snapshot.docs.forEach(tecnico => {
        tecnicos.push(tecnico.data())
      })
      this.setState({ tecnicos: tecnicos })
    })
  }

  eliminarTecnico = () => {
    firestore.collection("tecAdmin").doc(this.state.eliminarTecnico.tecnico.nif).delete().then(() => {
      toast.success("Técnico admin eliminado con éxito")
      this.setState({ eliminarTecnico: { opened: false, tecnico: undefined } })
      this.loadPageContent()
    }).catch(err => {
      toast.error("Error al eliminar el técnico admin")
      console.log(err)
    })
  }

  render() {
    if (this.state.tecnicos === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader"  >Técnicos</div>
        <div className="pageContent tableContainer">
          <div className="contentBar">
            <div></div>
            <Link to="/tecnicos/nuevo"><div className="contentButton"><img src={Add} alt="Más" />Nuevo Técnico</div></Link>
          </div>
          <div className="tableWrapper">
            <table>
              <thead>
                <tr>
                  <th>Técnico</th>
                  <th>NIF</th>
                  {/* <th style={{ textAlign: "center" }}>Administradores Fincas</th> */}
                  <th style={{ textAlign: "center" }}>Eliminar</th>
                </tr>
              </thead>
              <tbody>
                {this.state.tecnicos.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{e.nombre + " " + e.apellidos}</td>
                      <td>{e.nif}</td>
                      {/* <td style={{ textAlign: "center" }}><Link to={"/tecnicos/" + e.nif + "/aaff"}><img src={Lupa} alt="Administradores de Fincas" className="tableIcon"></img></Link></td> */}
                      <td style={{ textAlign: "center" }}><img src={Delete} alt="ELIMINAR" className="tableIcon" onClick={() => this.setState({ eliminarTecnico: { opened: true, tecnico: e } })}></img></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        {/* ELIMINAR UNA TECNICO */}
        <Dialog
          open={this.state.eliminarTecnico.opened}
          onClose={() => this.setState({ eliminarTecnico: { opened: false, tecnico: undefined } })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">¿Quieres eliminar el tecnico <b>{this.state.eliminarTecnico.tecnico?.nombre+" "+this.state.eliminarTecnico.tecnico?.apellidos}</b>?</DialogTitle>

          <DialogActions>
            <Button onClick={() => this.setState({ eliminarTecnico: { opened: false, tecnico: undefined } })} color="inherit" style={{ color: "#FF5252" }}>
              Cancelar
            </Button>
            <Button onClick={this.eliminarTecnico} color="inherit" style={{ color: "#7bc977" }} autoFocus>
              <b>Eliminar</b>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default TecnicoAdmin;

