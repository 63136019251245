import React from "react";
import { firestore, storage } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
/* import LogoMC from "../../../images/MC.png" */
import LogoNF from "../../../images/Logo.png"
import planPrev_1 from "../../../images/planPrev_1.png"

class PlanPrevencionRender extends React.Component {
    constructor() {
        super()
        this.state = {
            hojaVisita: undefined,
            centroTrabajo: undefined,
        }
    }
    componentDidMount() {
        firestore.collection("hojasVisita").doc(this.props.params.nif).get().then(doc => {
        const hojaVisita = doc.data()
        let centroTrabajo = undefined
            doc.data().centrosTrabajo.forEach(ct => {
                if(ct.nif === this.props.params.nifCentroTrabajo){
                    centroTrabajo = ct
                }
            })
            console.log(centroTrabajo)
            storage.ref(centroTrabajo?.img).getDownloadURL().then(img => {
                centroTrabajo['imgURL'] = img
                let fechaVisita = new Date(centroTrabajo?.fechaVisita.seconds * 1000)
                hojaVisita['fechaVisita'] = ("0" + fechaVisita.getDate()).slice(-2) + "-" + ("0" + (fechaVisita.getMonth() + 1)).slice(-2) + "-" + fechaVisita.getFullYear()
                this.setState({ hojaVisita: hojaVisita, centroTrabajo: centroTrabajo })
            })

        }).catch(() => window.location.replace('/'))
    }

    render() {
        if (!this.state.hojaVisita) return null
        return <>
            <table className="tablePage">
                <thead><tr><td>
                    <div class="header-space">&nbsp;</div>
                </td></tr></thead>
                <tbody>
                    <h1>PLAN DE PREVENCIÓN</h1>
                    <h2><b>Comunidad de propietarios:</b> {this.state.hojaVisita?.comunidad.razonSocial}</h2>
                    <h2><b>CIF:</b> {this.state.hojaVisita?.comunidad.nif}</h2>
                    <h2><b>Centro de trabajo:</b> {this.state.centroTrabajo?.nombre}</h2>
                    <div id="imgFinca" style={{ width: '100%', paddingTop: '66.66%', backgroundImage: `url(${this.state.centroTrabajo?.imgURL})`, backgroundPosition: 'center', backgroundSize: 'cover', marginTop: 100 }}></div>
                    <div className="pagebreak"></div>
                    <h3>1. Introducción</h3>
                    <p>
                        La Ley 31/1995, de 8 de noviembre, de Prevención de Riesgos Laborales, publicada en el B.O.E. del 10 de noviembre de 1995, reformada por la Ley 54/2003 de 12 de diciembre, establece en su artículo 16 que el empresario deberá integrar la prevención de riesgos laborales en el sistema general de gestión de la empresa, tanto en el conjunto de sus actividades como en todos los niveles jerárquicos de ésta, a través de la implantación y aplicación de un Plan de Prevención de Riesgos Laborales.<br />
                        Dicha ley establece los principios generales relativos a la prevención de los riesgos profesionales para la protección de la seguridad y de la salud, la eliminación o disminución de los riesgos derivados del trabajo, la información, la consulta, la participación equilibrada y la formación de los trabajadores en materia preventiva. <br />
                        El establecimiento del Sistema de Prevención de Riesgos Laborales se lleva a cabo conforme a la normativa vigente; concretamente, el artículo 14 de la Ley de Prevención de Riesgos Laborales, y su reforma, la Ley 54/2003, establece el derecho de los trabajadores a una protección eficaz en materia de seguridad y salud en el trabajo, lo que supone la existencia de un correlativo deber del empresario de protección de los trabajadores frente a los riesgos laborales. <br />
                        Por otro lado, el Reglamento de los Servicios de Prevención, aprobado por el Real Decreto 39/1997, de 17 de enero, como normativa de desarrollo de la Ley de Prevención de Riesgos Laborales, modificado por el RD 604/2006 y el RD 337/2010, establece en su artículo 1:<br />
                        "La prevención de riesgos laborales, como actuación a desarrollar en el seno de la empresa, deberá integrarse en el conjunto de sus actividades y decisiones, tanto en los procesos técnicos, en la organización del trabajo y en las condiciones en que éste se preste, como en la línea jerárquica de la empresa, incluidos todos los niveles de la misma.<br />
                        La integración de la prevención en todos los niveles jerárquicos de la empresa implica la atribución a todos ellos y la asunción por éstos de la obligación de incluir la prevención de riesgos en cualquier actividad que realicen u ordenen y en todas las decisiones que adopten."<br />
                        El Plan de Prevención de riesgos laborales deberá incluir la estructura organizativa, las responsabilidades, las funciones, las prácticas, los procedimientos, los procesos y los recursos necesarios para realizar la acción de prevención de riesgos en la empresa, en los términos que reglamentariamente se establezcan. Los instrumentos esenciales para la gestión y aplicación del plan de prevención de riesgos, que podrán ser llevados a cabo por fases de forma programada, son la identificación de riesgos laborales y la planificación de la actividad preventiva.<br />
                        A pesar de que aún no ha sido publicado el texto reglamentario que concrete el contenido del Plan de Prevención, disponer del mismo es ya una obligación legal. En base a dicha obligación, {this.state.hojaVisita?.comunidad.razonSocial} con la voluntad decidida de cumplir con sus obligaciones legales en materia de prevención de riesgos laborales y asesorada por su Servicio de Prevención Ajeno, ha elaborado el presente documento que define sus Sistema de Gestión de la Prevención y que tiene carácter de norma de trabajo interna.<br />
                        El objeto del presente documento es describir el Sistema de Gestión de la Prevención en {this.state.hojaVisita?.comunidad.razonSocial}. El documento, como norma de trabajo interna, obliga con carácter general a todos los que en ella trabajan, independientemente de las actividades que realicen y de su nivel jerárquico.<br />
                    </p>
                    <div className="pagebreak"></div>
                    <h3>2. Datos generales del plan de prevención</h3>
                    <h5>Datos generales del centro de trabajo:</h5>
                    <p>Comunidad de propietarios: {this.state.hojaVisita?.comunidad.razonSocial}</p>
                    <p>Dirección: {this.state.centroTrabajo?.nombre}</p>
                    <p>Localidad: {this.state.hojaVisita?.comunidad.localidad}</p>
                    <p>C.I.F.: {this.state.hojaVisita?.comunidad.nif}</p>
                    <p>Teléfono: {this.state.hojaVisita?.comunidad.telefono}</p>
                    <h3>3. Política preventiva</h3>
                    <p>
                        {this.state.hojaVisita?.comunidad.razonSocial} considera como uno de sus principios básicos y objetivos fundamentales la promoción de la mejora continua de las condiciones de trabajo. Por eso, asume las obligaciones que indica la Ley 31/1995, de 8 de noviembre, de Prevención de Riesgos Laborales y su reforma, la Ley 54/2003, de 12 de noviembre, y las considera como el nivel mínimo de actuación sobre esta cuestión.<br />
                        Este compromiso con la prevención y las responsabilidades que se derivan, afecta a todos los niveles que integran la empresa. Sólo asumiendo esta política, cumpliendo, respetando las normas, procedimientos por todos los estamentos y en todas las actividades, podremos cumplir este objetivo.<br />
                        Por eso {this.state.hojaVisita?.comunidad.razonSocial}:
                    </p>
                    <ul>
                        <li>Debe establecer un Sistema de Prevención de Riesgos que cuente con los medios adecuados para conseguir sus fines.</li>
                        <li>Debe integrar la actividad preventiva en todos los niveles y actuaciones de la empresa.</li>
                        <li>Debe fomentar una cultura preventiva y promover actuaciones que no se limiten a la simple corrección a posteriori de situaciones de riesgo ya manifestadas.</li>
                        <li>Debe ejercer actividades de formación e información dirigidas a tener un mejor conocimiento de los riesgos derivados del trabajo.</li>
                        <li>Debe fomentar la consulta y participación de los trabajadores en la gestión de la prevención de riesgos laborales.</li>
                        <li>Debe desarrollar planes preventivos y de actuación dirigidos a la mejora continua de la seguridad y salud.</li>
                    </ul>
                    <p>
                        {this.state.hojaVisita?.comunidad.razonSocial}, a través de este documento, manifiesta claramente su compromiso y lo transmite a todos los componentes de su estructura.<br />
                        <br />
                        En {this.state.hojaVisita?.comunidad.localidad}, a {this.state.hojaVisita?.fechaVisita}<br />
                    </p>
                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{this.state.hojaVisita?.comunidad.razonSocial}</p>
                    <div className="pagebreak"></div>
                    <h3>4. Descripción de la comunidad</h3>
                    <p>
                        {this.state.hojaVisita?.comunidad.razonSocial} dispone de un centro de trabajo ubicado en {this.state.centroTrabajo?.nombre}<br />
                        Las actividades que se realizan son las propias de una comunidad de propietarios: Limpieza general de las instalaciones, mantenimiento de las zonas ajardinadas, mantenimiento de las instalaciones…<br />
                        También realizan un conjunto de actividades que se deberán tener en cuenta desde el punto de vista de la prevención de riesgos: mantenimiento de las instalaciones por empresas de servicios.
                    </p>
                    <h3>5. Estructura organizativa</h3>
                    {/* eslint-disable-next-line */}
                    <img src={planPrev_1} style={{ width: '80%', margin: '24px 10% 0 10%' }} />
                    <div className="pagebreak"></div>
                    <h3>6. Elementos principales de la política preventiva</h3>
                    <h4>Integración de la actividad preventiva</h4>
                    <p>
                        Integrar la prevención de riesgos laborales en el conjunto de sus actividades y decisiones, tanto en los procesos y la organización del trabajo, y en las condiciones que éste se realice, como en todos los niveles de la línea jerárquica de responsabilidad.<br />
                        Según el artículo 1 del RD 39/1997, del 17 de Enero, por el que se aprueba el Reglamento de los Servicios de Prevención, modificado por el RD 604/2006 y el RD 337/2010, la Prevención de Riesgos Laborales como actuación a desarrollar en el seno de la comunidad, deberá integrarse en el conjunto de sus actividades y decisiones, tanto en los procesos técnicos, en la organización del trabajo y en las condiciones en que éste se preste, como en la línea jerárquica de la empresa incluidos todos los niveles de la misma. <br />
                        Se pretende mentalizar a todo trabajador del cumplimiento de las medidas de prevención en el desarrollo de su actividad, ya sea personalmente o con la colaboración de otras personas.<br />
                        La Ley de Prevención de Riesgos Laborales establece los derechos y las obligaciones en materia de seguridad y salud.
                    </p>
                    <h4>Responsabilidades y funciones</h4>
                    <h5>1. Del empresario</h5>
                    <p>Según la Ley 31/1995, de LPRL, y su reforma, la Ley 54/2003, las obligaciones del empresario son:</p>
                    <ul>
                        <li>Marcar la política de Prevención de Riesgos Laborales en la comunidad. </li>
                        <li>Fomentar una cultura de Prevención y velar por su integración en la comunidad.</li>
                        <li>Hacer un seguimiento permanente de la actividad preventiva para perfeccionar de manera continua las actividades de identificación, evaluación, planificación y control de riesgos en la comunidad.</li>
                        <li>Realizar modificaciones organizativas para la integración de la prevención.</li>
                        <li>Estructurar los equipos de responsables y seguir su funcionamiento.</li>
                        <li>Definir las funciones de los responsables.</li>
                        <li>Facilitar los derechos de información, consulta y participación de los trabajadores.</li>
                        <li>Designar recursos humanos y económicos a la Prevención.</li>
                    </ul>
                    <h5>2. De la persona responsable</h5>
                    <ul>
                        <li>Controlar el cumplimiento de la Normativa de Prevención.</li>
                        <li>Colaborar con el Servicio de Prevención para la realización de la Identificación de riesgos y la siguiente Planificación.</li>
                        <li>Ejecutar las medidas correctoras y/o preventivas, asignar su ejecución y responsables, encargados, jefes de área o mantenimiento de las diferentes secciones/departamentos de la comunidad.</li>
                        <li>Seguir la evolución en todos los casos.</li>
                        <li>Coordinar las actividades preventivas con otras empresas o profesionales contratados (RD 171/2004) o con personal puesto a disposición por ETT (RD 216/1999).</li>
                        <li>Participar en la elaboración de procedimientos e instrucciones de trabajo, velando por el cumplimiento de los mismos.</li>
                        <li>Planificar y organizar las tareas de su ámbito, integrando los aspectos preventivos.</li>
                        <li>Investigar los accidentes e incidentes ocurridos en su área de trabajo y aplicar las medidas preventivas necesarias para evitar su repetición.</li>
                        <li>Aplicar según sus posibilidades, las medidas preventivas y sugerencias de mejora que propongan los trabajadores.</li>
                        <li>La coordinación de actividades preventivas en su centro de trabajo.</li>
                        <li>La paralización de aquellos trabajos en circunstancias de riesgo grave e inminente que afecten a la seguridad y salud de los trabajadores. </li>
                    </ul>
                    <h5>3.	Del Servicio de Prevención</h5>
                    <ul>
                        <li>Asesoramiento al empresario para la efectiva integración de la prevención en la comunidad.</li>
                        <li>Asesoramiento para la implantación de un sistema de gestión de la prevención.</li>
                        <li>Realización de la identificación de riesgos y evaluación.</li>
                        <li>Propuesta de medidas correctoras y de la planificación de la prevención a partir de la evaluación.</li>
                        <li>Priorizar la actuación preventiva.</li>
                        <li>Colaboración con los responsables designados y los delegados de prevención para una eficaz implantación de la prevención.</li>
                        <li>Memoria anual de las actividades preventivas con referencia a las actividades efectivamente realizadas dentro de las planificadas.</li>
                        <li>Otras actuaciones según dispone el art. 31 de la Ley 31/1995, de 8 de noviembre, de LPRL y su reforma, la Ley 54/2003, y el RD 39/1997 Reglamento de los Servicios de Prevención, modificado por el RD 604/2006 y el RD 337/2010.</li>
                    </ul>
                    <div className="pagebreak"></div>
                    <h4>Prácticas, procedimientos y procesos</h4>
                    <h5>1. Consulta y participación de los trabajadores.</h5>
                    <p>
                        Los trabajadores tienen derecho a participar en las cuestiones relacionadas con la Prevención de Riesgos Laborales. Por eso, los representantes del personal tienen que ejercer las competencias que las normas establezcan en materia de información, consulta, negociación y vigilancia y control. <br />
                        El empresario ha de consultar a los trabajadores, con la debida antelación y a través de los Delegados de Prevención, la adopción de las decisiones relativas a:
                    </p>
                    <ul>
                        <li>La planificación y la organización del trabajo en la comunidad y la introducción de nuevas tecnologías, en todo aquello relacionado con las consecuencias que estas puedan tener en la Seguridad y la Salud de los trabajadores, derivadas de la elección de equipos, la determinación y la adecuación de las condiciones de trabajo y el impacto de los factores ambientales en el trabajo. </li>
                        <li>La organización y desarrollo de las actividades de protección de la salud y prevención de los riesgos profesionales en la comunidad.</li>
                        <li>La designación de los trabajadores encargados de las medidas de emergencia.</li>
                        <li>Los procedimientos de información y documentación a que hacen referencia los artículos 18.1 y 23.1 de la Ley de Prevención de Riesgos Laborales y su reforma, la Ley 54/2003.</li>
                        <li>La organización de la formación en materia preventiva.</li>
                        <li>Cualquier otra acción que pueda tener efectos sustanciales sobre la seguridad y  salud de los trabajadores.</li>
                    </ul>
                    <div className="pagebreak"></div>
                    <h4>Derechos de los trabajadores</h4>
                    <h5>Derechos generales</h5>
                    <p>El personal de la comunidad tiene los derechos que indica la Ley de PRL y su reforma, la Ley 54/2003, en materia de seguridad y salud, y que se resumen a continuación:</p>
                    <ul>
                        <li>Derecho a una protección eficaz en materia de seguridad y salud en el trabajo (art.14.1).</li>
                        <li>Derecho a que el coste de las medidas relativas a la seguridad y salud no deberá recaer sobre los trabajadores (art. 14.5).</li>
                        <li>Derecho a no ser destinado a una zona de riesgo grave y específico sin haber sido informado debidamente (art. 15.3).</li>
                        <li>Derecho a ser informado de forma directa e individualizada de los riesgos específicos de su puesto de trabajo y de las medidas de protección y prevención de los mencionados riesgos, así como de las medidas de emergencia existentes (art. 18.1).</li>
                        <li>Derecho a ser consultados y participar en las cuestiones que afecten a las condiciones de seguridad y salud (art. 18.2).</li>
                        <li>Derecho a recibir formación en materia preventiva (art. 19).</li>
                        <li>Derecho a interrumpir la actividad y a abandonar el puesto de trabajo en caso de riesgo grave e inminente (art. 21).</li>
                        <li>Derecho a la vigilancia periódica del estado de la salud, respetando siempre el derecho a la intimidad, dignidad de la persona del trabajador y la confidencialidad de toda la información relacionada con su estado de salud (art. 22).</li>
                        <li>Derecho a la protección específica de los trabajadores especialmente sensibles a determinados riesgos (art. 25).</li>
                        <li>Protección de la maternidad. Derecho a la adaptación de las condiciones o tiempos de trabajo a las necesidades de las trabajadoras embarazadas o en situación de parto reciente (art. 26).</li>
                        <li>Protección a los menores (art. 27).</li>
                        <li>Protección de los trabajadores con contratos temporales o de los contratados por empresas de trabajo temporal (art. 28).</li>
                    </ul>
                    <h4>Obligación de los trabajadores</h4>
                    <h5>Obligaciones generales</h5>
                    <p>
                        De acuerdo con el principio de integración de la actividad preventiva, todo trabajador de la comunidad que tenga personal a sus órdenes es responsable de la seguridad y salud de estos, por lo que debe conocer y hacer que se cumplan todas las reglas de prevención que afecten al trabajo que realicen.<br />
                        Cada trabajador debe cumplir las obligaciones establecidas en el artículo 29 de la Ley de Prevención de Riesgos Laborales que se indican a continuación:
                    </p>
                    <ul>
                        <li>Velar, según sus posibilidades y mediante el cumplimiento de las medidas de prevención que en cada caso sean adoptadas, por su propia seguridad y salud en el trabajo y por la de aquellos compañeros a las que pueda afectar su actividad profesional.</li>
                        <li>Usar adecuadamente, de acuerdo con su naturaleza y los riesgos previsibles, las máquinas, aparatos, herramientas, sustancias peligrosas, equipos de transporte y, en general, cualquier otro medio con los que se desarrolle su actividad.</li>
                        <li>Utilizar correctamente los medios y equipos de protección facilitados por el empresario, de acuerdo con las instrucciones recibidas de éste.</li>
                        <li>No poner fuera de servicio y utilizar correctamente los dispositivos de seguridad existentes.</li>
                        <li>Informar inmediatamente a su superior jerárquico directo y a los trabajadores designados sobre cualquier situación que, a su juicio, comporte un riesgo para la seguridad y la salud de los trabajadores.</li>
                        <li>Contribuir al cumplimiento de las obligaciones establecidas por la autoridad competente con el fin de proteger la seguridad y la salud de los trabajadores en el trabajo.</li>
                        <li>Cooperar con el empresario para que éste pueda garantizar unas condiciones de trabajo que sean seguras y no entrañen riesgos para la seguridad y la salud de los trabajadores.</li>
                    </ul>
                    <p>
                        El artículo 29 de la LPRL indica textualmente, en el punto 3: <br />
                        "El incumplimiento por los trabajadores de las obligaciones en materia de prevención de riesgos a que se refieren los apartados anteriores tendrá la consideración de incumplimiento laboral a los efectos previstos en el artículo 58.1 del Estatuto de los Trabajadores o de falta, en su caso, conforme a lo establecido en la correspondiente normativa sobre régimen disciplinario de los funcionarios públicos o del personal estatutario al servicio de las Administraciones Públicas. Lo dispuesto en este apartado será igualmente aplicable a los socios de las cooperativas cuya actividad consista en la prestación de su trabajo, con las precisiones que se establezcan en sus Reglamentos de Régimen Interno."
                    </p>
                    <h4>Recomendaciones generales de seguridad</h4>
                    <p>
                        Cuando alguno de los trabajadores que desarrollan su actividad en los puestos de trabajo objeto de la presente evaluación, ocupe, aunque sea puntualmente, otro puesto de trabajo distinto al suyo, se encontrará expuesto a los riesgos identificados en la evaluación que le corresponda al puesto de trabajo concreto, aunque la exposición al riesgo existente será diferente.<br />
                        Como medida de protección general recomendamos mantener los equipos de extinción de incendios existentes y, en su caso los recomendados en este informe, asegurándose de que son sometidos, como mínimo, a las revisiones obligatorias y de que se encuentran siempre en perfectas condiciones de uso.<br />
                        Sería recomendable disponer, en algún lugar visible junto a un teléfono, un cartel en el que se indiquen los números de teléfono de emergencias 112, mutua de accidentes y centro médico de urgencias más cercano.<br />
                        Por último, la comunidad debe disponer de material para primeros auxilios en caso de accidente, que deberá ser adecuado, en cuanto a su cantidad y características, al número de trabajadores, a los riesgos a que estén expuestos y a las facilidades de acceso al centro de asistencia médica más próximo. El material de primeros auxilios deberá adaptarse a las atribuciones profesionales del personal habilitado para su prestación. La situación o distribución del material en el lugar de trabajo y las facilidades para acceder al mismo y para, en su caso, desplazarlo al lugar del accidente, deberán garantizar que la prestación de los primeros auxilios pueda realizarse con la rapidez que requiera el tipo de daño previsible. El botiquín debe contener desinfectantes y antisépticos autorizados, gasas estériles, algodón hidrófilo, venda, esparadrapo, apósitos adhesivos, tijeras, pinzas y guantes desechables. El material de primeros auxilios se revisará periódicamente y se irá reponiendo tan pronto como caduque o sea utilizado.<br />
                        En el caso de que existan representantes legales de los trabajadores, la empresa deberá hacerles partícipes en todas las actuaciones que en materia de Prevención y Salud Laboral se lleven a cabo. Asimismo, toda la documentación que se genere como consecuencia de dichas actuaciones, deberá conservarse y estar a disposición de la autoridad competente cuando lo solicite.<br />
                    </p>
                    <div className="pagebreak"></div>
                    <h4>Otras recomendaciones generales de seguridad</h4>
                    <h5>1. Iluminaciónn de emergencia a la saluda de cada sala</h5>
                    <p>En caso de avería de la iluminación, las vías y salidas de evacuación que requieran iluminación deberán estar equipadas con iluminación de seguridad de suficiente intensidad. (Apdo. 10.9.º del ANEXO I, del RD 486/1997).</p>
                    <h5>2.Instalaciones higiénicas</h5>
                    <p>
                        2.1. Los lugares de trabajo dispondrán de vestuarios cuando los trabajadores deban llevar ropa especial de trabajo y no se les pueda pedir, por razones de salud o decoro, que se cambien en otras dependencias. (Apdo. 2.1.º del ANEXO V, del RD 486/1997).<br />
                        2.2. Los vestuarios estarán provistos de asientos y de armarios o taquillas individuales con llave, que tendrán la capacidad suficiente para guardar la ropa y el calzado. Los armarios o taquillas para la ropa de trabajo y para la de calle estarán separados cuando ello sea necesario por el estado de contaminación, suciedad o humedad de la ropa de trabajo. (Apdo. 2.2.º del ANEXO V, del RD 486/1997).<br />
                        Cuando los vestuarios no sean necesarios, los trabajadores deberán disponer de colgadores o armarios para colocar su ropa. (Apdo. 2.3.º del ANEXO V, del RD 486/1997).<br />
                        Los lugares de trabajo dispondrán, en las proximidades de los puestos de trabajo y de los vestuarios, de locales de aseo con espejos, lavabos con agua corriente, caliente si es necesario, jabón y toallas individuales u otro sistema de secado con garantías higiénicas. Dispondrán además de duchas de agua corriente, caliente y fría, cuando se realicen habitualmente trabajos sucios, contaminantes o que originen elevada sudoración. En tales casos, se suministrarán a los trabajadores los medios especiales de limpieza que sean necesarios. (Apdo. 2.4.º del ANEXO V, del RD.486/1997).<br />
                        Si los locales de aseo y los vestuarios están separados, la comunicación entre ambos deberá ser fácil. (Apdo.2.5.º del ANEXO V, del RD 486/1997).<br />
                        Los lugares de trabajo dispondrán de retretes, dotados de lavabos, situados en las proximidades de los puestos de trabajo, de los locales de descanso, de los vestuarios y de los locales de aseo, cuando no estén integrados en estos últimos. (Apdo. 2.6.º del ANEXO V, del RD 486/1997).<br />
                        Los retretes dispondrán de descarga automática de agua y papel higiénico. En los retretes que hayan de ser utilizados por mujeres se instalarán recipientes especiales y cerrados. Las cabinas estarán provistas de una puerta con cierre interior y de una percha. (Apdo. 2.7.º del ANEXO V, del RD 486/1997).<br />
                        Las dimensiones de los vestuarios, de los locales de aseo, así como las respectivas dotaciones de asientos, armarios o taquillas, colgadores, lavabos, duchas e inodoros, deberán permitir la utilización de estos equipos e instalaciones sin dificultades o molestias, teniendo en cuenta en cada caso el número de trabajadores que vayan a utilizarlos simultáneamente. (Apdo. 2.8.º del ANEXO V, del RD 486/1997).<br />
                        Los locales, instalaciones y equipos mencionados en el apartado anterior serán de fácil acceso, adecuados a su caso y de características constructivas que faciliten su limpieza. (Apdo. 2.9.º del ANEXO V, del RD 486/1997).
                    </p>
                    <div className="pagebreak"></div>
                    <h4>Formación de los trabajadores</h4>
                    <p>
                        Una persona correctamente formada e informada sobre su tarea, el entorno en el que se desarrolla, los medios que utiliza y los riesgos que comporta la ocupación de estos, se considera menos expuesta a riesgos que otra persona cuya formación es incorrecta.<br />
                        Por tanto, la empresa ha de posibilitar que cada trabajador reciba una formación teórica y práctica, suficiente y adecuada en materia preventiva, tal como indica el artículo 19 de la Ley de Prevención de Riesgos Laborales, y su reforma, la Ley 54/2003, tanto en el momento de su contratación como cuando se produzcan cambios en las funciones que ejerza o se introduzcan nuevas tecnologías o cambios en los equipos de trabajo.<br />
                        La mencionada formación se centra específicamente en el lugar de trabajo o función de cada trabajador, y se adapta a la evaluación de los riesgos y a la aparición de otros nuevos y se han de repetir periódicamente siempre que sea necesario. <br />
                        El Plan de formación incluye:
                    </p>
                    <ul>
                        <li>Formación general para todo el personal de la empresa.</li>
                        <li>Formación de personal de nuevo ingreso.</li>
                        <li>Formación en materias específicas:
                            <ul>
                                <li>Manipulación de cargas</li>
                                <li>Incendios</li>
                                <li>Primeros auxilios</li>
                                <li>Plan de emergencia</li>
                                <li>Otros riesgos específicos identificados</li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        Se llevará a cabo según la planificación de la actividad preventiva.<br />
                        El trabajador recibirá un certificado acreditativo de su asistencia a la formación.
                    </p>
                    <h4>Información, consulta y participación de los trabajadores</h4>
                    <p>La comunidad dará a conocer a su personal: </p>
                    <ul>
                        <li>La política de prevención de la comunidad.</li>
                        <li>Los riesgos para la seguridad y la salud de los trabajadores.</li>
                        <li>Las medidas y actividades de protección y prevención aplicables a los riesgos señalados en el apartado anterior.</li>
                        <li>Las medidas adoptadas en materia de primeros auxilios, de lucha contra incendios y evacuación.</li>
                    </ul>
                    <p>
                        Esta INFORMACIÓN se ha de proporcionar también a las empresas contratistas que realizan trabajos en esta comunidad, tal y como se indica en el documento "Coordinación de actividades empresariales". <br />
                        La CONSULTA y PARTICIPACIÓN es uno de los deberes empresariales de cumplimiento que se llevará a cabo a través de los órganos de representación especializada, es decir, los Delegados de Prevención. Se organizarán unas reuniones trimestrales para hacer una puesta en común de la problemática que pueda haber surgido en un momento dado o cualquier duda de carácter personal. <br />
                    </p>
                    <div className="pagebreak"></div>
                    <h4>Protección de trabajadores de especial significación</h4>
                    <h5>Protección de trabajadores especialmente sensibles a determinados riesgos:</h5>
                    <p>
                        Las evaluaciones de riesgo han de considerar de forma específica aquellos trabajadores especialmente sensibles a determinados riesgos y, en función de éstos, se han de adoptar las medidas preventivas y de protección necesarias. <br />
                        La comunidad ha de garantizar de manera específica la protección de los trabajadores que, por sus propias características personales o estado biológico conocido, incluyendo aquellos que tengan reconocida la situación de discapacidad física, psíquica o sensorial, sean especialmente sensibles a los riesgos derivados del trabajo.<br />
                        Los trabajadores no han de ser empleados en aquellos lugares de trabajo en los que, a causa de sus características personales, estado biológico o por su discapacidad física, psíquica o sensorial debidamente reconocida, puedan ellos, los otros trabajadores u otras personas relacionadas con la comunidad, ponerse en situación de peligro o, en general, cuando se encuentren manifiestamente en estados o situaciones transitorias que no responden a las exigencias psicofísicas de los respectivos lugares de trabajo. <br />
                        Igualmente, la comunidad ha de tener en cuenta en las evaluaciones, los factores de riesgo que puedan incidir en la función de procreación de los trabajadores y trabajadoras, en particular por la exposición a agentes físicos, químicos y biológicos que puedan ejercer efectos mutagénicos o de toxicidad para la procreación, tanto en los aspectos de la fertilidad, como del desarrollo de la descendencia, a fin de adoptar medidas preventivas necesarias.
                    </p>
                    <h5>Protección de la maternidad:</h5>
                    <p>Las evaluaciones de riesgos han de incluir la determinación de la naturaleza, el grado y la duración de la exposición de las trabajadoras en situación de embarazo o parto reciente a agentes, procedimientos o condiciones de trabajo que puedan influir negativamente en la salud de las trabajadoras o del feto, en cualquier actividad susceptible de presentar un riesgo específico, según el artículo 26 de la LPRL, y su reforma, la Ley 54/2003.</p>
                    <h5>Protección de los menores:</h5>
                    <p>Las evaluaciones de riesgos han de incluir la prohibición de trabajos de menores, según el Decreto de 26 de julio de 1957, en el cual se establecen las limitaciones en relación a la actividad e industria, motivos de la prohibición y, en su caso, condiciones particulares de prohibición.</p>
                    <h4>Equipos de trabajo, máquinas e instalaciones</h4>
                    <p>
                        Los equipos de trabajo se definen como cualquier máquina, aparato, instrumento o instalación utilizada en el trabajo.<br />
                        Según establece el RD 1215/1997, se han de adoptar las medidas necesarias para que los equipos de trabajo sean adecuados para el trabajo que haya de realizarse y convenientemente adaptados al efecto, de forma que garanticen la seguridad y la salud de los trabajadores al utilizarlos.<br />
                        Si la utilización de un equipo de trabajo puede presentar un riesgo específico para la seguridad y la salud de sus trabajadores, la comunidad ha de adoptar las medidas necesarias para que:<br />
                    </p>
                    <ul>
                        <li>La utilización del equipo de trabajo quede reservada a los trabajadores con la cualificación suficiente para utilizarlos.</li>
                        <li>Los trabajos de reparación, transformación, mantenimiento o conservación sean realizados por los trabajadores específicamente capacitados para eso.</li>
                    </ul>
                    <p>
                        Cuando, con el objeto de evitar o controlar un riesgo específico para la seguridad o la salud de los trabajadores, la utilización de un equipo de trabajo tenga que efectuarse en condiciones o formas determinadas, que requieran un particular conocimiento por parte de aquellos, la comunidad ha de adoptar las medidas necesarias para que la utilización del mencionado equipo quede reservada a los trabajadores designados para aquella función.<br />
                        Todos los equipos de trabajo se han de adecuar a lo que establece el RD 1215/1997, de acuerdo con las instrucciones del cual las empresas han de conformar las máquinas, los equipos y las herramientas. De esta manera:<br />
                    </p>
                    <h5>1. Máquinas, equipos e instalaciones:</h5>
                    <p>a) Máquinas de fabricación propia o ajena posteriores al 1 de enero de 1995, verificación del cumplimiento del RD 1435/1992 y del 56/1995:</p>
                    <ul>
                        <li>Marcado CE </li>
                        <li>Declaración CE de conformidad</li>
                        <li>Manual de instrucciones de seguridad en español</li>
                    </ul>
                    <p>b) Situaciones especiales:</p>
                    <ul>
                        <li>Si una máquina fabricada con posterioridad a 1 de enero de 1995 con marcado CE, sufriera alguna modificación sustancial que afecte a las condiciones que otorgaron el certificado, ha de certificarse según aquello que se ha indicado en el apartado a) considerando las modificaciones realizadas.</li>
                        <li>Cuando diversas máquinas con marcado CE se asocien en una línea productiva con funcionamiento solidario, el conjunto tiene la consideración de máquina y ha de certificarse de acuerdo a lo indicado en el apartado a) para las nuevas condiciones de funcionamiento.</li>
                    </ul>
                    <h5>2. Alquiler de equipos de trabajo:</h5>
                    <p>- Exigencia del cumplimiento de los apartados anteriores según el año de fabricación y las características de la máquina o equipo. Se ha de solicitar al proveedor una copia de la documentación relativa a seguridad (copia de la declaración CE de conformidad y marcado CE, o copia del informe de adecuación al RD 1215/1997 y copia del manual de seguridad en ambos casos).</p>
                    <h4>Sustancias peligrosas</h4>
                    <p>
                        Las sustancias y preparados químicos generan riesgos que es necesario evaluar y controlar. Las consecuencias de la materialización de estos riesgos afectan a las personas, los bienes y el medio ambiente. <br />
                        Su evaluación ayuda a concebir adecuadamente el espacio de trabajo. Se deben señalar los riesgos mediante los pictogramas adecuados, respetar los valores límites de exposición profesional a las sustancias peligrosas, eliminar los productos utilizados cuando sea posible y utilizar la protección adecuada cuando se realicen trabajos que representen un riesgo para la salud y la seguridad.<br />
                        Para llevar a cabo las actuaciones anteriores es necesario realizar una relación e identificación de sustancias y preparados peligrosos según el RD sobre notificación de sustancias nuevas y clasificación, envasado y etiquetado de substancias peligrosas (RD 363/1995 y posteriores modificaciones).<br />
                        Todas las sustancias con propiedades peligrosas, se han de embalar y etiquetar provisionalmente según las normas y criterios establecidos. Este etiquetado provisional es válido hasta que se incluya la sustancia o hasta que se decida no incluirla si no se le considera peligrosa.<br />
                        La peligrosidad de una sustancia o preparado no solo la determina el pictograma sino también las frases de riesgos (frases R), que nos indican el tipo de riesgo y las medidas que han de adoptarse para prevenirlo (frases S).<br />
                        El almacenamiento de estas sustancias se tiene que hacer de acuerdo a su peligrosidad, teniendo en cuenta las incompatibilidades de almacenamiento. Por eso, es muy importante obtener y transmitir información sobre las propiedades de las sustancias (fichas de seguridad y etiquetado), establecer reglas de trabajo y confeccionar y actualizar el plan de almacenamiento.<br />
                        La persona responsable del departamento ha de informar en todo momento al personal sobre los riesgos y medidas de seguridad que han de tenerse en cuenta para la manipulación de estas sustancias y preparados.<br />
                    </p>
                    <h4>Equipos de protección individual</h4>
                    <p>
                        En los casos en que la actividad requiera la utilización de equipos de protección individual, la comunidad tiene que proporcionarlos al personal afectado, quienes son responsables de su utilización, atención y, si es necesario, de su mantenimiento. La posible pérdida o rotura ha de ser comunicada a su superior inmediato con la finalidad de sustituirlo, así como si se observa alguna deficiencia en este o una incomodidad excesiva.<br />
                        A la entrega del equipo de protección individual (EPI), la persona ha de firmar una notificación de recepción del equipo (Anexo I). La comunidad ha de mantener un registro de los EPI entregados.<br />
                        Los equipos de protección individual han de disponer del correspondiente marcado CE y de las instrucciones para su correcta utilización y mantenimiento.<br />
                    </p>
                    <h4>Señalización</h4>
                    <p>
                        La comunidad ha de velar para que la señalización de seguridad y salud sea correcta en los lugares de trabajo.<br />
                        Los criterios para el uso de la señalización son los siguientes:
                    </p>
                    <ul>
                        <li>Llamar la atención de los trabajadores sobre la existencia de determinados riesgos, prohibiciones u obligaciones.</li>
                        <li>Alertar a los trabajadores cuando se produzca una determinada situación de emergencia que requiera medidas urgentes de protección o evacuación.</li>
                        <li>Facilitar a los trabajadores la localización e identificación de determinados medios o instalaciones de protección, evacuación, emergencia o primeros auxilios.</li>
                    </ul>
                    <div className="pagebreak"></div>
                    <h4>Coordinación de actividades empresariales</h4>
                    <p>
                        Cuando la comunidad contrate los servicios de una empresa y el personal de esta desarrolle su trabajo en la comunidad, se ha de realizar un intercambio de información y de instrucciones adecuadas en relación a los riesgos existentes y las medidas de protección y prevención correspondientes a cada una de las diferentes actividades.<br />
                        Así, la comunidad ha de proponer procedimientos de coordinación en materia de seguridad y salud con las empresas que prestan servicios en sus locales e instalaciones, para cumplir lo especificado en el artículo 24 de la Ley de Prevención de Riesgos Laborales y su reforma, la Ley 54/2003.<br />
                        La información y las instrucciones que se han de transmitir están relacionadas con:<br />
                    </p>
                    <ul>
                        <li>Los riesgos existentes en el centro de trabajo que afecten al subcontratista o trabajadores autónomos, así como sobre las posibles interferencias entre actividades.</li>
                        <li>Las medidas de protección y prevención correspondientes.</li>
                        <li>Las medidas de emergencia que se han de aplicar.</li>
                    </ul>
                    <p>
                        Información para las empresas que ejerzan actividades en la comunidad:<br />
                        En virtud de lo establecido en el RD 171/2004, de 30 de enero, por el que se desarrolla el artículo 24 de la Ley 31/1995, de 8 de noviembre, de Prevención de Riesgos Laborales, y su reforma, la Ley 54/2003, en materia de coordinación de actividades empresariales, cuando en un mismo centro de trabajo se desarrollen actividades con trabajadores de dos o más empresas, éstas deberán cooperar en la aplicación de la normativa de prevención de riesgos laborales:<br />
                    </p>
                    <ul>
                        <li>Las empresas deberán informarse recíprocamente sobre los riesgos específicos de las actividades que desarrollen en el centro de trabajo que puedan afectar a los trabajadores de las otras empresas concurrentes en el centro, en particular sobre aquellos que puedan verse agravados o modificados por circunstancias derivadas de la concurrencia de actividades.</li>
                        <li>La información deberá ser suficiente y deberá de proporcionarse antes del inicio de las actividades, cuando se produzca un cambio en las actividades concurrentes que sea relevante a efectos preventivos y cuando se haya producido una situación de emergencia. La información se facilitará por escrito.</li>
                        <li>Cuando, como consecuencia de los riesgos de las actividades concurrentes, se produzca un accidente de trabajo, el empresario deberá informar de aquél a los demás empresarios presentes en el centro de trabajo.</li>
                        <li>Los empresarios deberán comunicarse de inmediato toda situación de emergencia susceptible de afectar a la salud o la seguridad de los trabajadores de las empresas presentes en el centro de trabajo.</li>
                        <li>La información deberá ser tenida en cuenta por los empresarios concurrentes en el centro de trabajo en la evaluación de los riesgos y en la planificación de su actividad preventiva a las que se refiere el artículo 16 de la Ley 31/1995, de 8 de noviembre, de Prevención de Riesgos Laborales y su reforma, la Ley 54/2003.</li>
                        <li>Los empresarios habrán de considerar los riesgos que, siendo propios de cada empresa, surjan o se agraven precisamente por las circunstancias de concurrencia en que las actividades se desarrollan.</li>
                        <li>Cada empresario deberá informar a sus trabajadores respectivos de los riesgos derivados de la concurrencia de actividades empresariales en el mismo centro de trabajo en los términos previstos en el artículo 18.1 de la Ley 31/1995, de 8 de noviembre, de Prevención de Riesgos Laborales.</li>
                    </ul>
                    <div className="pagebreak"></div>
                    <h4>Comunicación con la comunidad</h4>
                    <p>Se ha de comunicar a la comunidad y en concreto al presidente:</p>
                    <ul>
                        <li>Cualquier situación anómala o peligrosa detectada.</li>
                        <li>Cualquier lesión producida durante la actividad, por leve que sea, antes de acabar la jornada, independientemente que la primera cura se realice en el botiquín. Se ha de informar de la misma manera en aquellos incidentes sin lesiones pero en los que podía haber habido.</li>
                        <li>Todo incendio o conato de incendio que se produzca. </li>
                    </ul>
                    <p>Así mismo, cualquier persona de la comunidad podrá efectuar propuestas para la mejora de los niveles de protección en cuanto a Seguridad y Salud de la comunidad.</p>
                    <h4>Plan de emergencia</h4>
                    <p>
                        El desarrollo e implantación del plan de emergencia o autoprotección a la comunidad, analiza las posibles situaciones de emergencia, y se adoptan las medidas necesarias en materia de primeros auxilios, lucha contra incendios y evacuación de los trabajadores, para lo que se designa el personal encargado de poner en práctica estas medidas y establecen periodos de comprobación del correcto funcionamiento tanto de los medios como de los equipos, así como la organización de las relaciones necesarias con los servicios externos, en particular en materia de primeros auxilios, asistencia médica de urgencia, salvamento y lucha contra incendios, de forma que quede garantizada la rapidez y eficacia de éstas.<br />
                        Para el desarrollo del Plan de Autoprotección o Emergencia se siguen las directrices del Manual de Autoprotección, publicado en el BOE el 26 de febrero de 1985.<br />
                        Se ha de mantener actualizado el Plan de Emergencia y han de promoverse las acciones necesarias para implantarlo en cada centro de trabajo (comunicación, formación, simulacros, etc.).<br />
                    </p>
                    <h4>Vigilancia de la salud y asistencia sanitaria</h4>
                    <p>
                        Para cumplir el mandato legal de garantizar la vigilancia del estado de salud de los trabajadores, reflejado en el artículo 22.1 de la LPRL, y su reforma, la Ley 54/2003, la comunidad contará con un Servicio de Prevención o Servicio Médico concertado que cubra o concierte esta especialidad.<br />
                        Los reconocimientos médicos se llevan a cabo de acuerdo con los riesgos laborales a que pueda estar expuesto cada trabajador, y se realizan pruebas específicas en función de estos. Para este cometido el Servicio Médico cuenta con los resultados de las mediciones ambientales oportunas (ruido, etc.) realizados previamente, por los técnicos del Servicio de Prevención.<br />
                        Los resultados de la vigilancia se comunican a cada trabajador respetando su derecho a la intimidad y a la confidencialidad de toda la información relacionada con su estado de salud, mientras que a la comunidad se le notifica la aptitud de los trabajadores para su trabajo habitual.<br />
                        Esta vigilancia de la salud, solo puede llevarse a cabo cuando el trabajador preste su consentimiento, a excepción de aquellos caso en los que la realización del reconocimiento sea imprescindible para evaluar los efectos de las condiciones de trabajo sobre la salud de los trabajadores o para verificar si el estado de salud del trabajador puede constituir un peligro para éste, para otros trabajadores o para otras personas relacionadas con la comunidad. (Anexo II)
                    </p>
                    <h4>Investigación de accidentes, incidentes y enfermedades profesionales</h4>
                    <p>La comunidad con la ayuda del servicio de prevención deberá investigar, analizar y registrar las incidencias ocurridas durante la realización de la actividad, imputables o no a errores en el sistema de prevención de riesgos laborales, es decir:</p>
                    <ul>
                        <li>Incidentes.</li>
                        <li>Accidentes.</li>
                        <li>Enfermedades laborales.</li>
                        <li>Recomendaciones o requerimientos efectuados por organismos oficiales.</li>
                    </ul>
                    <p>
                        Cuando a consecuencia de un accidente de trabajo en la comunidad se haya producido un daño para la salud de los trabajadores, se debe realizar una investigación del accidente a fin de detectar las causas. De igual manera se deben investigar aquellos incidentes (suceso que no ha producido lesión pero que, en circunstancias ligeramente diferentes, podría haber dado lugar a un accidente) que se consideren de especial interés para la prevención de posibles accidentes con lesión. <br />
                        La comunicación de accidentes se debe realizar mediante el formulario correspondiente (Anexo III)<br />
                        Esta investigación sistemática de los accidentes/incidentes proporciona información sobre aspectos como:<br />
                    </p>
                    <ul>
                        <li>Identificación de nuevos riesgos.</li>
                        <li>Identificación de las causas que han provocado el accidente / incidente.</li>
                        <li>Identificación de la secuencia en la que se desarrollaron los acontecimientos.</li>
                        <li>Identificación de los medios de prevención inadecuados o insuficientes.</li>
                    </ul>
                    <p>
                        Este procedimiento lo lleva a cabo la comunidad, con la posible participación de los Delegados de Prevención y los responsables de cada uno de los servicios y departamentos. En aquellos accidentes que presenten consecuencias graves o mortales se debe complementar la investigación, si es necesario, con la intervención de técnicos externos. <br />
                        De esta forma las medidas preventivas que se adopten deberán orientarse adecuadamente sobre aquellos factores de riesgo que predominen en la comunidad, y deben garantizar una mayor eficacia en la intervención preventiva.<br />
                    </p>
                    <h4>Procedimientos, instrucciones y recomendaciones de seguridad y salud</h4>
                    <p>Todas las unidades organizativas de la comunidad deben cumplir la normativa, reglamentación e instrucciones obligatorias en materia de seguridad y  procurar cumplir las recomendaciones de seguridad que se establezcan.</p>
                    <div className="pagebreak"></div>
                    <h3>7. Objetivos y metas</h3>
                    <p><b>Elementos principales de los objetivos y metas a alcanzar</b></p>
                    <h4>Objetivos, funciones y responsabilidades del plan de prevención</h4>
                    <ul>
                        <li> Declarar la política de prevención de la comunidad y recoger la estructura soporte que garantice su aplicación. </li>
                        <li>Realizar periódicamente un análisis de la eficacia del sistema de gestión y en su caso establecer las medidas de carácter general que se requieran para adaptarlo a los principios marcados en la política preventiva</li>
                        <li>Fijar y documentar los objetivos y metas a tenor de la política preventiva.</li>
                        <li>Asegurar el cumplimiento de los preceptos contemplados en la normativa de aplicación.</li>
                        <li>Asegurar que la organización disponga de la formación necesaria para desarrollar las funciones y responsabilidades establecidas.</li>
                        <li>Establecer una modalidad organizativa de la prevención.</li>
                        <li>Definir los requisitos generales que deberá establecer la comunidad para garantizar la implantación y el funcionamiento del plan de prevención. </li>
                        <li>Definir las responsabilidades y las funciones, en materia de seguridad, de todos los niveles jerárquicos de la comunidad. </li>
                        <li>Establecer las competencias de cada nivel organizativo para el desarrollo de las actividades preventivas definidas en los procedimientos.</li>
                        <li>Establecer los mecanismos adecuados para asegurar el cumplimiento de la normativa y reglamentación vigente en materia de prevención de riesgos laborales. </li>
                        <li>Integrar los aspectos relativos al SST en el sistema general de gestión de la entidad.</li>
                        <li>Presentar e informar sobre el nivel de prevención alcanzado, así como de los objetivos y metas propuestos. </li>
                        <li>Servir de vehículo para la formación, la calificación y la motivación del personal, respecto a la prevención de riesgos laborales. </li>
                        <li>Favorecer la consulta y participación de los trabajadores conforme a los principios indicados en la normativa de aplicación</li>
                        <li>Consultar a los trabajadores en la adopción de decisiones que puedan afectar a la seguridad, salud y condiciones de trabajo.</li>
                        <li>Adoptar las acciones correctoras y preventivas necesarias para corregir las posibles desviaciones que se detecten en el Plan de Prevención.</li>
                        <li>Participar de forma proactiva en el desarrollo de la actividad preventiva que se desarrolla, a nivel de los lugares de trabajo, para poder estimular comportamientos eficientes, detectar deficiencias y demostrar interés por su solución.</li>
                        <li>Mostrar interés por los accidentes laborales acaecidos y por las medidas adoptadas para evitar su repetición.</li>
                        <li>Asignar los recursos necesarios, tanto humanos como materiales, para conseguir los objetivos establecidos.</li>
                        <li>Visitar periódicamente los lugares de trabajo para poder estimular comportamientos eficientes, detectar deficiencias y trasladar interés por su solución.</li>
                        <li>Suprimir todo riesgo eliminable.</li>
                        <li>Minimizar las consecuencias y probabilidad de ocurrencia de aquellos riesgos que no pueden ser eliminados.</li>
                        <li>Mantener unos altos niveles de salud y bienestar en el trabajo.</li>
                    </ul>
                    <div className="pagebreak"></div>
                    <h1 style={{ fontSize: 48, textAlign: 'center', paddingTop: 300 }}>ANEXOS</h1>
                    <div className="pagebreak"></div>
                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>ANEXO I</div>
                    <br />
                    <div className="pageTitle">Entrega de equipos de protección individual</div>
                    <p>Con el fin de dar cumplimiento a lo dispuesto por el artículo 17 de la Ley 31/1995 de 8 de Noviembre de Prevención de Riesgos Laborales, se le hace entrega de los siguientes equipos de protección individuales y “vestuario profesional”:</p>
                    <table className="simpleTable">
                        <tr>
                            <th>Tipo de Equipo</th>
                            <th>Fecha de Entrega</th>
                            <th colSpan={2}>Nombre y firma trabajador</th>
                        </tr>
                        <tr>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                        </tr>
                        <tr>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                        </tr>
                        <tr>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                        </tr>
                        <tr>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                        </tr>
                        <tr>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                        </tr>
                        <tr>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                        </tr>
                        <tr>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                        </tr>
                        <tr>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                        </tr>
                        <tr>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                            <td><br /></td>
                        </tr>
                    </table>
                    <div className="pagebreak"></div>
                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>ANEXO II</div>
                    <br />
                    <div className="pageTitle">Registro comunicación exámenes de salud</div>
                    <p>
                        {this.state.hojaVisita?.comunidad.razonSocial}<br />
                        Nombre y Apellidos:_________________________________________________________________________________________________<br />
                        Puesto de trabajo:_____________________________________________________________ DNI:_________________________________
                    </p>
                    <br />
                    <hr />
                    <br />
                    <p>
                        De acuerdo a lo establecido en el artículo 22 de la Ley 31/1995 de 8 de noviembre sobre Prevención de Riesgos Laborales, la comunidad garantiza a sus trabajadores la vigilancia periódica de su estado de salud en función de los riesgos inherentes al puesto de trabajo.<br />
                        Esta Vigilancia de la Salud garantizada por la comunidad a través del Servicio de Prevención Ajeno, estará sometida a protocolos específicos con relación a los riesgos laborales a los que se encuentra expuesto el trabajador en su puesto de trabajo (art. 37, R.D. 39/97 de Reglamento de los Servicios de Prevención).<br />
                        Los resultados de la vigilancia de su salud le serán comunicados con la preceptiva confidencialidad de los datos médicos y con acuse de recibo.<br />
                        El puesto de trabajo a desarrollar <u>no comporta la realización con carácter obligatorio del reconocimiento médico</u>, por lo que le ruego sírvase firmar la aceptación o renuncia al mismo, para que se pueda planificar su realización.<br />
                    </p>
                    <table>
                        <tr>
                            <td style={{ width: '84%' }}>SI deseo realizar el reconocimiento médico </td>
                            <td style={{ width: '13%' }}>Acepto</td>
                            <td className="tdAnexo"></td>
                        </tr>
                        <tr>
                            <td>NO deseo realizar el reconocimiento médico</td>
                            <td>Renuncio</td>
                            <td className="tdAnexo"></td>
                        </tr>
                    </table>
                    <br />
                    <p>
                        Atentamente,<br />
                        {this.state.hojaVisita?.comunidad.razonSocial}
                    </p>
                    <br /><br />
                    <p>
                        <span style={{ marginRight: 175 }}>Fecha:___/___/_____</span>
                        <span>Firma del trabajador:________________________</span>
                    </p>
                    <div className="pagebreak"></div>
                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>ANEXO III</div>
                    <br />
                    <div className="pageTitle">Comunicado de accidentes de trabajo a la comunidad</div>
                    <table style={{ width: '100%', marginBottom: 45, marginTop: 55 }}>
                        <tr>
                            <td>Fecha:_____________________</td>
                            <td style={{ textAlign: 'right' }}>Comunidad de propietarios: {this.state.hojaVisita?.comunidad.razonSocial}</td>
                        </tr>
                    </table>
                    <p>
                        En cumplimiento a lo establecido en el art. 4.2 del Real Decreto 171/2004, de 30 de enero, por el que se desarrolla el artículo 24 de la Ley 31/1995, de 8 de noviembre, de Prevención de Riesgos Laborales, en materia de coordinación de actividades empresariales  , se le/s notifica como centro de trabajo:<br />
                        En el día de ________________, el trabajador D/Dña.:______________________________ de la empresa____________________________________ ha sufrido un accidente laboral el centro de trabajo.<br />
                        Se pondrá a su disposición, según el artículo 18 de la Ley 31/95, la investigación interna, que según el apartado 2 del art. 16 de la citada Ley de Prevención de Riesgos Laborales se llevará a cabo en el plazo de tiempo más breve posible.<br />
                        Atentamente,<br />
                        Fdo.:_________________
                        <div style={{ height: 50 }}></div>
                        Fdo.: {this.state.hojaVisita?.comunidad.razonSocial}
                    </p>
                </tbody>
            </table>
            <div className="pdf-header">
                <img src={LogoNF} alt="Logo NF" />
            </div>
            <style jsx>{`
            .tablePage{
                width: 100%;
            }
            .pdf-header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                position: fixed;
                top: 0;
                background-color: #ffffff
            }
            .header-space, .pdf-header {
                height: 90px;
                
            }
            .pdf-header img {
                height: 60px
            }
            div {
                font-size: 10px
            }
            h1 {
                margin-top: 100px;
                margin-bottom: 60px;
                font-size: 20px;
            }
            h2 {
                font-size: 18px;
                font-weight: 400;
            }
            h3 {
                width: 100%;
                padding: 5px 0;
                text-transform: uppercase;
                border-bottom: 1px solid #000000;
                margin-top: 25px;
                margin-bottom: 15px;
                font-size: 14px;
            }
            h4 {
                width: 100%;
                padding: 5px 10px;
                background-color: #f8f8f8;
                text-transform: uppercase;
                border: 1px solid #e6e6e6;
                margin-bottom: 10px;
                margin-top: 20px;
                font-size: 13px;
            }
            h5 {
                font-size: 12px;
                margin: 20px 0 10px 0;
                text-decoration: underline
            }
            .textBox {
                border: 2px solid #e6e6e6;
                padding: 15px;
                margin-top: 15px;
            }
            .tableRiesgos {
                border-collapse: collapse;
                width: 100%;
                table-layout: fixed;
                margin-bottom: 24px;
                
            }
            .simpleTable th, .simpleTable td, .tableRiesgos th, .tableRiesgos td {
                border: 1px solid #000000;
                padding: 10px;
                line-height: 1.1
            }
            .tableRiesgos th {
                background-color: #375491;
                color: #ffffff;
                font-size: 12px;
                font-weight: bold;
            }
            .simpleTable {
                border-collapse: collapse;
                width: 100%;
                margin: 24px 0 24px 0;
            }
            .simpleTable th {
                background-color: #f8f8f8;
            }
            .simpleTable br {
                margin: 36px;
            }
            ul {
                line-height: 1.8
            }
            p {
                line-height: 1.8;
                text-align: justify;
            }
            br {
                display: block;
                margin: 24px 0;
                line-height:22px;
                content: " ";
            }
            .pageTitle {
                text-align: center;
                text-decoration: underline;
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 20px;
                text-transform: uppercase
            }
            @page {
                size: A4 portrait;
                margin: 2cm
            }
            .tdAnexo{
                border: solid 1px black;
                width: 15px;
            }
        `}</style>
        </>
    }
}

export default withRouter(PlanPrevencionRender);
