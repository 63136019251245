import { auth  } from "../providers/firebase";
import React from "react";
import "./Login.css"
import "./PasswordSet.css"
import BackgroundImage from "../images/loginFondo.jpg"
import Tick from "../icons/tick.svg"
import Logo from "../images/Logo.png"
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

class cambiarPassword extends React.Component {
  constructor() {
    super()
    this.state = {
      newPassword: "",
      repeatNewPassword: "",
      validCode: undefined,
      oobCode: null,
      mode: null,
      success: false,
      email:""
    }
  }
  componentDidMount() {
        
  }
  enviarEmail(e){
    e.preventDefault()
    
    if(this.state.email!==""){
      const email = this.state.email
      auth.sendPasswordResetEmail(email)
        .then(() => {
          console.log("Email enviado")
          this.setState({ success: true })
        })
        .catch((error) => {
          
          //const errorCode = error.code;
          //const errorMessage = error.message;
          if(error.code === "auth/invalid-email"){
            toast.error("El email no esta bien formado")
          }else if(error.code === "auth/user-not-found"){
            toast.error("El email no existe")
          }else{
            toast.error("Ha ocurrido un error")
          }
        });
    }
  }
  render() {
    if (this.state.success) {
      return (
        <div className="successMsg">
          <img src={Tick} alt="Correcto" />
          Email enviado con éxito. Recibirás un email con un enlace para poder cambiar la contraseña.
        </div>
      )
    }
    return ( //Valid Code
      <>
      <div style={{ backgroundImage: `url(${BackgroundImage})` }} className="backgroundDiv">
        <div className="overlay"></div>
        <form className="loginForm" onSubmit={e => this.enviarEmail(e)} style={{border: "1px solid #c1bdbd"}}>
          {/* <div>Enviar Email</div> */}
          <div><img src={Logo} className="loginLogo" alt="Logo" /></div>
          <label htmlFor="email" style={{margin: '0 30px 20px 30px'}}>Inserta el email de usuario</label>
          <input type="email" required minLength="6" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
          <button type="submit">Enviar</button>
          <Link to="/login">
            <div style={{
                        backgroundColor: "rgb(255, 255, 255)",
                        color: "rgb(48, 50, 72)",
                        margin: "10px auto 0",
                        padding: "8px 30px 0",
                        fontWeight: "normal",
                        fontSize: "10px"
                  }}>Iniciar sesión
            </div>
          </Link>
        </form>
      </div>
    </>
    );
  }
}

export default cambiarPassword;
