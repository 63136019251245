import React, {useEffect, useState, useCallback} from "react";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { Link } from "react-router-dom";
import { firestore, auth } from "../../../providers/firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import Tick from "../../../icons/tick.svg"
import Cross from "../../../icons/cross.svg"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { abrirArchivo, subirDocumentacionProveedor, subirDeclaracionResponsable } from "../../../providers/documentos";
import { toast } from "react-toastify";
import Swal from 'sweetalert2'
const CustomSwitch = withStyles({
    switchBase: {
      color: "#FF5252",
      '&$checked': {
        color: "#7bc977",
      },
      '&$checked + $track': {
        backgroundColor: "#7bc977",
      },
    },
    checked: {},
    track: {
      backgroundColor: "#FF5252"
    },
  })(Switch);
  
  const CustomPendienteSwitch = withStyles({
    switchBase: {
      color: "#FFD700"
    },
    checked: {},
    track: {
      backgroundColor: "#FFD700"
    },
  })(Switch);

const AlertasProveedores = (props) => {
    
    const [proveedores, setProveedores] = useState([]);
    const [listaOriginal, setListaOriginal] = useState([]);
    const [tecnico, setTecnico] = useState({});
    const [aaff, setAaff] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [modalEditarOpened, setModalEditarOpened] = useState(false);
    const [filtrarConsultor, setFiltrarConsultor] = useState('')
    const [dialog, setDialog] = useState({
        opened: false,
        title: "",
        docName: "",
        fechaCaducidad: undefined,
      },)
    const [subirDocumentacion, setSubirDocumentacion] = useState({
        opened: false,
        nombreDoc: undefined,
        fecha: undefined,
        proveedor: undefined
      },)
    const [verDocumentacion, setVerDocumentacion] = useState({
        opened: false,
        nombreDoc: undefined,
        proveedor: undefined
      },)
    
    const getProveedores = ( () => {
        // console.log(props)
        let provs = []
        let promises1 = []
        let promises = []
        let nifProveedores = []
        let aaffs = {}
        let nifAAFF = []
        let fincas = []
        promises1.push(firestore.collection("tecnicos").doc(auth.currentUser.uid).get().then(snapshot => {
            //console.log(snapshot.data())
            setTecnico(snapshot.data())
        }))
        promises1.push(firestore.collection("aaff").where("nifTecnico", "==", auth.currentUser.uid).get().then(aaffsnapshot => {
            aaffsnapshot.docs.forEach(aaff =>{
                aaffs = { ...aaffs, [aaff.data().nif]: aaff.data()}
                nifAAFF.push(aaff.data().nif)
                //console.log(aaff.data().nif)
                firestore.collection("fincas").where("nifAAFF", '==', aaff.data().nif).get().then(fincasnapshot => {
                    fincasnapshot.docs.forEach(finca => {
                        fincas.push(finca.data().nif)
                        /* if(finca.data().nif === 'H64000847'){

                            nifProveedores = nifProveedores.concat(finca.data().nifProveedores)      
                        } */
                        //console.log(finca.data().nif)
                        nifProveedores = nifProveedores.concat(finca.data().nifProveedores)      
                    })
                    nifProveedores = [...new Set(nifProveedores)]
                    // console.log(fincas)
                    //console.log(nifProveedores)
                    let promises = []
                    let proveedores = []
                    let Ultreclamacion = undefined
                    //console.log(nifProveedores)
                    nifProveedores.forEach(e => {
                        promises.push(firestore.collection("proveedores").doc(e).get().then(prov => 
                        {
                            let p = prov.data()
                            /* if(p.fechaAlta.seconds === undefined){
                                console.log(p.nif)
                            } */
                            /* if(p.ultimaReclamacion !== undefined || p.ultimaReclamacion !== 'Invalid Date'){
                                Ultreclamacion = p.ultimaReclamacion.seconds
                            }else{
                                Ultreclamacion = p.fechaAlta.seconds
                            } */
                            Ultreclamacion = p.ultimaReclamacion?.seconds ? p.ultimaReclamacion.seconds : p.fechaAlta.seconds
                            
                            if(daysDiff(new Date(Ultreclamacion *1000), new Date()) > 45  && 
                            ((p.documentos?.vigilancia?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(p.documentos.vigilancia?.fechaCaducidad?.seconds *1000), new Date()) > 30)
                            || (p.documentos?.certificadoSS?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(p.documentos.certificadoSS?.fechaCaducidad?.seconds *1000), new Date()) > 30) 
                            || (p.documentos?.seguro?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(p.documentos.seguro?.fechaCaducidad?.seconds *1000), new Date()) > 30) 
                            || (p.documentos?.justificante?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(p.documentos.justificante?.fechaCaducidad?.seconds *1000), new Date()) > 30)
                            || (p.documentos?.evaluacionRiesgos?.ruta === '' || p.documentos?.planificacionPreventiva?.ruta === '' || p.documentos?.vigilancia?.ruta === '' 
                            || p.documentos?.certificadoSS?.ruta === '' || p.documentos?.seguro?.ruta === '' || p.documentos?.justificante?.ruta === '' )  ) ){
                                //console.log("ruta "+prov.data().documentos?.evaluacionRiesgos?.ruta)
                                proveedores.push(prov.data())
                            }
                        }))
                    })
                    Promise.all(promises).then(() => {
                        // console.log(proveedores)
                        setProveedores(proveedores)
                        //setProveedores({ proveedores: proveedores })
                    })
                })
            })
        }))
        Promise.all(promises1).then(() => {
            // console.log(aaffs)
            // console.log(nifAAFF)
            setIsLoading(false)
            //setConsultores( consultores )
            //setAAFFS({ aaffs })
        })
    })
    
    useEffect (() =>{
        
        getProveedores()
        
        
        /* firestore.collection("proveedores").orderBy("razonSocial").get().then(querysnapshot => {
          querysnapshot.docs.forEach(proveedor => {
              let nifAAFF = []
              let tecnico = []
              let Ultreclamacion = undefined
              const e = proveedor.data()
              firestore.collection("fincas").where("nifProveedores", "array-contains", e.nif).get().then(provsnapshot => {
                //console.log(e.nif +" "+provsnapshot.size)
                provsnapshot.docs.forEach(fincaprov => {
                  console.log(fincaprov.data())

                })
              })
              if(e.ultimaReclamacion !== undefined){
                Ultreclamacion = e.ultimaReclamacion.seconds
              }else{
                Ultreclamacion = e.fechaAlta.seconds
              }
              if(daysDiff(new Date(Ultreclamacion *1000), new Date()) > 45 && 
                ((e.documentos?.vigilancia?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(e.documentos.vigilancia?.fechaCaducidad?.seconds *1000), new Date()) > 30)
                || (e.documentos?.certificadoSS?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(e.documentos.certificadoSS?.fechaCaducidad?.seconds *1000), new Date()) > 30) 
                || (e.documentos?.seguro?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(e.documentos.seguro?.fechaCaducidad?.seconds *1000), new Date()) > 30) 
                || (e.documentos?.justificante?.fechaCaducidad?.seconds !== undefined && daysDiff(new Date(e.documentos.justificante?.fechaCaducidad?.seconds *1000), new Date()) > 30)
                || (e.documentos?.evaluacionRiesgos?.ruta === '' || e.documentos?.planificacionPreventiva?.ruta === '' || e.documentos?.vigilancia?.ruta === '' 
                || e.documentos?.certificadoSS?.ruta === '' || e.documentos?.seguro?.ruta === '' || e.documentos?.justificante?.ruta === '' ))){
                      
                    promises.push(firestore.collection("fincas").where("nifProveedores", "array-contains", proveedor.data().nif).get().then(snapshot => {
                       snapshot.docs.forEach(finca => {
                            nifAAFF = nifAAFF.concat(finca.data().nifAAFF)
                       })   
                       nifAAFF = [...new Set(nifAAFF)] 
                       for(let i=0; i< nifAAFF.length; i++ ){
                            tecnico.push(aaffs[nifAAFF[i]].nifTecnico)
                        }
                       e.nifConsultor = tecnico
                       e.nifAAFF = nifAAFF
                    })) 
                    e.Ultreclamacion = Ultreclamacion
                    proveedores.push(e)
                }
          }) 
        }) */
    },[])
    /* proveedores.forEach((pr, i) => {
        console.log(i, pr.nif, pr.fechaAlta)
    }) */
    /* const monthDiff = (dateFrom, dateTo) => {
        return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear())) //mostrar la diferencia en meses
    }*/

    const validarDocumentacion = (p, docName) => {
        console.log(p, docName)
        firestore.doc(`proveedores/${p}`).set({
          documentos: {
            [docName]: {
              validado: true,
              pendiente: false,
              fechaCaducidad: dialog.fechaCaducidad ? new Date(dialog.fechaCaducidad) : new Date(0)
            }
          }
        }, { merge: true })
        //setSubirDocumentacion ({ opened: false, nombreDoc: undefined, fecha: undefined, proveedor: undefined } )
        getProveedores()
    }
    /* const noValidarDocumentacion = () => {
        firestore.doc(`proveedores/${proveedor.nif}`).set({
          documentos: dialog.docName === "declaracionResponsable" ? {
            declaracionResponsable: {
              [this.props.params.nifAAFF]: {
                ruta: "",
                validado: false,
                pendiente: false
              }
            }
          } : {
            [dialog.docName]: {
              ruta: "",
              validado: false,
              pendiente: false,
              fechaCaducidad: ""
            }
          }
        }, { merge: true })
        this.setState({ dialog: { opened: false, title: "", docName: "", proveedor: undefined } })
        this.loadPageContent()
    } */

    const daysDiff = (dateFrom, dateTo) => {
        var diasdif= dateTo.getTime()-dateFrom.getTime();
        var contdias = Math.round(diasdif/(1000*60*60*24)); //mostrar la diferencia de dias
        return contdias 
    } 

    const cambiarUltimaReclamacion = (f) => {
      Swal.fire({
        title: 'Cambiar Ultima Reclamacion',
        html: `<input type="date" id="fecha" class="swal2-input">`,
        confirmButtonText: 'Cambiar',
        focusConfirm: false,
        preConfirm: () => {
          const fecha = Swal.getPopup().querySelector('#fecha').value
          if (!fecha) {
            Swal.showValidationMessage(`insertar fecha`)
          }
          return {fecha: fecha }
        }
      }).then((result) => {
        if(result.value !== undefined){
          Swal.fire({
            icon: 'success',
            title: 'Fecha cambiada'
          })
          firestore.collection("proveedores").doc(f).update({ 
            ultimaReclamacion: new Date(result.value.fecha)
          })
        }
      })
    }
    const ordenarUltimaReclamacion = () => {
      const proveedoresReverse = [...proveedores].reverse()
      setProveedores(proveedoresReverse)
    }

    if (proveedores === []) return <></> //Loading data...
    return ( 
        <>
        <div className="pageHeader"  >alerta Proveedores</div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              ALERTAS
              {/* <Link to="/consultores"><div className="gridHeadRight">{"<< volver al listado de Consultores"}</div></Link> */}
            </div>
            <div className="tableContainer"> 
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Proveedor</th>
                      <th style={{cursor:'pointer'}} onClick={ordenarUltimaReclamacion} >Última reclamación</th>
                      <th>Email</th>
                      <th>Planificación preventiva</th>
                      <th>Identificación riesgos</th>
                      <th>Vigilancia salud</th>
                      <th>Certificado SS</th>
                      <th>Justificante pago SPA</th>
                      <th>Seguro Resp. Civil</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    isLoading ? <tr><td colSpan='10' style={{textAlign:'center'}}><CircularProgress style={{color:'#848474'}} /> </td></tr> :
                    proveedores.length === 0 ? <tr><td colSpan='10' style={{textAlign:'center'}}>No hay alertas pendientes</td></tr> :
                    proveedores.map((e, i) => {
                        const ultimaReclamacion = e.ultimaReclamacion !==undefined ? e.ultimaReclamacion?.seconds : e.fechaAlta.seconds
                        return (
                        <tr key={i}>
                          <td>{i+1}</td>
                          <td><Link to={`/proveedores/${e.nif}`}>{e.razonSocial}</Link></td>
                          <td style={{ textAlign: "center" }} onClick={() => cambiarUltimaReclamacion(e.nif)} >{daysDiff(new Date(ultimaReclamacion *1000), new Date()) > 30 ? <span style={{color: "red"}}>{new Date(ultimaReclamacion * 1000).toLocaleDateString()}</span> : <span style={{color: "black"}}>{new Date(ultimaReclamacion * 1000).toLocaleDateString()}</span>}</td>
                          <td>{e.emailLogin}</td>
                          
                          <td style={{ textAlign: "center" }}>{
                                <CustomSwitch checked={e.documentos.planificacionPreventiva?.validado} onClick={e.documentos.planificacionPreventiva?.validado ? () => setVerDocumentacion( { opened: true, nombreDoc: 'planificacionPreventiva', proveedor: e } ) : () => subirDocumentacionProveedor("planificacionPreventiva", e, getProveedores)} />}
                                <small style={{display:'block'}}>-</small>
                          </td>
                          <td style={{ textAlign: "center" }}>{
                                <CustomSwitch checked={e.documentos.evaluacionRiesgos?.validado} onClick={e.documentos.evaluacionRiesgos?.validado ? () => setVerDocumentacion( { opened: true, nombreDoc: 'evaluacionRiesgos', proveedor: e } ) : () => subirDocumentacionProveedor("evaluacionRiesgos", e, getProveedores)} />}
                                <small style={{display:'block'}}>-</small>                                      
                          </td>
                          <td style={{ textAlign: "center" }}>{
                                <CustomSwitch checked={e.documentos.vigilancia?.validado} onClick={e.documentos.vigilancia?.validado ? () => setVerDocumentacion( { opened: true, nombreDoc: 'vigilancia', proveedor: e }) : () => setSubirDocumentacion( { opened: true, nombreDoc: 'vigilancia', proveedor: e })} />}
                                <small style={{display:'block'}}>{e.documentos.vigilancia?.fechaCaducidad !== undefined ? <span style={{color: daysDiff(new Date(e.documentos.vigilancia?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(e.documentos.vigilancia?.fechaCaducidad?.seconds* 1000).toLocaleDateString() }</span> : "-"}</small>                          
                          </td>
                          <td style={{ textAlign: "center" }}>{
                                <CustomSwitch checked={e.documentos.certificadoSS?.validado} onClick={e.documentos.certificadoSS?.validado ? () => setVerDocumentacion( { opened: true, nombreDoc: 'certificadoSS', proveedor: e }) : () => setSubirDocumentacion( { opened: true, nombreDoc: 'certificadoSS', proveedor: e })} />}
                                <small style={{display:'block'}}>{e.documentos.certificadoSS?.fechaCaducidad !== undefined ? <span style={{color: daysDiff(new Date(e.documentos.certificadoSS?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(e.documentos.certificadoSS?.fechaCaducidad?.seconds* 1000).toLocaleDateString() }</span> : "-"}</small>
                          </td>
                          <td style={{ textAlign: "center" }}>{
                                <CustomSwitch checked={e.documentos.justificante?.validado} onClick={e.documentos.justificante?.validado ? () => setVerDocumentacion( { opened: true, nombreDoc: 'justificante', proveedor: e }) : () => setSubirDocumentacion( { opened: true, nombreDoc: 'justificante', proveedor: e })} />}
                                <small style={{display:'block'}}>{e.documentos.justificante?.fechaCaducidad !== undefined ? <span style={{color: daysDiff(new Date(e.documentos.justificante?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(e.documentos.justificante?.fechaCaducidad?.seconds* 1000).toLocaleDateString() }</span> : "-"}</small>
                          </td>
                          <td style={{ textAlign: "center" }}>{
                                <CustomSwitch checked={e.documentos.seguro?.validado} onClick={e.documentos.seguro?.validado ? () => setVerDocumentacion ({ opened: true, nombreDoc: 'seguro', proveedor: e }) : () => setSubirDocumentacion({ opened: true, nombreDoc: 'seguro', proveedor: e })} />}
                                <small style={{display:'block'}}>{e.documentos.seguro?.fechaCaducidad !== undefined ? <span style={{color: daysDiff(new Date(e.documentos.seguro?.fechaCaducidad?.seconds* 1000), new Date()) > 30 ? "red" : "black"}}>{new Date(e.documentos.seguro?.fechaCaducidad?.seconds* 1000).toLocaleDateString()}</span> : "-"}</small>
                            </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <input type="file" id="fileElem" hidden></input>
              </div>
            </div>
          </div>
        </div>
        {/* <Dialog
            open={dialog.opened}
            onClose={() => setDialog({ opened: false, title: "", docName: "" })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
            <DialogContent>
            {dialog.fecha ? 
                <DialogContentText id="alert-dialog-description">
                Indica la fecha de caducidad del documento
                <div>
                    <input type="date" required value={dialog.fechaCaducidad} onChange={event => setDialog({ ...dialog, fechaCaducidad: event.target.value  })} />
                </div>

                </DialogContentText> : ''
            }
            </DialogContent>
            <DialogActions>
            <Button onClick={() => noValidarDocumentacion()} color="inherit" style={{ color: "#FF5252" }}>
                No Validar
            </Button>
            <Button onClick={() => validarDocumentacion()} color="inherit" style={{ color: "#7bc977" }} autoFocus>
                <b>Validar</b>
            </Button>
            </DialogActions>
        </Dialog> */}
        {/* VER DOCUMENTACIÓN */}
        <Dialog
            open={verDocumentacion.opened}
            onClose={() => setVerDocumentacion({ opened: false, nombreDoc: undefined, proveedor: undefined })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">¿Qué quieres hacer con el documento?</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Subir un nuevo documento implica sobreescribir el anterior
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => {
                firestore.doc(`proveedores/${verDocumentacion.proveedor.nif}`).set({
                documentos: {
                    [verDocumentacion.nombreDoc]: {
                    ruta: "",
                    validado: false,
                    pendiente: false
                    }
                }
                }, { merge: true }).then(() => {
                toast.success("Documento borrado con éxito")
                setVerDocumentacion({ opened: false, nombreDoc: undefined, proveedor: undefined })
                getProveedores()
                })
            }} color="inherit" style={{ color: "#848474" }}>
                Borrar documento
            </Button>
            <Button onClick={() => {
                const nombreDoc = verDocumentacion.nombreDoc
                
                setSubirDocumentacion ({ opened: true, nombreDoc: nombreDoc, proveedor: verDocumentacion.proveedor })
                
                setVerDocumentacion( { opened: false, nombreDoc: undefined, proveedor: undefined })
            }} color="inherit" style={{ color: "#848474" }}>
                Subir nuevo
            </Button>
            <Button onClick={() => {
                const ruta = verDocumentacion.proveedor.documentos[verDocumentacion.nombreDoc].ruta
                abrirArchivo(ruta)
                setVerDocumentacion({ opened: false, nombreDoc: undefined, proveedor: undefined })
            }} color="inherit" style={{ color: "#848474" }} autoFocus>
                <b>VER</b>
            </Button>
            </DialogActions>
        </Dialog>
        {/* SUBIR DOCUMENTACIÓN */}
        <Dialog
            open={subirDocumentacion.opened}
            onClose={() => setSubirDocumentacion({ opened: false, nombreDoc: undefined, fecha: undefined, proveedor: undefined })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Subir documentación</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Indica la fecha de caducidad del documento
                <div>
                <input type="date" required value={subirDocumentacion.fecha} onChange={event => setSubirDocumentacion( { ...subirDocumentacion, fecha: event.target.value })} />
                </div>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => {
                setSubirDocumentacion( { opened: false, nombreDoc: undefined, fecha: undefined, proveedor: undefined })
            }} color="inherit" style={{ color: "#848474" }} autoFocus>
                Cancelar
            </Button>
            <Button onClick={() => {
                if (subirDocumentacion.fecha !== undefined) {
                    subirDocumentacionProveedor(subirDocumentacion.nombreDoc, subirDocumentacion.proveedor, getProveedores, subirDocumentacion.fecha)
                    setSubirDocumentacion({ opened: false, nombreDoc: undefined, fecha: undefined, proveedor: undefined })
                } else {
                    toast.error("Introduce la fecha de caducidad")
                }
            }} color="inherit" style={{ color: "#848474" }} autoFocus>
                <b>Guardar y elegir archivo</b>
            </Button>
            </DialogActions>
        </Dialog>
      </>
    );
}
 
export default AlertasProveedores;