import React from "react";
import { Link } from "react-router-dom";
import { firestore } from "../../../providers/firebase";
import { withRouter } from "../../../providers/withRouter";
import HeaderBackground from "../../../images/banner_administradores.jpg"
//import Lupa from "../../../icons/lupa.svg"
import Warning from "../../../icons/warning.svg"
import CircularProgress from '@material-ui/core/CircularProgress';

class AAFF extends React.Component {
  constructor() {
    super()
    this.state = {
      consultor: {},
      aaff: [],
      isLoading: true
    }
  }
  componentDidMount() {
    let proveedores = {}
    let promises = []
    let provnif = []
    promises.push(firestore.collection("proveedores").get().then(querysnapshot => {
      querysnapshot.docs.forEach(proveedor => {
        proveedores = { ...proveedores, [proveedor.data().nif]: proveedor.data() }
        provnif.push(proveedor.data().nif)
      })
    
    }))
    Promise.all(promises).then(() => {
      this.setState({proveedorDatos:proveedores})
      this.setState({provsnif:provnif})
    })
    firestore.collection("consultores").doc(this.props.params.nif).get().then(snapshot => {
      if (!snapshot.exists) {
        window.location.replace("/consultores")
      }
      this.setState({ consultor: snapshot.data() })
      let aaffs = []
      let promises = []
      firestore.collection("aaff").where('nifConsultor', '==', snapshot.data().nif).orderBy("razonSocial").get().then(snapshot2 => {
        snapshot2.docs.forEach(doc => {
          let aaffData = doc.data()
          promises.push(firestore.collection("fincas").where("nifAAFF", "==", aaffData.nif).get().then(fincas => {
             //aaffData.nFincas = fincas.size
          let proveedores = []
          //let fincasVisitadas = 0
          let nfincas = 0
          fincas.docs.forEach(finca => {
            if(finca.data().activo === undefined || finca.data().activo === true){
              nfincas++
            }
            proveedores = proveedores.concat(finca.data().nifProveedores)
            /* if (finca.data().documentos?.hojaVisita?.validado) {
              fincasVisitadas++
            } */
          })
          proveedores = [...new Set(proveedores)]
          let nprof = 0
          for(let i=0; i< proveedores.length; i++){
            for(let j=0; j< this.state.provsnif.length; j++){
              if(this.state.provsnif[j]?.includes(proveedores[i])){
                let e = this.state.proveedorDatos[this.state.provsnif[j]]
                console.log(e.documentos?.declaracionResponsable[aaffData.nif])
                if(e.documentos !== 'undefined' && (e.documentos?.certificadoSS?.pendiente || e.documentos?.declaracionResponsable[aaffData.nif]?.pendiente || e.documentos?.evaluacionRiesgos?.pendiente || e.documentos?.justificante?.pendiente
                  || e.documentos?.lopd?.pendiente || e.documentos?.planEmergencia?.pendiente || e.documentos?.planPrevencion?.pendiente || e.documentos?.planificacionPreventiva?.pendiente 
                  || e.documentos?.seguro?.pendiente || e.documentos?.vigilancia?.pendiente)){
                    nprof++;
                }
              } 
            }
          }
          aaffData.nFincas = nfincas
          aaffData.proveedores = proveedores
          aaffData.nProveedores = proveedores.length
          //aaffData.fincasVisitadas = fincasVisitadas
          aaffData.docsPendientes = nprof
            aaffs.push(aaffData)
          }))
        })
        Promise.all(promises).then(() => {
          this.setState({ aaff: aaffs, isLoading: false })
          //console.log(aaffs)
        })
      })
    })
  }

  render() {
    if (this.state.aaff === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader"  >Consultores / <b>{this.state.consultor.nombre + " " + this.state.consultor.apellidos}</b></div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              LISTADO ADMINISTRADORES DE FINCAS
              <Link to="/consultores"><div className="gridHeadRight">{"<< volver al listado de Consultores"}</div></Link>
            </div>
            <div className="tableContainer">
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Administrador</th>
                      <th>NIF</th>
                      <th>Provincia</th>
                      <th>Municipio</th>
                      <th style={{ textAlign: "center" }}>Nº Comunidades</th>
                      <th style={{ textAlign: "center" }}></th>
                      <th style={{ textAlign: "center" }}>Proveedores</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.isLoading ? <tr><td colSpan='8' style={{textAlign:'center'}}><CircularProgress style={{color:'#848474'}} /> </td></tr> :<></>}
                    {this.state.aaff.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td>{i+1}</td>
                          <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.razonSocial}</Link></td>
                          <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.nif}</Link></td>
                          <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.provincia}</Link></td>
                          <td><Link to={"/aaff/" + e.nif + "/comunidades"}>{e.municipio}</Link></td>
                          <td style={{ textAlign: "center" }}>{e.nFincas}</td>
                          <td>{e.docsPendientes > 0 ? <img src={Warning} alt="" width="15px" style={{ marginLeft: 10 }}/> : ""}</td>
                          <td style={{ textAlign: "center" }}><Link to={"/consultores/" + this.state.consultor.nif + "/aaff/" + e.nif + "/proveedores"}>{e.nProveedores}</Link></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(AAFF);