import * as XLSX from "xlsx"

export const excelToJson = (file, callback) => {
    let reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onloadend = (ev) => {
        let data = new Uint8Array(ev.target.result)
        let workbook = XLSX.read(data, { type: "array" })
        callback(XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]))
    }
    reader.onerror = (ev) => {
        console.log(ev.target.error)
    }
}