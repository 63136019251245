import React from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { firestore, storage } from "../../../providers/firebase";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { toast } from "react-toastify";

import {abrirArchivo } from "../../../providers/documentos";
import Tick from "../../../icons/tick.svg"
import Cross from "../../../icons/cross.svg"
import Minus from "../../../icons/minus.svg"

class HojasVisita extends React.Component {
  constructor() {
    super()
    this.state = {
      fincas: [],
      tecnico: {},
      aaff: {}
    }
  }
  componentDidMount() {
    firestore.collection("aaff").doc(this.props.params.nifAAFF).get().then(docAAFF => {
      if (docAAFF.data().nifTecnico === this.props.params.nifTecnico) {
        firestore.collection("tecnicos").doc(this.props.params.nifTecnico).get().then(docTecnico => {
          this.setState({ tecnico: docTecnico.data(), aaff: docAAFF.data() })
        }).catch(err => window.location.replace("/tecnicos/" + this.props.params.nifTecnico + "/aaff"))
      } else {
        window.location.replace("/tecnicos/" + this.props.params.nifTecnico + "/aaff")
      }

    }).catch(err => window.location.replace("/tecnicos/" + this.props.params.nifTecnico + "/aaff"))
    this.loadPageContent()
  }

  loadPageContent = () => {
    firestore.collection("fincas").where('nifAAFF', "==", this.props.params.nifAAFF).orderBy("razonSocial").get().then(snapshot => {
      let fincas = [];
      snapshot.forEach(finca => {
        fincas.push(finca.data())
      })
      this.setState({ fincas: fincas })
    })
  }

  abrirArchivo = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }
  }

  render() {
    if (this.state.fincas === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader"  >Técnico / {this.state.tecnico.nombre + " " + this.state.tecnico.apellidos} / {this.state.aaff.razonSocial} / <b>Hojas de visita</b></div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              LISTADO DE COMUNIDADES
              <Link to={`/tecnicos/${this.props.params.nifTecnico}/aaff`}><div className="gridHeadRight">{"<< volver al listado de Administradores de Fincas"}</div></Link>
            </div>
            <div className="tableContainer">
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Comunidad</th>
                      <th>NIF</th>
                      <th style={{ textAlign: "center" }}>LOPD</th>
                      <th style={{ textAlign: "center" }}>Hoja de visita</th>
                      <th style={{ textAlign: "center" }}>Plan prevención</th>
                      <th style={{ textAlign: "center" }}>Identificación riesgos</th>
                      <th style={{ textAlign: "center" }}>Plan emergencia</th>
                      <th>Activa</th>
                      <th>Servicios</th>
                      <th>Empleados</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fincas.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td>{e.razonSocial}</td>
                          <td>{e.nif}</td>
                          <td style={{ textAlign: "center" }}>{e.documentos.lopd?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> :
                            e.documentos.lopd?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                              abrirArchivo(e.documentos.lopd?.ruta)
                            }}/> : <img src={Cross} alt="No validado" className="tableIcon" />}
                          </td>
                          <td style={{ textAlign: "center" }}>{e.documentos.hojaVisita?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> :
                            e.documentos.hojaVisita?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                              abrirArchivo(e.documentos.hojaVisita?.ruta)
                            }}/> : <img src={Cross} alt="No validado" className="tableIcon" />}
                          </td>
                          <td style={{ textAlign: "center" }}>{e.documentos.prevencionRiesgos?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> :
                            e.documentos.prevencionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                              abrirArchivo(e.documentos.prevencionRiesgos?.ruta)
                            }}/> : <img src={Cross} alt="No validado" className="tableIcon" />}
                          </td>
                          <td style={{ textAlign: "center" }}>{e.documentos.evaluacionRiesgos?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> :
                            e.documentos.evaluacionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                              abrirArchivo(e.documentos.evaluacionRiesgos?.ruta)
                            }} /> : <img src={Cross} alt="No validado" className="tableIcon" />}
                          </td>
                          <td style={{ textAlign: "center" }}>{e.documentos.planEmergencia?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> :
                            e.documentos.planEmergencia?.validado ? <img src={Tick} alt="Validado" className="tableIcon" onClick={() => {
                              abrirArchivo(e.documentos.planEmergencia?.ruta)
                            }} /> : <img src={Cross} alt="No validado" className="tableIcon" />}
                          </td>
                          <td>{e.activo === undefined ? "Activa" : e.activo === "true" || e.activo === true ? "Activa" : "Inactiva"}</td>
                          <td>{e.servicios === undefined ? "CAE" : e.servicios === "true" || e.servicios === true ? "SPA" : "CAE"}</td>
                          <td>{e.empleados === undefined ? "No" : e.empleados === "true" || e.empleados === true ? "Sí" : "No"}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(HojasVisita);

