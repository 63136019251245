import React from "react";
import { Link } from "react-router-dom";
import { firestore, storage, auth } from "../../../providers/firebase";
import HeaderBackground from "../../../images/banner_administradores.jpg"
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";

class Comunidades extends React.Component {
  constructor() {
    super()
    this.state = {
      fincas: [],
      aaff: {}
    }
  }
  componentDidMount() {
    firestore.collection("aaff").doc(this.props.params.nif).get().then(doc => {
      firestore.collection("fincas").where("nifAAFF", "==", this.props.params.nif).where("nifProveedores", "array-contains", auth.currentUser.uid).orderBy("razonSocial", "asc").get().then(snapshot => {
        let data = []
        snapshot.docs.forEach(e => {
          if(e.data().activo === true || e.data().activo === "true" || e.data().activo === undefined){
          }
          data.push(e.data())
        })
        this.setState({ aaff: doc.data(), fincas: data })
      })
    }).catch(err => window.location.replace("/aaff"))

  }

  abrirArchivo = (ruta) => {
    if (ruta === "") {
      toast.error("No existe el archivo")
    } else {
      storage.ref(ruta).getDownloadURL().then(url => {
        window.open(url, "_blank")
      }).catch(error => {
        console.log(error)
        toast.error("Ha ocurrido un error al descargar el archivo")
      })
    }
  }

  render() {
    if (this.state.fincas === []) return <></> //Loading data...
    return (
      <>
        <div className="pageHeader"  >Administrador / <b>{this.state.aaff.razonSocial}</b></div>
        <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">
              LISTADO COMUNIDADES
              <Link to="/aaff"><div className="gridHeadRight">{"<< volver al listado de Administradores de Fincas"}</div></Link>
            </div>
            <div className="tableContainer">
              <div className="tableWrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Comunidad</th>
                      <th>NIF</th>
                      <th>Dirección</th>
                      <th>C.P.</th>
                      <th>Municipio</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fincas.length === 0 ? <tr><td colSpan={5} style={{textAlign: 'center'}}>No hay comunidades</td></tr> :
                    this.state.fincas.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td><Link to={"/comunidades/" + e.nif}>{e.razonSocial}</Link></td>
                          <td><Link to={"/comunidades/" + e.nif}>{e.nif}</Link></td>
                          <td><Link to={"/comunidades/" + e.nif}>{e.direccion}</Link></td>
                          <td><Link to={"/comunidades/" + e.nif}>{e.codigoPostal}</Link></td>
                          <td><Link to={"/comunidades/" + e.nif}>{e.municipio}</Link></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Comunidades);

